import React, { FunctionComponent, useEffect, useState } from 'react';
import '../css/exchange.css';
import GenericIcons from './generics/GenericIcons';
import { formatStringNumber } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';

type ExchangeRateType = {
  country: string;
  yesterdayDateValue: number;
  currentDateValue: number;
};

const PriceExchange: FunctionComponent = ({}) => {
  const [exchangeRatesData, setExchangeRatesData] = useState<ExchangeRateType[]>([]);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    setIsMounted(true);
    const fetchRates = async () => {
      try {
        const data = (await getUncatchEndpointData({
          endpoint: 'exchangeRates'
        })) as ExchangeRateType[];
        if (isMounted) {
          setExchangeRatesData(data);
        }
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };
    fetchRates();

    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  return (
    <div className={'price-exchange'}>
      <GenericIcons icon='DollarIcon' />
      {exchangeRatesData.map((row, idx) => (
        <p key={`exchange_rate_key_${idx}`}>
          {`${row.country} $${formatStringNumber(row.currentDateValue.toString(), true)}`}
          <span>
            {row.yesterdayDateValue > row.currentDateValue ? (
              <GenericIcons icon='downArrowIcon' />
            ) : row.yesterdayDateValue < row.currentDateValue ? (
              <GenericIcons icon='upArrowIcon' />
            ) : (
              ''
            )}
          </span>
        </p>
      ))}
    </div>
  );
};

export default PriceExchange;
