import React, { ReactNode, useState } from 'react';
import './GenericContainerSidebar.css';
import { Link } from 'react-router-dom';
import GenericIcons from '../GenericIcons';

type Props = {
  children: ReactNode;
  img?: string;
  titleTopNav?: string;
  AppNavbar?: ReactNode;
  linkTopNav?: string;
  logout?: ReactNode;
  user?: string;
};

const GenericContainerSidebar = (props: Props) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  return (
    <div className={'generic-sidebar-container'}>
      <div className={'generic-sidebar-navbar'}>
        {isOpenSidebar && (
          <div className={'generic-sidebar-arrow'} onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
            <GenericIcons
              icon='arrow'
              imageStyle={{
                transform: isOpenSidebar ? 'rotate(0deg)' : '',
                cursor: 'pointer',
                width: 32,
                height: 32
              }}
            />
          </div>
        )}
        <div className='sidebar-image'>
          {props.img ? <img src={props.img} style={{ objectFit: 'cover', width: 150 }} /> : null}
        </div>
        <div className='title-sidebar'>
          <Link to={`${props.linkTopNav}`}>
            <h1 className={'text-title-sidebar'}>
              The <span>Trader</span>
            </h1>
          </Link>
        </div>
      </div>
      <div className='generic-sidebar'>
        <div
          className={`left-sidebar ${isOpenSidebar ? 'closed-left-sidebar' : 'open-left-sidebar'}`}>
          <div className='left-sidebar-top'>
            <div className='left-sidebar-top-icon'>
              <GenericIcons
                icon='userIconBlue'
                imageStyle={{
                  width: 32,
                  height: 32
                }}
              />
            </div>
            <div className='left-sidebar-top-user'>
              <p>{props.user}</p>
            </div>
            <div
              className='left-sidebar-top-arrow-icon'
              onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
              <GenericIcons
                icon='arrow'
                imageStyle={{
                  transform: !isOpenSidebar ? 'rotate(180deg)' : '',
                  cursor: 'pointer',
                  width: 32,
                  height: 32
                }}
              />
            </div>
          </div>

          <div className='left-sidebar-middle'>{props.AppNavbar}</div>

          <div className='left-sidebar-bottom'>
            {props.logout && (
              <div className='left-sidebar-bottom-logout'>
                <GenericIcons icon='SignOutDarkBlue' />
                {props.logout}
              </div>
            )}
            <div className={'left-sidebar-bottom-by-datafy'}>
              <span>The Trader by</span>
              <a href='https://datafy.cl/' target='_blank' rel='noopener noreferrer'>
                <GenericIcons
                  icon='datafy'
                  imageStyle={{
                    width: 73,
                    marginLeft: 8,
                    height: 26
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className='right-render'>{props.children}</div>
      </div>
    </div>
  );
};

export default GenericContainerSidebar;
