import React, { FunctionComponent, useState, useEffect, useRef, CSSProperties } from 'react';
import { Socket } from 'socket.io-client';
import { AuthState } from '../reducers/types';
import { SignalValues, SimpleObject, User } from '../types';
import { useUserAccess } from '../components/hooks';
import { AppState } from '../store';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { performUncatchedRequest } from '../utils/request';
import { connect } from 'react-redux';
import ItemCatalog from '../components/ItemCatalog';

type BiddinInfoType = {
  [key: string]: { currentBidder: User | null; currentCountDown: number; onAuction: boolean };
};

const UserListComponent = ({ users }: { users: Array<string> }) => {
  return (
    <div
      style={{
        width: '500px',

        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderLeft: '1px solid black'
      }}>
      <h2>Usuarios Conectados</h2>
      <ul>
        {users.map((u, idx) => (
          <li key={idx}>{u}</li>
        ))}
      </ul>
    </div>
  );
};

export type Product = {
  id: string;
  name: string;
  elementImg: string;
  quantity: number; // In KG
  initialUnitPrice: number;
  initialPrice: number;
  currentPrice: number;
  unitOfMeasureCurrentPrice: number;
  soldPrice: number;
  winningBidder: User;
  available: string;
  countryName: string;
  brandName: string;
  category: string;
  expirationDate: string;
  auctionStartDate: string;
  auctionEndDate: string;
};

export type AuctionProductData = {
  products: Array<Product>;
  productIdOnAuction: string;
  biddingInfo: BiddinInfoType;
};

const ProductInfo = ({
  product,
  idx,
  currentPriceStyle
}: {
  product: Product;
  idx: number;
  currentPriceStyle?: CSSProperties;
}) => {
  return (
    <div className='container-product'>
      <h2>{product.name.toUpperCase()}</h2>
      <div className='container-row-product'>
        <div className='container-img-product'>
          <img src={product.elementImg} width='100' height='100' />
        </div>
        <div className='container-details-product'>
          <p key={`available_${idx}`}>{product.available ? 'DISPONIBLE' : 'REMATADO'}</p>
          <p key={`brand_name_${idx}`}>
            MARCA: <b>{product.brandName}</b>
          </p>
          <p key={`country_name_${idx}`}>
            PAIS ORIGEN: <b>{product.countryName}</b>
          </p>
          <p key={`category_${idx}`}>
            CATEGORIA: <b>{product.category}</b>
          </p>
          <p key={`expiration_date_${idx}`}>
            FECHA DE EXPIRACION: <b>{dateFormatFn(product.expirationDate, 'DD/MM/YYYY', true)}</b>
          </p>
          <p key={`unit_price_${idx}`}>
            PRECIO UNITARIO: <b>${formatStringNumber(product.initialUnitPrice.toString())}/kgs</b>
          </p>
          <p key={`quantity_${idx}`}>
            CANTIDAD: <b>{formatStringNumber(product.quantity.toString(), true)} kgs</b>
          </p>
          <p key={`initial_price_${idx}`}>
            PRECIO INICIAL: <b>${formatStringNumber(product.initialPrice.toString())}</b>
          </p>
          <p key={`auction_final_date_${idx}`}>
            FECHA CIERRE SUBASTA: <b>{dateFormatFn(product.auctionEndDate)}</b>
          </p>
          {!product.soldPrice ? (
            <p key={`current_price_${idx}`} style={currentPriceStyle}>
              PRECIO ACTUAL: <b>${formatStringNumber(product.currentPrice.toString())}</b>
            </p>
          ) : null}
          {product.winningBidder ? (
            <p key={`winning_bidder_${idx}`}>
              GANADOR DE LA SUBASTA: <b>{product.winningBidder.userUsername}</b>
            </p>
          ) : null}
          {product.soldPrice ? (
            <p key={`sold_price_${idx}`}>
              VENDIDO POR : <b>${formatStringNumber(product.soldPrice.toString())}</b>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const ProductListComponent = ({
  products,
  onJoinToAuctionChannel,
  productIdOnAuction
}: {
  products: Array<Product>;
  onJoinToAuctionChannel: (productId: string) => () => void;
  productIdOnAuction: string;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto'
      }}>
      <h1>Productos a Rematar</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '15px',
          margin: '20px',
          padding: '20px'
        }}>
        <div className='card-meet-trader-container'>
          {products && Array.isArray(products)
            ? products.map((p, idx) => (
                <div key={`card_meet_trader_${idx}`} className='card-meet-trader'>
                  <ProductInfo product={p} idx={idx} />
                  {p.available && productIdOnAuction !== p.id ? (
                    <>
                      <button
                        className='button-card-meet-trader'
                        onClick={onJoinToAuctionChannel(p.id)}>
                        UNIRSE A LA SUBASTA
                      </button>
                    </>
                  ) : p.available && productIdOnAuction === p.id ? (
                    <p>EN REMATE</p>
                  ) : (
                    <p>VENDIDO</p>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

const ProductOnAuction = ({
  products,
  user,
  productIdOnAuction,
  onIncreaseAuctionClick,
  priceInterval,
  countdown,
  currentBidder
}: {
  products: Array<Product>;
  user: User | null;
  productIdOnAuction: string;
  onIncreaseAuctionClick: (amount: number) => () => void;
  priceInterval: number;
  countdown: number;
  currentBidder: User | null;
}) => {
  const productIdx = products.findIndex((p) => p.id === productIdOnAuction);

  const style = {
    fontSize: countdown < 11 ? 85 : 55
  };

  const getRemindDate = (endDateStr?: string) => {
    const auxDate = new Date();
    auxDate.setTime(auxDate.getTime() + countdown * 1000);
    const endDate = endDateStr ? new Date(endDateStr) : auxDate;
    const currentDate = new Date();

    const diff = endDate.getTime() - currentDate.getTime();

    const totalSecs = Math.floor(diff / 1000);
    const totalMins = Math.floor(totalSecs / 60);
    const totalHours = Math.floor(totalMins / 60);
    const totalDays = Math.floor(totalHours / 24);

    const secs = totalSecs % 60;
    const mins = totalMins % 60;
    const hours = totalHours % 24;
    const days = totalDays % 30; // NOTE: Proximate to days in one month

    const months = Math.floor(totalDays / 30); // NOTE: Proximate to months in one year
    const years = Math.floor(months / 12);

    const dayString = days > 0 ? `${days} dia(s) ` : '';
    const monthString = months > 0 ? `${months} mes(es) ` : '';
    const yearString = years > 0 ? `${years} año(s) ` : '';

    return `${yearString}${monthString}${dayString} ${hours}:${mins}:${secs}`;
  };

  const drawProductAuction = (paramInverval: number) => (
    <>
      <ProductInfo
        product={products[productIdx]}
        idx={productIdx}
        currentPriceStyle={{ fontSize: '22px' }}
      />
      <div style={{ width: '100%', padding: 10 }}>
        <div className='container-licitador-title'>
          <p style={{ fontSize: '1.5em', marginBottom: 20 }}>
            LICITADOR ACTUAL: {currentBidder ? <b>{currentBidder.userUsername}</b> : null}
          </p>
        </div>

        <div className='container-licitador'>
          {countdown !== 0 && (
            <>
              {[1, 2, 3].map((i) => (
                <button
                  key={`bidding_btn_${i}`}
                  className='btn-licitador'
                  disabled={countdown === 0 || currentBidder?._id === user?._id}
                  onClick={onIncreaseAuctionClick(
                    products[productIdx].unitOfMeasureCurrentPrice + priceInterval * i
                  )}>
                  OFERTAR $
                  {formatStringNumber(
                    (
                      (products[productIdx].unitOfMeasureCurrentPrice + priceInterval * i) *
                      products[productIdx].quantity
                    ).toString()
                  )}
                  Precio Unitario $
                  {formatStringNumber(
                    (products[productIdx].unitOfMeasureCurrentPrice + priceInterval * i).toString()
                  )}
                </button>
              ))}

              <div>
                <h2 style={style} className={countdown < 11 ? 'animate__heartBeat' : ''}>
                  {/* {getRemindDate(products[productIdx].auctionEndDate)} */}
                  {getRemindDate()}
                </h2>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '2em'
      }}>
      {products[productIdx] ? drawProductAuction(priceInterval) : <h2>Ingresa a una Subasta</h2>}
    </div>
  );
};

interface IProps {
  auth: AuthState;
  socket: Socket | null;
}

const AuctionPage: FunctionComponent<IProps> = ({ auth, socket }) => {
  useUserAccess(auth);

  const [connectedUsers, setConnectedUsers] = useState<Array<string>>([]);
  const [availableProducts, setAvailableProducts] = useState<Array<Product>>([]);
  const [currentBidderSocket, setCurrentBidderSocket] = useState<User | null>(null);
  const [countdown, setCountdown] = useState<number>(0);
  const [joinedChannel, setJoinedChannel] = useState<string>('');
  const [priceInterval, setPriceInterval] = useState<number>(0);
  const isMounted = useRef(true);

  // CALLBACKS
  const onJoinToAuctionChannel = (productId: string) => () => {
    if (socket) {
      socket.emit(
        SignalValues.joinAuctionChannel,
        productId,
        (channelId: number, biddingInfo: BiddinInfoType) => {
          //NOTE: Channel id has the same productId
          setJoinedChannel(productId);
          console.log({ productId, channelId, joinedChannel, biddingInfo });
          setCountdown(biddingInfo[productId].currentCountDown);
        }
      );
    }
  };

  const onIncreaseAuctionClick = (joinedChannel: string) => (amount: number) => () => {
    if (socket) {
      socket.emit(SignalValues.increaseAuction, joinedChannel, amount);
    }
  };

  const updateAuctionData = (auctionProductsData: AuctionProductData) => {
    if (isMounted.current) {
      setAvailableProducts(auctionProductsData.products);
      setCurrentBidderSocket(
        auctionProductsData.biddingInfo[auctionProductsData.productIdOnAuction]?.currentBidder
      );
    }
  };

  // COMPONENT DID MOUNT
  useEffect(() => {
    const getParameters = async () => {
      try {
        const { data } = await performUncatchedRequest({
          endpoint: 'parameters',
          method: 'get'
        });

        // NOTE: Avoid state changes when the component is unmouted
        if (!isMounted.current) {
          return;
        }

        const priceIntervalObj = data.find(
          (param: SimpleObject) => param.paramName === 'auction_price_interval'
        );

        setPriceInterval(Number(priceIntervalObj.paramValue));
      } catch (error) {
        console.log(error);
      }
    };

    getParameters();

    return () => {
      isMounted.current = false;
    };
  }, []);

  // COMPONENT DID UPDATE (socket.id)
  useEffect(() => {
    if (socket) {
      socket.emit(SignalValues.initialData);
    }
  }, [socket?.id]);

  useEffect(() => {
    if (socket) {
      socket.on(SignalValues.initialDataBroadcast, updateAuctionData);
      socket.on(SignalValues.auctionBroadcast, updateAuctionData);
      socket.on(SignalValues.connectedUsersBroadcast, setConnectedUsers);
      socket.on(SignalValues.countdownBroadcast, setCountdown);
    }
  }, [socket]);

  return (
    <div
      style={{ backgroundColor: '#fff', display: 'flex', overflow: 'hidden', height: '100vh' }}
      className='notDisplayScroll'>
      <ProductListComponent
        products={availableProducts}
        onJoinToAuctionChannel={onJoinToAuctionChannel}
        productIdOnAuction={joinedChannel}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          borderLeft: '1px solid black',
          position: 'relative'
        }}>
        {socket && (
          <div style={{ position: 'absolute', top: '0' }}>
            <p style={{ fontSize: '22px' }}>
              Te haz conectado! tu socketID es: <b>{socket.id}</b>
            </p>
          </div>
        )}

        {joinedChannel ? (
          <ProductOnAuction
            products={availableProducts}
            user={auth.user}
            productIdOnAuction={joinedChannel}
            onIncreaseAuctionClick={onIncreaseAuctionClick(joinedChannel)}
            priceInterval={priceInterval}
            countdown={countdown}
            currentBidder={currentBidderSocket}
          />
        ) : null}
      </div>
      <UserListComponent users={connectedUsers as Array<string>} />
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(AuctionPage);
