import { Item } from '../types';
import { WebEntity, FieldTypes } from './types';

const ItemEntity: WebEntity<Item> = {
  name: 'item',
  endpoint: 'items',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Item'
  },

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Codigo',
      selector: 'customerItemId',
      sortable: true,
      wrap: true
    },
    {
      name: 'Nombre',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Dias de Duración',
      selector: 'itemDurationDays',
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Codigo',
      selector: 'customerItemId',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre',
      selector: 'itemName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Pais Origen',
      selector: 'countrySourceId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'country',
        endpoint: 'countries',
        select: '_id',
        show: 'countryName',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Marca',
      selector: 'brandId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'brand',
        endpoint: 'brands',
        select: '_id',
        show: 'brandName',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Dias de Duración',
      selector: 'itemDurationDays',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Codigo',
      selector: 'customerItemId',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre',
      selector: 'itemName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Pais Origen',
      selector: 'countrySourceId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'country',
        endpoint: 'countries',
        select: '_id',
        show: 'countryName',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Marca',
      selector: 'brandId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'brand',
        endpoint: 'brands',
        select: '_id',
        show: 'brandName',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Dias de Duración',
      selector: 'itemDurationDays',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default ItemEntity;
