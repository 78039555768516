export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const camelToAnyCase = (str: string, opt: string = '_', capitalize = false) => {
  const localStr = String(str);
  return capitalize
    ? localStr[0].toUpperCase() +
        localStr.replace(/[A-Z]/g, (letter) => `${opt}${letter.toUpperCase()}`).substring(1)
    : localStr.replace(/[A-Z]/g, (letter) => `${opt}${letter.toLowerCase()}`);
};

export const camelToSnakeCase = (str: string) => camelToAnyCase(str);

export const snakeToCamelCase = (str: string) => {
  if (str[0] === '_') return str;
  return str.replace(/_([a-z])/g, (_, p1) => p1.toUpperCase());
};

export const spaceToCamelCase = (str: string) => {
  const localStr = String(str).toLowerCase();
  return localStr.replace(/\s[a-z]/, (letter) => letter[1].toUpperCase());
};
