import axios from 'axios';
import Swal from 'sweetalert2';
import * as E from '../entities';
import { WebEntityName, WebEntity } from '../entities/types';
import { AppDispatch, AppGetState } from '../store';
import { tokenConfig } from '../utils/header';
import {
  deleteEntityAction,
  getEntitiesAction,
  voidEntitiesAction,
  setEntityLoading
} from './entitiesActions';
import { handleError } from '../components/PopAlert';
import { AvailableStock, Cart, Lot, SimpleObject, StateValues, Stock, User } from '../types';
import { SingleEntityState } from '../reducers/types';

// REFRESHERS
const refreshEntity =
  (entity: SimpleObject, query?: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      entity.name as WebEntityName,
      entity.endpoint,
      query ? { query } : {}
    )()(dispatch, getState);

export const refreshShoppingCart = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  await refreshEntity(E.SaleOrderDetailEntity, { saleOrderStateName: StateValues.Activo })(
    dispatch,
    getState
  );
};

export const refreshSaleOrderDetail =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    await refreshEntity(E.SaleOrderDetailEntity)(dispatch, getState);
  };

const refreshEntityWithOptions =
  (entity: SimpleObject, options: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(entity.name as WebEntityName, entity.endpoint, options)()(
      dispatch,
      getState
    );

const refreshUserCarts =
  (userId: string, entityName: WebEntityName) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(entityName, `carts/userBuyerId/${userId}`, {})()(dispatch, getState);

const refreshCartDetail =
  (cartId: string, entityName: WebEntityName) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(entityName, `cartDetails/cart/${cartId}`, {})()(dispatch, getState);

const refreshLotDetail =
  (lotId: string, entityName: WebEntityName) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(entityName, `${E.LotActiveDependentEntity.endpoint}/${lotId}`, {})()(
      dispatch,
      getState
    );

const refreshAvailableStockDetail =
  (
    { itemId, stockOriginName }: { itemId: string; stockOriginName: string },
    entityName: WebEntityName
  ) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      entityName,
      `${E.AvailableStockEntity.endpoint}/item/${itemId}/origin/${stockOriginName}`,
      {}
    )()(dispatch, getState);

const refreshBarcodeSearch =
  (barcode: string) => async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      E.BarcodeSearchEntity.name as WebEntityName,
      `${E.BarcodeSearchEntity.endpoint}/${barcode}`,
      {}
    )()(dispatch, getState);

const refreshCartSearch = (lotId: string) => async (dispatch: AppDispatch, getState: AppGetState) =>
  await getEntitiesAction(
    E.CartSearchEntity.name as WebEntityName,
    `${E.CartSearchEntity.endpoint}/${lotId}`,
    {}
  )()(dispatch, getState);

const refreshUserDeliveryAddress =
  (userId: string, entityName: WebEntityName) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      entityName,
      `${E.UserDeliveryAddressEntity.endpoint}/getDeliveryAddress/${userId}`,
      {}
    )()(dispatch, getState);

/**
 * Add Example
 *
 * @param inventario: Array<GestionInventario>
 */
// export const ingresarInventario =
//   (inventario: Array<GestionInventario>, date: Date, zona: string) =>
//   async (dispatch: AppDispatch, getState: AppGetState) => {
//     try {
//       const res = await axios.post(
//         `/api/inventario`,
//         { data: inventario, fechaDocumento: cleanTextDate(date), zona },
//         tokenConfig(getState)
//       );
//       await refreshDocumento(dispatch, getState);
//       return res;
//     } catch (error) {
//       handleError({ error, entityName: E.GestionInventarioEntity.name, dispatch });
//     }
//   };

/**
 * Add item to the shopping cart
 *
 * @param item: item to add
 
// DEPRECATED
export const addItemToSaleOrder =
  (item: SimpleObject) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/trades/saleOrder`, item, tokenConfig(getState));
      await refreshEntity(E.ItemStockEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, dispatch });
    }
  };*/

/**
 * Cancel reserved sale order
 *
 * @param saleOrderId: string
 */
export const cancelReservedSaleOrder =
  (saleOrderId: SimpleObject) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.put(
        `/api/trades/saleOrder/reserved/cancel`,
        { saleOrderId },
        tokenConfig(getState)
      );
      await refreshEntity(E.SaleOrderEntity, { stateName: StateValues.Reservado })(
        dispatch,
        getState
      );
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Mark a shopping cart as returned "Devuleto"
 *
 * @param cartId: string
 */
export const markShoppingCartAsReturned =
  (cartId: SimpleObject) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.put(`/api/trades/shoppingCart/sold/return`, { cartId }, tokenConfig(getState));
      await refreshEntity(E.SoldCartAdminEntity, { page: 1, pageSize: 10 })(dispatch, getState);
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Add item to the shopping cart by barcode
 *
 * @param item: item to add
 */
export const addItemToShoppingCartByBarcode =
  (barcode: string, saleOrderId: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const { data: stockItems } = await axios.get(
        `/api/stock/barcodeSearch/${barcode}`,
        tokenConfig(getState)
      );

      if (stockItems && stockItems.length > 0 && stockItems[0].stockStateId === null) {
        const { isConfirmed } = await Swal.fire({
          title: 'El stock seleccionado no se encuentra publicado',
          showDenyButton: true,
          confirmButtonText: 'Publicar y Agregar',
          denyButtonText: 'Cancelar'
        });

        if (!isConfirmed) {
          return;
        }

        await axios.post(
          `/api/stock/publish`,
          { stockIds: [stockItems[0].stockId] },
          tokenConfig(getState)
        );
      }

      await axios.post(
        `/api/trades/addToShoppingCartByBarcode`,
        { barcode, saleOrderId },
        tokenConfig(getState)
      );
      await refreshEntity(E.ShoppingCartEntity, { saleOrderId })(dispatch, getState);
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

export const markShoppingCartAsSold =
  (shoppingCartData: Array<{ documentNumber: string; shoppingCartId: string }>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/trades/shoppingCart/pending/confirm`,
        { shoppingCartData },
        tokenConfig(getState)
      );
      await refreshEntity(E.PendingPaymentCartAdminEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

export const markShoppingCartAsDeclined =
  (shoppingCartId: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/trades/shoppingCart/pending/decline`,
        { shoppingCartId },
        tokenConfig(getState)
      );

      await refreshEntity(E.PendingPaymentCartAdminEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

export const markShoppingCartAsDeclinedSearchCart =
  (cartSearchData: { shoppingCartId: string; lotId: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.put(
        `/api/trades/shoppingCart/pending/decline`,
        { shoppingCartId: cartSearchData.shoppingCartId },
        tokenConfig(getState)
      );
      await refreshCartSearch(cartSearchData.lotId)(dispatch, getState);
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

export const expireReservedShoppingCart =
  (cartSearchData: { shoppingCartId: string; lotId: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.put(
        `/api/trades/shoppingCart/pending/expire`,
        { shoppingCartId: cartSearchData.shoppingCartId },
        tokenConfig(getState)
      );
      await refreshCartSearch(cartSearchData.lotId)(dispatch, getState);
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

export const addNewUserAddress =
  (
    newUserAddressData: {
      userId: string;
      deliveryAddress: string;
      isDefaultDeliveryAddress: boolean;
    },
    requestFrom: 'Profile' | 'Maintainer'
  ) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/userDeliveryAddresses/addNewUserAddress`,
        { newUserAddressData },
        tokenConfig(getState)
      );
      if (requestFrom === 'Maintainer') {
        await refreshEntity(E.UserEntity)(dispatch, getState);
        await refreshUserDeliveryAddress(
          newUserAddressData.userId,
          E.UserDeliveryAddressEntity.name
        )(dispatch, getState);
      }

      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Update Shopping Cart to place an order.
 */
export const placeAnOrder =
  (resumeOrder: {
    isDispatch: boolean;
    deliveryAddressId: string;
    saleOrderDate: string;
    buyerUserId: string;
  }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.put(
        `/api/trades/shoppingCart/placeAnOrder`,
        {
          isDispatch: resumeOrder.isDispatch,
          deliveryAddressId: resumeOrder.deliveryAddressId,
          saleOrderDate: resumeOrder.saleOrderDate,
          buyerUserId: resumeOrder.buyerUserId
        },

        tokenConfig(getState)
      );
      await refreshEntity(E.SaleOrderDetailEntity)(dispatch, getState);
      return true;
    } catch (error) {
      handleError({ error, dispatch });
      return false;
    }
  };

/**
 * Update Shopping Cart to place an order.
 * DEPRECATED
 */
// export const placePreSale =
//   (resumeOrder: {
//     isDispatch: boolean;
//     deliveryAddressId: string;
//     cartOrderDate: string;
//     buyerUserId: string;
//   }) =>
//   async (dispatch: AppDispatch, getState: AppGetState) => {
//     try {
//       const res = await axios.put(
//         `/api/trades/shoppingCart/placePreSale`,
//         {
//           isDispatch: resumeOrder.isDispatch,
//           deliveryAddressId: resumeOrder.deliveryAddressId,
//           cartOrderDate: resumeOrder.cartOrderDate,
//           buyerUserId: resumeOrder.buyerUserId
//         },

//         tokenConfig(getState)
//       );
//       await refreshEntity(E.ShoppingCartEntity)(dispatch, getState);
//       return res;
//     } catch (error) {
//       handleError({ error, dispatch });
//     }
//   };

/**
 * Update Shopping Cart to place an order.
 */
export const placePreSaleBasedOnSaleOrder =
  (saleOrderId: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/trades/shoppingCart/placePreSaleBasedOnSaleOrder`,
        {
          saleOrderId
        },

        tokenConfig(getState)
      );
      await refreshEntity(E.ShoppingCartEntity)(dispatch, getState);
      await refreshEntity(E.SaleOrderDetailEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Lot new items
 */
export const lotearItems =
  (itemIds: Array<String>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/lots/lotItems`, { itemIds }, tokenConfig(getState));
      await refreshEntity(E.StockToLotEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Publish available stock
 */
export const publishStock =
  (stockIds: Array<String>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/stock/publish`, { stockIds }, tokenConfig(getState));
      await refreshEntity(E.StockEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Edit massively stock origin
 */
export const massiveStockOriginEdition =
  ({
    stockIds,
    stockOriginId,
    stockOriginNameToFetch
  }: {
    stockIds: Array<String>;
    stockOriginId: String;
    stockOriginNameToFetch?: String;
  }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.patch(
        `/api/stock/stockOrigin`,
        { stockIds, stockOriginId },
        tokenConfig(getState)
      );
      if (stockOriginNameToFetch) {
        await refreshEntity(E.AvailableStockEntity, { stockOriginName: stockOriginNameToFetch })(
          dispatch,
          getState
        );

        await refreshEntity(E.AvailableStockDependentEntity)(dispatch, getState);
      } else {
        await refreshEntity(E.StockEntity)(dispatch, getState);
      }
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Unpublish available stock
 */
export const unpublishStock =
  (data: { stockIds: Array<String>; itemId: string; stockOriginName: string }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.patch(
        `/api/${E.AvailableStockDependentEntity.endpoint}`,
        { action: 'delete', entityIds: data.stockIds },
        tokenConfig(getState)
      );
      voidEntitiesAction(E.AvailableStockDependentEntity.name)()(dispatch);
      await refreshEntity(E.AvailableStockEntity, { stockOriginName: data.stockOriginName })(
        dispatch,
        getState
      );
      await refreshAvailableStockDetail(
        { itemId: data.itemId, stockOriginName: data.stockOriginName },
        E.AvailableStockDependentEntity.name
      )(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * simple Unlot
 */
export const unlot =
  (lotIds: Array<String>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/lots/unlot`, { lotIds }, tokenConfig(getState));
      await refreshEntityWithOptions(E.LotEntity, { query: { state: StateValues.Activo } })(
        dispatch,
        getState
      );
      await voidEntitiesAction(E.LotActiveDependentEntity.name)()(dispatch);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * advanced Unlot
 */
export const advancedUnlot =
  (lotId: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(`/api/lots/advancedUnlot`, { lotId }, tokenConfig(getState));

      await voidEntitiesAction(E.CartSearchEntity.name)()(dispatch);
      await voidEntitiesAction(E.CartSearchDependentEntity.name)()(dispatch);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Add Stock
 *
 * @param stock: Array<Inventario>
 */
export const addStock =
  (stock: Array<any>, stockOriginId: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/stock/massiveLoad`,
        { data: stock, stockOriginId },
        tokenConfig(getState)
      );
      await refreshEntity(E.StockEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.StockEntity.name, dispatch });
    }
  };

/**
 * Gets user cart
 *
 * @param userId: string
 */
export const fetchUserCarts =
  (entityName: WebEntityName, userState: SingleEntityState<User>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading(entityName));

    try {
      voidEntitiesAction(entityName)()(dispatch);
      await refreshUserCarts(userState.singleSelected?._id || '', entityName)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

/**
 * Gets Cart Detail
 *
 * @param cartId: string
 */
export const fetchCartDetail =
  (entityName: WebEntityName, cartState: SingleEntityState<Cart>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading(entityName));

    try {
      voidEntitiesAction(entityName)()(dispatch);
      await refreshCartDetail(cartState.singleSelected?._id || '', entityName)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

/**
 * Gets Lot Detail
 *
 * @param lotId: string
 */
export const fetchLotDetail =
  (entityName: WebEntityName, LotState: SingleEntityState<Lot>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading(entityName));

    try {
      voidEntitiesAction(entityName)()(dispatch);
      await refreshLotDetail(LotState.singleSelected?._id || '', entityName)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

/**
 * Gets Barcode Search
 *
 * @param barcode: string
 */
export const fetchBarcodeSearch =
  (barcode: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading(E.BarcodeSearchEntity.name));

    try {
      voidEntitiesAction(E.BarcodeSearchEntity.name as WebEntityName)()(dispatch);
      await refreshBarcodeSearch(barcode)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.BarcodeSearchEntity.name, dispatch });
    }
  };

/**
 * Gets Lot Search
 *
 * @param lotId: string
 */
export const fetchCartSearch =
  (lotId: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading(E.CartSearchEntity.name));

    try {
      voidEntitiesAction(E.CartSearchEntity.name as WebEntityName)()(dispatch);
      await refreshCartSearch(lotId)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.CartSearchEntity.name, dispatch });
    }
  };

/**
 * Gets User Delivery Address
 *
 * @param userId: string
 */
export const fetchUserDeliveryAddress =
  (entityName: WebEntityName, userState: SingleEntityState<User>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading(entityName));

    try {
      voidEntitiesAction(entityName)()(dispatch);
      await refreshUserDeliveryAddress(userState.singleSelected?._id || '', entityName)(
        dispatch,
        getState
      );
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

/**
 * Gets Available Stock Detail
 *
 * @param lotId: string
 */
export const fetchAvailableStockDetail =
  (entityName: WebEntityName, itemState: SingleEntityState<AvailableStock>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading(entityName));

    try {
      voidEntitiesAction(entityName)()(dispatch);
      await refreshAvailableStockDetail(
        {
          itemId: itemState.singleSelected?._id || '',
          stockOriginName: itemState.singleSelected?.stockOriginName || ''
        },
        entityName
      )(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName, dispatch });
    }
  };

export const setDefaultDeliveryAddress =
  (deliveryAddressData: {
    entity: WebEntity<any>;
    userId: string;
    userDeliveryAddressId: string;
  }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const { entity, userId, userDeliveryAddressId } = deliveryAddressData;
    dispatch(setEntityLoading(entity.name));

    try {
      const res = await axios.put(
        `/api/userDeliveryAddresses/setDefaultDeliveryAddress`,
        { deliveryAddressData: { userId, userDeliveryAddressId } },
        tokenConfig(getState)
      );
      if (entity.name === E.UserDeliveryAddressEntity.name) {
        await refreshEntity(E.UserEntity)(dispatch, getState);
        await refreshUserDeliveryAddress(userId, E.UserDeliveryAddressEntity.name)(
          dispatch,
          getState
        );
      } else if (entity.name === E.UserDeliveryAddressProfileEntity.name) {
        await refreshEntity(entity)(dispatch, getState);
      }

      return res;
    } catch (error) {
      handleError({ error, entityName: entity.name, dispatch });
    }
  };

/**
 * Remove Lots from cart
 */
export const removeLotsFromCart =
  (itemIds: string[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      if (!window.confirm(`¿Seguro que deseas eliminar: \r ${itemIds}?`)) {
        return;
      }

      await deleteEntityAction(
        E.ShoppingCartItemsEntity.name,
        E.ShoppingCartItemsEntity.endpoint
      )(itemIds)(dispatch, getState);

      await refreshEntity(E.ShoppingCartItemsEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Remove lot details from cart
 */
export const removeLotDetailFromCart =
  (cartDetailIds: string[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      if (!window.confirm(`¿Seguro que deseas eliminar: \r ${cartDetailIds}?`)) {
        return;
      }

      await deleteEntityAction(
        E.ShoppingCartEntity.name,
        E.ShoppingCartEntity.endpoint
      )(cartDetailIds)(dispatch, getState);

      await refreshEntity(E.ShoppingCartItemsEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, dispatch });
    }
  };

/**
 * Remove lot details from lot Admin
 */
/*
export const removeLotDetailFromLotAdmin =
  (data: { lotId: string; lotDetailIds: string[] }) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.put(
        `/api/lots/removeLotDetailsFromLot`,
        { lotDetailIds: data.lotDetailIds },
        tokenConfig(getState)
      );

      await refreshEntityWithOptions(E.LotEntity, { query: { state: StateValues.Activo } })(
        dispatch,
        getState
      );
      await refreshLotDetail(data.lotId, E.LotDetailEntity.name)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, dispatch });
    }
  };*/
