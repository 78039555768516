import { PriceListEstandar } from '../types';
import { WebEntity } from './types';

const PriceListEstandarEntity: WebEntity<PriceListEstandar> = {
  name: 'priceListEstandar',
  endpoint: 'priceLists/estandar',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Corte',
      selector: 'itemName',
      wrap: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    {
      name: 'Corte',
      selector: 'itemName',
      format: 'text'
    }
  ]
};

export default PriceListEstandarEntity;
