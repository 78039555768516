import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import '../css/priceList.css';
import GenericIcons from './generics/GenericIcons';
import { pagesName } from '../pages/AnonimousPage';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';
import { ItemStock, SimpleObject } from '../types';

interface IProps {
  auth: AuthState;
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
}

const PriceList: FunctionComponent<IProps> = ({ auth, setPageContentName }) => {
  const [priceList, setPriceList] = useState<ItemStock[]>([]);
  const [items, setItems] = useState<string[]>([]);
  const [brands, setBrands] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getUncatchEndpointData({
        endpoint: 'trades/itemsEstandar',
        query: { onlyItemsWithStock: false }
      });
      setPriceList(data);
      setLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (priceList.length > 0) {
      setItems(Array.from(new Set(priceList.map((row) => row.itemName))).sort());
      setBrands(Array.from(new Set(priceList.map((row) => row.brandName))).sort());
    }
  }, [priceList]);

  return (
    <div className={'price-list-container'}>
      {!auth.isAuthenticated && (
        <div className='price-list-blur'>
          <button onClick={() => setPageContentName(pagesName.login)}>
            <p>Ver lista de precios</p>
          </button>
        </div>
      )}

      <div className='price-list-title'>
        <h1>
          <GenericIcons />
          Lista de precios
        </h1>
        <p>{dateFormatFn(new Date().toISOString(), 'DD/MM/YYYY', false)}</p>
      </div>
      <div className='price-list-table'>
        {priceList.length > 0 && brands.length > 0 && items.length > 0 ? (
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th></th>
                {brands.map((brand, idx) => (
                  <th key={`thead-th-key-${idx}`}>{brand}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.map((item, idx) => (
                <tr key={`tbody-tr-key-${idx}`}>
                  <th>{item}</th>
                  {brands.map((brand, idxB) => {
                    const findItem = priceList.find(
                      (row) => row.brandName === brand && row.itemName === item
                    );
                    return findItem ? (
                      <th key={`tbody-th-key-${idx}-${idxB}`}>{`$${formatStringNumber(
                        auth.isAuthenticated ? findItem.unitOfMeasurePrice.toString() : '5000'
                      )}`}</th>
                    ) : (
                      <th key={`tbody-th-key-${idx}-${idxB}`}></th>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        ) : !loading ? (
          <h3>No hay items disponibles</h3>
        ) : (
          <h3>Cargando...</h3>
        )}
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(PriceList);
