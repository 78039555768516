import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { performUncatchedRequest } from '../utils/request';
import { Button } from 'semantic-ui-react';

const TexBox = ({ twoLengthChar }: { twoLengthChar: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        paddingInline: '0.5em',
        paddingBlock: '0.5em',
        borderStyle: 'solid',
        borderColor: 'black',
        borderWidth: '3px',
        width: '5em',
        justifyContent: 'center'
      }}
    >
      <h1>{twoLengthChar}</h1>
    </div>
  );
};

const AuthorizationPage: FunctionComponent = () => {
  const emptyKey = ['', '', '', '', '', ''];
  const [generatedKey, setGeneratedKey] = useState<string[]>(emptyKey);
  const [countDown, setCountDown] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const localClearInterval = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const generateCode = async () => {
    try {
      if (timerRef.current || countDown > 0) {
        return;
      }

      // Generate and get authorization Key
      const res = await performUncatchedRequest({
        endpoint: `trades/generateAuthorizationKey`,
        method: 'get'
      });

      const authorizationKey = res.data as { endDate: Date; password: string };

      // Calculate miliseconds to countdown
      const waitingTimeInSeconds = Math.round(
        (new Date(authorizationKey.endDate).getTime() - new Date().getTime()) / 1000
      );
      setCountDown(waitingTimeInSeconds);

      // Split password in 6 pairs (for easy display)
      const password = authorizationKey.password;
      const keyPart = password.substring(password.length - 12).match(/.{1,2}/g) || emptyKey; // split in array of three

      setGeneratedKey(keyPart);

      timerRef.current = setInterval(() => {
        setCountDown((count) => {
          if (count > 0) {
            return count - 1;
          }

          localClearInterval();
          setGeneratedKey(emptyKey);
          return 0;
        });
      }, 1000);
    } catch (error) {
      console.log(error);
      localClearInterval();
    }
  };

  useEffect(() => {
    return () => {
      localClearInterval();
    };
  }, []);

  return (
    <div style={{ margin: '2em 2em' }}>
      <h1>Generar Clave Autorización</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '40em'
        }}
      >
        {generatedKey.map((k, idx) => (
          <TexBox twoLengthChar={k || '--'} key={`authorization_key_part_${idx}`} />
        ))}

        <div style={{ fontSize: '40px' }}>{countDown}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '40em', marginTop: '3em' }}>
        <Button color={'green'} onClick={generateCode}>
          Generar Autorización
        </Button>
      </div>
    </div>
  );
};

export default AuthorizationPage;
