export const ButtonCargarInventario = {
  color: ' #FFF',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  fontfFeatureSettings: 'clig off, liga off',
  lineHeight: '24px',
  borderRadius: '8px',
  padding: '12px 15px',
  border: 'none',
  background: '#64A70B',
  display: 'flex',
  alignItems: 'center'
};
