import { BarcodeSearch } from '../types';
import { WebEntity } from './types';
import { dateFormatFn, formatStringNumber, booleanFormatFn } from '../utils/utils';

const BarcodeSearchEntity: WebEntity<BarcodeSearch> = {
  name: 'barcodeSearch',
  endpoint: 'stock/barcodeSearch',
  referenceColumn: 'barcode',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Barcode',
      selector: 'barcode',
      sortable: true,
      wrap: true
    },
    {
      name: 'Estado',
      selector: 'stateName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Loteado?',
      selector: 'isLoted',
      format: ({ isLoted }) => booleanFormatFn(isLoted),
      sortable: true,
      wrap: true
    },
    {
      name: 'Lote?',
      selector: 'lotId',
      sortable: true,
      wrap: true
    },
    {
      name: 'Numero Venta?',
      selector: 'cartId',
      sortable: true,
      wrap: true
    },

    {
      name: 'Producto',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad (kg)',
      selector: 'unitOfMeasureQuantity',
      format: ({ unitOfMeasureQuantity }) => `${unitOfMeasureQuantity}Kg`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio Unitario',
      selector: 'unitOfMeasurePrice',
      format: ({ unitOfMeasurePrice }) => `$${formatStringNumber(unitOfMeasurePrice.toString())}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio Total',
      selector: 'unitOfMeasureQuantity',
      format: ({ unitOfMeasureQuantity, unitOfMeasurePrice }) =>
        `$${formatStringNumber((unitOfMeasureQuantity * unitOfMeasurePrice).toString())}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'En remate?',
      selector: 'isForAuction',
      format: ({ isForAuction }) => booleanFormatFn(isForAuction),
      sortable: true,
      wrap: true
    },
    {
      name: 'Usuario?',
      selector: 'userFullname',
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Actualizacion',
      selector: 'dateUpdated',
      format: ({ dateUpdated }) => dateFormatFn(dateUpdated),
      sortable: true,
      wrap: true
    }
  ],

  fields: [],
  editableFields: []
};

export default BarcodeSearchEntity;
