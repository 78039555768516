import React, { Fragment, FunctionComponent } from 'react';
import { Table } from 'semantic-ui-react';
import { isObjectEmpty, multiSubFieldSelector, MULTI_FIELD_TOKEN } from '../../utils/utils';

type ObjectTableProps = {
  object: any;
  fields?: Array<string>;
};

const ObjectTable: FunctionComponent<ObjectTableProps> = ({ object, fields = [] }) => {
  return (
    <Table celled padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign='center'>Campo</Table.HeaderCell>
          <Table.HeaderCell textAlign='center'>Contenido</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {!object || isObjectEmpty(object) ? (
          <Table.Row>
            <Table.Cell>
              <p>Sin datos</p>
            </Table.Cell>
          </Table.Row>
        ) : (
          (fields.length > 0 ? fields : Object.keys(object)).map((fieldName, key) => (
            <Fragment key={`object_table_${key}`}>
              <Table.Row>
                <Table.Cell>{fieldName}</Table.Cell>
                <Table.Cell>
                  {typeof object[fieldName] !== 'object' ? (
                    fieldName.includes(MULTI_FIELD_TOKEN) ? (
                      <ObjectTable object={multiSubFieldSelector(fieldName, object)} />
                    ) : (
                      JSON.stringify(multiSubFieldSelector(fieldName, object))
                    )
                  ) : (
                    <ObjectTable object={object[fieldName]} />
                  )}
                </Table.Cell>
              </Table.Row>
            </Fragment>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default ObjectTable;
