import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { AppState } from '../store';
import {
  UsuarioCRUD,
  UserDeliveryAddressDependentCRUD,
  TipoUsuarioCRUD,
  ItemCRUD,
  BrandCRUD,
  CountryCRUD,
  ElementCRUD
} from '../components/GeneratedComponents';
import { fetchUserDeliveryAddress } from '../actions/middleActions';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import * as E from '../entities';

const ConnectedRefreshUserDeliveryAddress = connect(
  ({ entities: { user } }: AppState) => ({
    state: user,
    entityName: E.UserDeliveryAddressEntity.name
  }),
  {
    fetchFunction: fetchUserDeliveryAddress
  }
)(RefresherComponent);

const MantenedoresPage: FunctionComponent<{}> = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Tab
        menu={{ color: 'red', pointing: true }}
        panes={[
          {
            menuItem: { key: 'usuario-admin', icon: 'user', content: 'Usuario' },
            render: () => (
              <Tab.Pane attached={true}>
                <ConnectedRefreshUserDeliveryAddress />
                <UsuarioCRUD />
                <UserDeliveryAddressDependentCRUD />
              </Tab.Pane>
            )
          },
          {
            menuItem: { key: 'tipo-usuario-admin', icon: 'users', content: 'Tipo Usuario' },
            render: () => (
              <Tab.Pane attached={false}>
                <TipoUsuarioCRUD />
              </Tab.Pane>
            )
          },
          {
            menuItem: { key: 'item-admin', icon: 'boxes', content: 'Item' },
            render: () => (
              <Tab.Pane attached={false}>
                <ItemCRUD />
              </Tab.Pane>
            )
          },
          {
            menuItem: { key: 'pais-origen-admin', icon: 'world', content: 'Pais Origen' },
            render: () => (
              <Tab.Pane attached={false}>
                <CountryCRUD />
              </Tab.Pane>
            )
          },
          {
            menuItem: { key: 'marca-admin', icon: 'tag', content: 'Marca' },
            render: () => (
              <Tab.Pane attached={false}>
                <BrandCRUD />
              </Tab.Pane>
            )
          },
          {
            menuItem: { key: 'elements-admin', icon: 'images', content: 'Cortes' },
            render: () => (
              <Tab.Pane attached={false}>
                <ElementCRUD />
              </Tab.Pane>
            )
          }
        ]}
      />
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), {})(MantenedoresPage);
