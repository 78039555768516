import { GeneralSaleRule } from '../types';
import { FieldTypes, WebEntity } from './types';

const GeneralSaleRuleEntity: WebEntity<GeneralSaleRule> = {
  name: 'generalSaleRule',
  endpoint: 'rules/generalSale',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre Regla',
      selector: 'ruleName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Descripción',
      selector: 'description',
      sortable: true,
      wrap: true
    },
    {
      name: 'Valor',
      selector: 'ruleValue',
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre Regla',
      selector: 'ruleName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Valor',
      selector: 'ruleValue',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    /*{
      name: 'Nombre Regla',
      selector: 'ruleName',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Descripcion',
      selector: 'description',
      type: FieldTypes.Text,
      required: true
    },*/
    {
      name: 'Valor',
      selector: 'ruleValue',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default GeneralSaleRuleEntity;
