import React, { FunctionComponent, useState, useMemo } from 'react';
import { generateCRUD } from '../components/CRUDGenerator';
import { StateValues } from '../types';
import * as E from '../entities';
import SaleOrderDetailCompletionPage from '../pages/SaleOrderDetailCompletionPage';
import { Button } from 'semantic-ui-react';
import { OrderToCompleteModalActions } from '../components/SecondaryButton';

const ORDER_TO_COMPLETE_PAGE = 'OrderToCompletePage';
const SHOPPING_CART_PAGE = 'ShoppingCartPage';

const generateSaleOrdersToComplete = (pageSwitcher: (orderId: string) => () => void) =>
  generateCRUD({
    webEntity: E.SaleOrderEntity,
    title: 'Ordenes por completar',
    viewInputFilter: false,
    serverSidePagination: true,
    query: { stateName: StateValues.Reservado },
    columnComponent: {
      begin: true,
      column: {
        cell: (order) => (
          <OrderToCompleteModalActions pageSwitcher={pageSwitcher} saleOrderId={order._id} />
        ),
        allowOverflow: true,
        button: true
      }
    },
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

const SoldCartsPage: FunctionComponent = () => {
  const [pageToShow, setPageToShow] = useState<string>(ORDER_TO_COMPLETE_PAGE);
  const [saleOrderId, setSaleOrderId] = useState<string>('');

  const showShoppingCartPage = (orderId: string) => () => {
    setPageToShow(SHOPPING_CART_PAGE);
    setSaleOrderId(orderId);
  };

  const comebackCallback = () => {
    setPageToShow(ORDER_TO_COMPLETE_PAGE);
  };

  const SaleOrdersToComplete = useMemo(
    () => generateSaleOrdersToComplete(showShoppingCartPage),
    [showShoppingCartPage]
  );

  return (
    <>
      {pageToShow === ORDER_TO_COMPLETE_PAGE ? (
        <SaleOrdersToComplete />
      ) : (
        <>
          <Button onClick={comebackCallback} color='red'>
            Volver
          </Button>
          <SaleOrderDetailCompletionPage saleOrderId={saleOrderId} />
        </>
      )}
    </>
  );
};

export default SoldCartsPage;
