import { Cart, UserTypeValues } from '../types';
import { formatStringNumber, dateFormatFn, booleanFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const CartEntity: WebEntity<Cart> = {
  name: 'cart',
  endpoint: 'trades/shoppingCart/pending',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Numero',
      selector: '_id',
      sortable: true
    },
    {
      name: 'Asistente Compra',
      selector: 'requesterUserFullname',
      sortable: true,
      wrap: true
    },
    {
      name: 'Nombre Cliente',
      selector: 'buyerUserFullname',
      sortable: true,
      wrap: true
    },
    {
      name: 'Email Cliente',
      selector: 'buyerUserEmail',
      sortable: true,
      wrap: true
    },
    {
      name: 'Estado',
      selector: 'stateName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cajas',
      selector: 'packagingQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Kgs',
      selector: 'unitOfMeasureQuantity',
      format: ({ unitOfMeasureQuantity }) =>
        unitOfMeasureQuantity ? `${formatStringNumber(unitOfMeasureQuantity, true, 0, 2)} Kg` : '',
      sortable: true,
      wrap: true
    },
    {
      name: 'Total Original',
      selector: 'cartOriginalTotalPrice',
      format: ({ cartOriginalTotalPrice }) =>
        cartOriginalTotalPrice ? `$${formatStringNumber(cartOriginalTotalPrice.toString())}` : '',
      sortable: true
    },
    {
      name: 'Total con descuentos y recargos',
      selector: 'cartTotalPrice',
      format: ({ cartTotalPrice }) => `$${formatStringNumber(cartTotalPrice.toString())}`,
      sortable: true
    },
    {
      name: 'IVA',
      selector: 'taxPrice',
      // TODO: Revisar a nivel de backend por que da null en vez de 0
      format: ({ taxPrice }) => (taxPrice ? `$${formatStringNumber(taxPrice.toString())}` : '0'),
      sortable: true
    },
    {
      name: 'Total + IVA',
      selector: 'cartTotalPriceWithIva',
      format: ({ totalNetPrice }) =>
        totalNetPrice ? `$${formatStringNumber(totalNetPrice.toString())}` : '0',
      sortable: true
    },
    {
      name: 'Entrega Por Delivery',
      selector: 'isDispatch',
      format: ({ isDispatch }) => booleanFormatFn(isDispatch),
      sortable: true
    },
    {
      name: 'Dirección Despacho',
      selector: 'deliveryAddress',
      sortable: true
    },
    {
      name: 'Recargo por despacho',
      selector: 'cartTotalDeliveryCharge',
      format: ({ cartTotalDeliveryCharge }) =>
        cartTotalDeliveryCharge
          ? `$${formatStringNumber(cartTotalDeliveryCharge.toString())}`
          : '0',
      sortable: true
    },
    {
      name: 'Descuentos Totales',
      selector: 'cartTotalDiscount',
      format: ({ cartTotalDiscount }) =>
        cartTotalDiscount ? `$${formatStringNumber(cartTotalDiscount.toString())}` : '0',
      sortable: true
    },
    {
      name: 'Fecha Orden',
      selector: 'cartOrderDate',
      format: ({ cartOrderDate }) => dateFormatFn(cartOrderDate, 'DD/MM/YYYY HH:mm:ss'),
      wrap: true,
      sortable: true
    },
    {
      name: 'Fecha Venta',
      selector: 'cartOrderDate',
      format: ({ cartSaleDate }) => dateFormatFn(cartSaleDate, 'DD/MM/YYYY HH:mm:ss'),
      wrap: true,
      sortable: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [
    {
      name: 'Item',
      selector: 'itemId',
      type: FieldTypes.MultiReference,
      required: false,
      reference: {
        name: 'item',
        endpoint: 'items',
        select: 'itemId',
        show: 'itemName,brandName,countryName'
      }
    },
    {
      name: 'Comprador',
      selector: 'userBuyerId',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'user',
        endpoint: 'users',
        select: 'userId',
        show: 'userFullname,nationalId',
        filter: { userTypeNames: [UserTypeValues.Cliente] }
      }
    },
    {
      name: 'Vendedor',
      selector: 'userRequesterId',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'user',
        endpoint: 'users',
        select: 'userId',
        show: 'userFullname,nationalId',
        filter: { userTypeNames: [UserTypeValues.Operador, UserTypeValues.Admin] }
      }
    }
  ]
};

export default CartEntity;
