import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import io, { Socket } from 'socket.io-client';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import Profile from './Profile';
import SaleOrderPage from './SaleOrderPage';
import LoginPage from '../components/auth/LoginPage';
import SignUpPage from '../components/auth/SignUpPage';
import { SignalValues } from '../types';
import AuctionPage from './AuctionPage';
import { refreshShoppingCart } from '../actions/middleActions';
import NavBar from '../components/NavBar';
import PriceExchange from '../components/PriceExchange';
import Footer from '../components/Footer';
import './css/anonimousPage.css';
import HomePage from './HomePage';
import ProductStorePage from './ProductStorePage';

moment.locale('es');

interface IPropsAnonimousPage {
  auth: AuthState;
  refreshShoppingCart: any;
}

export const pagesName = {
  home: 'home',
  trade: 'trade',
  auction: 'auction',
  shoppingCart: 'shopping_cart',
  profile: 'profile',
  login: 'login',
  signUp: 'sign_up'
};

const AnonimousPage: FunctionComponent<IPropsAnonimousPage> = ({ auth, refreshShoppingCart }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [pageContentName, setPageContentName] = useState<string>(pagesName.home);
  const [clientWidth, setClientWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setClientWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setClientWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log('Connecting...');

      const updatedSocket = io({
        host: process.env.WDS_SOCKET_HOST,
        path: process.env.WDS_SOCKET_PATH,
        port: process.env.WDS_SOCKET_PORT,
        query: {
          token: auth.token
        }
      });

      updatedSocket.on('connect', () => {
        updatedSocket.emit(SignalValues.connectedUsers, auth.user?.userUsername);
      });

      setSocket(updatedSocket);
    } else {
      if (socket) {
        console.log('Disconnecting by authentication update');
        socket.disconnect();
        setSocket(null);
      }
    }
  }, [auth, auth.isAuthenticated]);

  return (
    <div className='anonimous-page-container'>
      <div className='anonimous-page-body'>
        <NavBar
          pageContentName={pageContentName}
          setPageContentName={setPageContentName}
          refreshShoppingCart={refreshShoppingCart}
          socket={socket}
        />
        {clientWidth > 1000 && <PriceExchange />}
        <div className='anonimous-page-content'>
          {pageContentName === pagesName.login ? (
            !auth.isAuthenticated && <LoginPage setPageContentName={setPageContentName} />
          ) : pageContentName === pagesName.signUp ? (
            <SignUpPage setPageContentName={setPageContentName} />
          ) : pageContentName === pagesName.home ? (
            <HomePage
              auth={auth}
              setPageContentName={setPageContentName}
              socket={socket}
              setSocket={setSocket}
            />
          ) : pageContentName === pagesName.trade ? (
            <Fragment>
              <ProductStorePage />
            </Fragment>
          ) : pageContentName === pagesName.profile ? (
            <Profile />
          ) : pageContentName === pagesName.auction ? (
            <AuctionPage socket={socket} />
          ) : pageContentName === pagesName.shoppingCart ? (
            <SaleOrderPage />
          ) : (
            /* <ShoppingCart /> */

            <></>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { refreshShoppingCart })(AnonimousPage);
