import { AvailableStock } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const AvailableStockEntity: WebEntity<AvailableStock> = {
  name: 'availableStock',
  endpoint: 'stock/available',
  referenceColumn: '_id',
  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Item',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Empaque',
      selector: 'packagingName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad de Medida',
      selector: 'unitOfMeasureName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad en Bodega', // NOTE: includes non published and published
      selector: 'totalStockQuantity',
      sortable: true,
      wrap: true,
      omit: true
    },
    {
      name: 'Cantidad Sin Publicar',
      selector: 'unpublishedStockQuantity',
      sortable: true,
      wrap: true,
      omit: true
    },
    {
      name: 'Cantidad Total',
      selector: 'totalAvailableStockQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Publicada',
      selector: 'availablePublishedStockQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Pedida',
      selector: 'reservedStockQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Kgs Disponible',
      selector: 'unitOfMeasureQuantity',
      format: ({ publishedAvailableUnitOfMeasureQuantity }) =>
        `${formatStringNumber(publishedAvailableUnitOfMeasureQuantity, true, 0, 2)}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Elaboración',
      selector: 'elaborationDate',
      format: ({ elaborationDate }) => `${dateFormatFn(elaborationDate, 'DD/MM/YYYY', true)}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      format: ({ expirationDate }) => `${dateFormatFn(expirationDate, 'DD/MM/YYYY', true)}`,
      sortable: true,
      wrap: true
    }
  ],

  fields: [],

  editableFields: []
};

export default AvailableStockEntity;
