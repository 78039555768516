import { CartDetail } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const CartDetailEntity: WebEntity<CartDetail> = {
  name: 'cartDetail',
  endpoint: 'cartDetails',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'barcode',
      selector: 'barcode',
      wrap: true
    },
    {
      name: 'Item',
      selector: 'itemName',
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName'
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName'
    },
    {
      name: 'Pais Origen',
      selector: 'countryName'
    },
    {
      name: 'Estado',
      selector: 'stateName',
      wrap: true
    },
    {
      name: 'Unidad de Medida',
      selector: 'unitOfMeasureName'
    },
    {
      name: 'Empaque',
      selector: 'packagingName'
    },
    {
      name: 'Kgs',
      selector: 'unitOfMeasureQuantity',
      format: ({ unitOfMeasureQuantity }) =>
        `${formatStringNumber(unitOfMeasureQuantity.toString(), true, 0, 2)}Kg`
    },
    {
      name: 'Precio (Kg)',
      selector: 'unitOfMeasurePrice',
      format: ({ unitOfMeasurePrice }) => `$${formatStringNumber(unitOfMeasurePrice.toString())}`
    },
    {
      name: 'Descuento',
      selector: 'priceDiscount',
      format: ({ priceDiscount }) => `$${formatStringNumber(priceDiscount.toString())}`
    },
    {
      name: 'Cargo Delivery',
      selector: 'unitOfMeasureDeliveryCharge',
      format: ({ unitOfMeasureDeliveryCharge }) =>
        `$${formatStringNumber(unitOfMeasureDeliveryCharge.toString())}`
    },
    {
      name: 'Precio (Kg) Final',
      selector: 'unitOfMeasurePrice',
      format: ({ unitOfMeasurePrice, priceDiscount, unitOfMeasureDeliveryCharge }) =>
        `$${formatStringNumber(
          (unitOfMeasurePrice - priceDiscount + unitOfMeasureDeliveryCharge).toString()
        )}`
    },
    {
      name: 'Fecha Elaboración',
      selector: 'elaborationDate',
      format: ({ elaborationDate }) => dateFormatFn(elaborationDate, 'DD/MM/YYYY', true),
      wrap: true
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      format: ({ expirationDate }) => dateFormatFn(expirationDate, 'DD/MM/YYYY', true),
      wrap: true
    }
  ],

  fields: [],
  editableFields: []
};

export default CartDetailEntity;
