import React, { FunctionComponent } from 'react';
import '../css/news.css';

interface IProps {
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
}

const News: FunctionComponent<IProps> = ({ setPageContentName }) => {
  return (
    <div className='news-container'>
      <p>Científicos descubren un nuevo y desconocido superpoder del yogurt</p>
      <p>La producción mundial de leche tendría un modesto crecimiento en 2024</p>
      <p>Las bebidas azucaradas que desde hoy empiezan a tener impuesto</p>
      <p>
        Avanza proyecto que busca prohibir catalogar de "carne" a los productos sucedáneos veganos
      </p>
      <a href=''>Ver todas las noticias</a>
    </div>
  );
};

export default News;
