import React, { FunctionComponent } from 'react';
import { SoldCartAdminCRUD, SoldCartAdminDependentCRUD } from '../components/GeneratedComponents';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { requestErrorPopAlert } from '../components/PopAlert';
import { getUncatchEndpointData } from '../utils/request';
import { Button, Icon } from 'semantic-ui-react';
import { downloadXLSX } from '../utils/export';
import { modificarOrdenColumnas } from '../utils/utils';
import { fetchCartDetail } from '../actions/middleActions';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import * as E from '../entities';
import { StateValues } from '../types';

const ConnectedRefreshSoldCart = connect(
  ({ entities: { soldCartAdmin } }: AppState) => ({
    state: soldCartAdmin,
    entityName: E.SoldCartAdminDependentEntity.name
  }),
  {
    fetchFunction: fetchCartDetail
  }
)(RefresherComponent);

const columnsReporte: Record<string, string> = {
  cartId: 'Numero',
  requesterUserFullname: 'Asistente Compra',
  buyerUserFullname: 'Nombre Cliente',
  buyerUserEmail: 'Email Cliente',
  stateName: 'Estado',
  cartOriginalTotalPrice: 'Total Original',
  cartTotalPrice: 'Total con descuentos y recargos',
  taxPrice: 'IVA',
  totalNetPrice: 'Total + IVA',
  isDispatch: 'Entrega por delivery',
  deliveryAddress: 'Direccion despacho',
  cartTotalDeliveryCharge: 'Recargo por despacho',
  cartTotalDiscount: 'Descuentos totales',
  cartOrderDate: 'Fecha Orden',
  cartSaleDate: 'Fecha Venta',
  barcode: 'Barcode',
  stockOriginName: 'Bodega',
  itemName: 'Item',
  brandName: 'Marca',
  countryName: 'pais',
  unitOfMeasureName: 'Unidad',
  packagingName: 'Empaque',
  unitOfMeasureQuantity: 'Kgs',
  unitOfMeasurePrice: 'Precio (Kg)',
  itemTotalPrice: 'Precio Total',
  itemTaxPrice: 'IVA Precio',
  totalPriceOfTheItemWithTax: 'Total + IVA Precio'
};

const SoldCartsPage: FunctionComponent = () => {
  const [isExporting, setIsExporting] = React.useState<boolean | null>(null);

  const onDescargarReporteClick = () => async () => {
    try {
      setIsExporting(true);
      const data = await getUncatchEndpointData({
        endpoint: 'trades/CartAndDetailByState/export',
        query: { stateName: StateValues.Vendido }
      });

      const newBlob = modificarOrdenColumnas(data, columnsReporte);

      if (!newBlob.size) {
        setIsExporting(false);
        requestErrorPopAlert({
          response: {
            data: { msg: 'No se encontraron registros' }
          }
        });
        return;
      }
      downloadXLSX(newBlob);
      setIsExporting(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setIsExporting(false);
    }
  };

  return (
    <>
      <div style={{ padding: '3em' }}>
        <h1>En Construcccion...</h1>
      </div>

      {/* <Button style={{ marginLeft: '1em' }} color='blue' onClick={onDescargarReporteClick()}>
        <Icon name={'newspaper outline'} />
        Exportar Reporte
      </Button>
      {isExporting === true && <h1>Exportando...</h1>}
      <ConnectedRefreshSoldCart />
      <SoldCartAdminCRUD />
      <SoldCartAdminDependentCRUD /> */}
    </>
  );
};

export default SoldCartsPage;
