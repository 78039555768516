import React, { FunctionComponent } from 'react';
import { GeneralSaleRuleCRUD, DiscountRuleCRUD } from '../components/GeneratedComponents';
import './css/tableExample.css';

const SaleOptionsPage: FunctionComponent<{}> = () => {
  return (
    <div style={{ padding: '20px 15px' }}>
      <h1>Reglas de Venta</h1>

      <GeneralSaleRuleCRUD />
      <DiscountRuleCRUD />

      <div>
        Ejemplo:
        <table style={{ border: '1px solid black' }}>
          <tr>
            <td>Condicion</td>
            <td>Cantidad</td>
            <td>Unidad</td>
            <td>Operacion</td>
            <td>Valor Operador</td>
          </tr>
          <tr>
            <td>{'>'}</td>
            <td>3</td>
            <td>caja</td>
            <td>*</td>
            <td>0.75</td>
          </tr>
        </table>
        <p>
          Supongamos que un lote tiene 10 cajas de 10 Kg a $1.000 pesos el kilo. <br />
          Basado en la condición anterior, si: <br />
          - El cliente compra más de 3 cajas (digamos 4) <br />
          Entonces: <br />
          - Precio de Compra Original: 4 (cajas) * 10 (kg) * 1.000 ($) = $40.000 <br />
          - Operacion aplicada: 4 (cajas) * 10 (kg) * 1.000 ($) * 0.75 = $30.000 <br />
        </p>
      </div>
    </div>
  );
};

export default SaleOptionsPage;
