import React, { ChangeEvent, useState } from 'react';
import { connect } from 'react-redux';
import * as E from '../entities';
import { localEditEntityAction } from '../actions/entitiesActions';
import { generateCRUD } from './CRUDGenerator';
import {
  //AddItemToSaleOrderButton,
  PendingPaymentCartModalActions,
  LotearItemsButton,
  UnlotButton,
  ConnectedNewAddressForm,
  SetDefaultDeliveryAddressBtn,
  RemoveLotsFromCartButton,
  RemoveLotDetailsFromCartButton,
  PurchaseDataPDFButton,
  CartSearchActions,
  //RemoveLotDetailFromLotAdmin,
  PublishStockButton,
  MassiveStockOriginEditionButton,
  SoldCartModalActions
} from './SecondaryButton';
import { StateValues } from '../types';
import { ManageImage } from './ManageImage';

// Component Example
/* export const OrdenesRetiroAnuladas = generateCRUD({
 *   webEntity: {
 *     ...E.OrdenEntity,
 *     tableColumns: canceledReturnOrderColumns,
 *     filterFields: []
 *   },
 *   title: 'ordenes retiro anuladas',
 *   GETPath: 'byOrderFilters',
 *   query: Q.ordenesEnEstadoV2(OrderStatus.Canceled),
 *   serverSidePagination: true,
 *   additionalTableActions: ({ selected }) => (
 *     <div>
 *       <RevertReturnOrderButton selected={selected} refreshStatus={OrderStatus.Canceled} />
 *     </div>
 *   ),
 *   columnComponent: {
 *     begin: true,
 *     column: {
 *       cell: (row) => <OrdenDetails orden={row} />,
 *       allowOverflow: true,
 *       button: true
 *     }
 *   },
 *   customExport: {
 *     exportDataHandler: async () =>
 *       await getEndpointData({
 *         endpoint: 'v2/ordenes/export/returnOrderAt',
 *         query: { status: OrderStatus.Canceled },
 *         isBlob: true
 *       })
 *   },
 *   allowedActions: { export: true, delete: false, add: false, select: { enable: true } },
 *   style: {}
 * }); */

export const ShoppingCartCRUD = generateCRUD({
  webEntity: E.ShoppingCartEntity,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: true,
    edit: false,
    select: { enable: true }
  }
});

// Mantenedores
export const UsuarioCRUD = generateCRUD({
  webEntity: E.UserEntity,
  title: 'Administracion Usuario',
  allowedActions: {
    export: true,
    delete: false,
    edit: true,
    select: { enable: false, single: true }
  },
  columnComponent: {
    begin: true,
    column: {
      cell: (user) => {
        return (
          <div>
            <ConnectedNewAddressForm user={user} requestFrom={'Maintainer'} />
          </div>
        );
      },
      allowOverflow: true,
      button: true
    }
  }
});

export const UserDeliveryAddressDependentCRUD = generateCRUD({
  webEntity: E.UserDeliveryAddressEntity,
  dummy: true,
  title: 'Direcciones de despacho',
  additionalTableActions: ({ selected }) => (
    <SetDefaultDeliveryAddressBtn entity={E.UserDeliveryAddressEntity} selected={selected} />
  ),
  allowedActions: {
    export: false,
    add: false,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});

export const TipoUsuarioCRUD = generateCRUD({
  webEntity: E.UserTypeEntity,
  title: 'Administracion Tipo Usuario',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const ItemCRUD = generateCRUD({
  webEntity: E.ItemEntity,
  title: 'Administrar items',
  allowedActions: {
    export: false,
    add: true,
    delete: false,
    edit: true,
    select: { enable: false }
  }
});

export const CountryCRUD = generateCRUD({
  webEntity: E.CountryEntity,
  title: 'Administrar paises de origen',
  allowedActions: {
    export: false,
    add: true,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const BrandCRUD = generateCRUD({
  webEntity: E.BrandEntity,
  title: 'Administrar marcas',
  allowedActions: {
    export: false,
    add: true,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const ElementCRUD = generateCRUD({
  webEntity: E.ElementEntity,
  title: 'Administrar Cortes',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  },
  columnComponent: {
    begin: false,
    column: {
      cell: (element) => {
        return (
          <div>
            <ManageImage {...{ entity: E.ElementEntity, element }} />
          </div>
        );
      },
      allowOverflow: true,
      button: true,
      width: '10em',
      name: 'Imagen'
    }
  }
});

// Control panel - Admin

export const PendingPaymentCartAdminCRUD = generateCRUD({
  webEntity: E.PendingPaymentCartAdminEntity,
  title: 'Pedidos pendientes de pago',
  serverSidePagination: true,
  columnComponent: {
    begin: true,
    column: {
      cell: (shoppingCart) => <PendingPaymentCartModalActions shoppingCartId={shoppingCart._id} />,
      allowOverflow: true,
      button: true
    }
  },
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  }
});

export const PendingPaymentCartAdminDependentCRUD = generateCRUD({
  webEntity: E.PendingPaymentCartAdminDependentEntity,
  dummy: true,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const SkuCRUD = generateCRUD({
  webEntity: E.SkuEntity,
  title: 'Sku',
  allowedActions: {
    export: true,
    add: true,
    delete: true,
    edit: true,
    select: { enable: false }
  }
});

export const StockCRUD = generateCRUD({
  webEntity: E.StockEntity,
  title: 'Stock por publicar',
  sharedFilterName: 'filterStock',
  query: {
    stateName: null
  },
  additionalTableActions: ({ selected }) => {
    const stockIds = selected.map((stock) => stock._id);
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <PublishStockButton stockIds={stockIds} />
        <MassiveStockOriginEditionButton stockIds={stockIds} />
      </div>
    );
  },
  allowedActions: {
    export: true,
    add: false,
    delete: true,
    edit: true,
    select: { enable: true, pageOnly: true }
  }
});

export const StockToLotCRUD = generateCRUD({
  webEntity: E.StockToLotEntity,
  title: 'Stock a Lotear',
  sharedFilterName: 'filterStock',
  additionalTableActions: ({ selected }) => {
    const itemIds = selected.map((item) => item._id);
    return (
      <div>
        <LotearItemsButton itemIds={itemIds} />
      </div>
    );
  },
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: true, pageOnly: true }
  }
});

export const LotActiveCRUD = generateCRUD({
  webEntity: E.LotEntity,
  title: 'Lotes Activos',
  query: { state: StateValues.Activo },
  additionalTableActions: ({ selected }) => {
    const lotIds = selected.map((lot) => lot._id);
    return (
      <div>
        <UnlotButton lotIds={lotIds} />
      </div>
    );
  },
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: true,
    select: { enable: true, single: true }
  }
});

export const LotActiveDependentCRUD = generateCRUD({
  webEntity: E.LotActiveDependentEntity,
  title: 'Detalle',
  dummy: true,
  /*
  additionalTableActions: ({ selected }) => (
    <div>
      <RemoveLotDetailFromLotAdmin lotDetails={selected} />
    </div>
  ),*/
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const CartSearchCRUD = generateCRUD({
  webEntity: E.CartSearchEntity,
  dummy: true,
  title: 'Carros',
  columnComponent: {
    begin: true,
    column: {
      cell: (shoppingCart) => <CartSearchActions shoppingCart={shoppingCart} />,
      allowOverflow: true,
      button: true
    }
  },
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  }
});

export const CartSearchDependentCRUD = generateCRUD({
  webEntity: E.CartSearchDependentEntity,
  dummy: true,
  title: 'Detalle',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const LotForAuctionCRUD = generateCRUD({
  webEntity: E.LotEntity,
  title: 'Lotes en remate',
  query: { state: StateValues.Activo, isForAuction: true },
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: true,
    select: { enable: false, single: true }
  }
});

export const OutOfDateLotForAuctionCRUD = generateCRUD({
  webEntity: E.LotEntity,
  title: 'Lotes de subastas fuera de fecha',
  query: { state: StateValues.Activo, isForAuction: true, outOfAuctionDate: true },
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: true,
    select: { enable: false, single: true }
  }
});

export const SoldCartAdminCRUD = generateCRUD({
  webEntity: E.SoldCartAdminEntity,
  title: 'Ventas Realizadas',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  },
  serverSidePagination: true,
  columnComponent: {
    begin: true,
    column: {
      cell: (shoppingCart) => <SoldCartModalActions shoppingCartId={shoppingCart._id} />,
      allowOverflow: true,
      button: true
    }
  }
});

export const SoldCartAdminDependentCRUD = generateCRUD({
  webEntity: E.SoldCartAdminDependentEntity,
  dummy: true,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const ReturnedSalesCRUD = generateCRUD({
  webEntity: E.ReturnedCartAdminEntity,
  title: 'Devoluciones',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  },
  serverSidePagination: true
});

export const ReturnedCartAdminDependentCRUD = generateCRUD({
  webEntity: E.ReturnedCartAdminDependentEntity,
  dummy: true,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const DiscardedStockCRUD = generateCRUD({
  webEntity: E.SoldCartAdminEntity,
  title: 'Stock Descartado',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  },
  serverSidePagination: true
});

export const PriceListBolsaCRUD = generateCRUD({
  webEntity: E.PriceListBolsaEntity,
  title: 'Bolsa de la carne',
  showAllRows: true,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const UserCartPerUserPageCRUD = generateCRUD({
  webEntity: E.UserCartPerUserPageAdminEntity,
  title: 'Usuario',
  allowedActions: {
    export: false,
    delete: false,
    edit: false,
    add: false,
    select: { enable: false, single: true }
  }
});

export const SalesOfAUserCartPerUserPageDependentCRUD = generateCRUD({
  webEntity: E.SalesOfAUserCartPerUserPageAdminDependentEntity,
  title: '',
  dummy: true,
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  }
});

export const CartSalesOfAUserCartPerUserPageDependentCRUD = generateCRUD({
  webEntity: E.CartSalesOfAUserCartPerUserPageAdminDependentEntity,
  dummy: true,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const BarcodeSearchCRUD = generateCRUD({
  webEntity: E.BarcodeSearchEntity,
  dummy: true,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

// Profile

export const PendingPaymentCartCRUD = generateCRUD({
  webEntity: E.PendingPaymentCartProfileEntity,
  title: 'Pedidos pendientes de pago',
  allowedActions: {
    export: true,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  },
  columnComponent: {
    begin: true,
    column: {
      cell: (shoppingCart) => <PurchaseDataPDFButton cartId={shoppingCart._id} />,
      allowOverflow: true,
      button: true
    }
  }
});

export const PendingPaymentCartDependentCRUD = generateCRUD({
  webEntity: E.PendingPaymentCartProfileDependentEntity,
  dummy: true,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const SoldCartCRUD = generateCRUD({
  webEntity: E.SoldCartProfileEntity,
  title: 'Compras Realizadas',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false, single: true }
  },
  serverSidePagination: true,
  columnComponent: {
    begin: true,
    column: {
      cell: (shoppingCart) => <PurchaseDataPDFButton cartId={shoppingCart._id} />,
      allowOverflow: true,
      button: true
    }
  }
});

export const SoldCartDependentCRUD = generateCRUD({
  webEntity: E.SoldCartProfileDependentEntity,
  dummy: true,
  title: '',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});

export const GeneralSaleRuleCRUD = generateCRUD({
  webEntity: E.GeneralSaleRuleEntity,
  title: 'Reglas Generales de Venta',
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: true,
    select: { enable: false }
  }
});

export const DiscountRuleCRUD = generateCRUD({
  webEntity: E.DiscountRuleEntity,
  title: 'Condiciones de venta sobre Precio Unitario',
  allowedActions: {
    export: false,
    add: true,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});

export const UserDeliveryAddressProfileCRUD = generateCRUD({
  webEntity: E.UserDeliveryAddressProfileEntity,
  title: 'Direcciones de despacho',
  additionalTableActions: ({ selected }) => (
    <SetDefaultDeliveryAddressBtn entity={E.UserDeliveryAddressProfileEntity} selected={selected} />
  ),
  allowedActions: { export: false, add: true, delete: true, edit: true, select: { enable: true } }
});

interface NumberInputProps {
  value: any;
  fieldName: string;
  conditions?: (value: number) => boolean;
  style: any;
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  fieldName,
  conditions = () => true,
  style
}) => {
  const [inputValue, setInputValue] = useState(value[fieldName]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (/^[0-9]\d*$/.test(event.target.value)) {
      if (conditions(Number.parseInt(event.target.value))) {
        setInputValue(event.target.value);
        value[fieldName] = Number.parseInt(event.target.value);
      }
    }
  };

  return <input type='number' value={inputValue} onChange={handleInputChange} style={style} />;
};

const editEntityAction = localEditEntityAction(E.LotItemEntity.name);
export default connect(null, { onChange: editEntityAction })(NumberInput);

/* export const ItemGallery = generateCRUD({
 *   webEntity: E.LotItemEntity,
 *   title: 'Lista de Productos',
 *   query: { areForAuction: false },
 *   columnComponent: {
 *     begin: true,
 *     column: {
 *       cell: (item) => {
 *         const initialPackagingLotCurrentQuantity = item.packagingLotCurrentQuantity;
 *         return (
 *           <div>
 *             <div style={{ display: 'flex', marginRight: '0.5em' }}>
 *               <AddItemToShoppingCartButton item={item} />
 *               <NumberInput
 *                 value={item}
 *                 fieldName={'packagingLotCurrentQuantity'}
 *                 style={{ width: '4em' }}
 *                 conditions={(value: number) =>
 *                   value <= initialPackagingLotCurrentQuantity && value > 0 ? true : false
 *                 }
 *               />
 *             </div>
 *           </div>
 *         );
 *       },
 *       allowOverflow: true,
 *       button: true,
 *       width: '10em',
 *       name: 'Cantidad a Comprar'
 *     }
 *   },
 *   allowedActions: {
 *     export: false,
 *     add: false,
 *     delete: false,
 *     edit: false,
 *     select: { enable: false }
 *   }
 * });*/

/* DEPRECATED
export const ItemGallery = generateCRUD({
  webEntity: E.ItemStockEntity,
  title: 'Lista de Productos',
  columnComponent: {
    begin: true,
    column: {
      cell: (item) => {
        const stockQuantity = Number.parseInt(item.stockQuantity || '0');
        return (
          <div>
            <div style={{ display: 'flex', marginRight: '0.5em' }}>
              <AddItemToSaleOrderButton item={item} />
              <NumberInput
                value={item}
                fieldName={'stockQuantity'}
                style={{ width: '4em' }}
                conditions={(value: number) => (value <= stockQuantity && value > 0 ? true : false)}
              />
            </div>
          </div>
        );
      },
      allowOverflow: true,
      button: true,
      width: '10em',
      name: 'Cantidad a Comprar'
    }
  },
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});*/

export const SaleOrderItems = generateCRUD({
  webEntity: E.SaleOrderDetailEntity,
  title: 'Productos en tu carro',
  query: {
    saleOrderStateName: StateValues.Activo
  },
  viewInputFilter: false,
  allowedActions: {
    export: false,
    add: false,
    delete: true,
    edit: true,
    select: { enable: true }
  }
});

export const ShoppingCartItems = generateCRUD({
  webEntity: E.ShoppingCartItemsEntity,
  title: 'Productos en tu carro',
  viewInputFilter: false,
  additionalTableActions: ({ selected }) => {
    const itemIds = selected.map((item) => item._id);
    return (
      <div>
        <RemoveLotsFromCartButton itemIds={itemIds} />
      </div>
    );
  },
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: true }
  }
});

export const ShoppingCartItemsDetail = generateCRUD({
  webEntity: E.ShoppingCartEntity,
  title: '',
  viewInputFilter: false,
  dummy: true,
  additionalTableActions: ({ selected }) => {
    const cartDetailIds = selected.map((item) => item._id);
    return (
      <div>
        <RemoveLotDetailsFromCartButton cartDetailIds={cartDetailIds} />
      </div>
    );
  },
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: {
      enable: true,
      rowDisabledCriteria: (item) => item.isForAuction
    }
  }
});

export const StockMovementAuditCRUD = generateCRUD({
  webEntity: E.StockMovementAuditEntity,
  title: 'Registro de auditoría',
  serverSidePagination: true,
  viewInputFilter: false,
  allowedActions: {
    export: false,
    add: false,
    delete: false,
    edit: false,
    select: { enable: false }
  }
});
