import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { SimpleObject } from '../types';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { EntitiesState } from '../reducers/types';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { formatStringNumber, dateFormatFn } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';

const PDFpriceList: FunctionComponent<{ entities: EntitiesState }> = ({ entities }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true);
  const [priceListEstandar, setPriceListEstandar] = useState<SimpleObject[]>([]);
  const [priceListBolsa, setPriceListBolsa] = useState<SimpleObject[]>([]);
  const [chargePerMajorPriceList, setChargePerMajorPriceList] = useState<number>(0);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);

  useEffect(() => {
    const getGeneralSaleRules = async () => {
      const res = await getUncatchEndpointData({ endpoint: 'rules/generalSale' });
      const saleRule = res.find(
        (rule: SimpleObject) => rule.ruleName === 'discount_per_major_price_list'
      );
      setChargePerMajorPriceList(Number(saleRule.ruleValue));
    };
    getGeneralSaleRules();
  }, []);

  useEffect(() => {
    setDisabledBtn(false);

    if (entities.priceListEstandar.list.length > 0 && entities.priceListBolsa.list.length > 0) {
      const pListEstandar = entities.priceListEstandar.list.map(
        ({ _id, ...rest }: SimpleObject) => {
          const filteredKeys = Object.keys(rest).filter((key) => !key.endsWith('_4'));

          const filteredObject: SimpleObject = {};
          filteredKeys.forEach((key) => {
            filteredObject[key] = rest[key];
          });

          return {
            ...filteredObject
          };
        }
      );
      const headerColumnsEstandar = Object.keys(pListEstandar[0])
        .filter((key) => key !== 'itemName')
        .reduce(
          (acc, key) => {
            acc[key] = key;
            return acc;
          },
          { itemName: 'Corte' } as SimpleObject
        );

      const pListBolsa = entities.priceListBolsa.list.map(({ _id, ...rest }) => {
        return {
          ...rest
        };
      });
      const headerColumnsBolsa: SimpleObject = {
        brandName: 'Marca',
        itemName: 'Corte',
        stockQuantity: 'Q',
        priceWithDiscount: '$VTA S/IVA',
        elaborationDate: 'F. Elab',
        expirationDate: 'F. Venc'
      };
      setPriceListEstandar([...[headerColumnsEstandar], ...pListEstandar]);
      setPriceListBolsa([...[headerColumnsBolsa], ...pListBolsa]);
    }
  }, [entities.priceListEstandar.list, entities.priceListBolsa.list, forceRefresh]);

  const onClick = async () => {
    try {
      setLoading(true);
      await generatePDF(priceListEstandar, priceListBolsa, chargePerMajorPriceList);
      setForceRefresh(!forceRefresh);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Button onClick={() => onClick()} loading={loading} color='red' disabled={disabledBtn}>
      <Icon name='file pdf' />
      Exportar Lista de precios
    </Button>
  );
};

export default connect(({ entities }: AppState) => ({ entities }))(PDFpriceList);

const transformColor = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return rgb(r / 255, g / 255, b / 255);
};

const generatePDF = async (
  priceListEstandar: SimpleObject[],
  priceListBolsa: SimpleObject[],
  chargePerMajorPriceList: number
) => {
  const fontSizeForAllCells = 10; //cellWidth / maxWidth;
  const cellHeight = 20;
  const marginWidth = 20;
  const marginHeight = 50;

  const maxWidths: Record<string, number> = {};
  priceListEstandar.forEach((row) => {
    Object.keys(row).forEach((key) => {
      const value = row[key] ? row[key].toString() : null;
      if (value) {
        const width = value.length * fontSizeForAllCells;
        maxWidths[key] = Math.max(maxWidths[key] || 0, width);
      }
    });
  });

  const leftTableWidth = Object.keys(maxWidths).reduce(
    (acc, currentkey) => acc + maxWidths[currentkey],
    0
  );
  priceListBolsa.forEach((row, idx) => {
    if (idx !== 0) {
      row.priceWithDiscount = `$${formatStringNumber(row.priceWithDiscount)}`;
      row.expirationDate = dateFormatFn(row.expirationDate, 'DD/MM/YYYY', true);
      row.elaborationDate = dateFormatFn(row.elaborationDate, 'DD/MM/YYYY', true);
    }
  });

  const maxWidthsBolsa: Record<string, number> = {};
  priceListBolsa.forEach((row: SimpleObject) => {
    Object.keys(priceListBolsa[0]).forEach((key) => {
      const value = row[key].toString();
      const width = value.length * fontSizeForAllCells;
      maxWidthsBolsa[key] = Math.max(maxWidthsBolsa[key] || 0, width);
    });
  });

  const rightTableWidth = Object.keys(maxWidthsBolsa).reduce(
    (acc, currentkey) => acc + maxWidthsBolsa[currentkey],
    0
  );

  const pdfDoc = await PDFDocument.create();

  const width = marginWidth + leftTableWidth + marginWidth + rightTableWidth + marginWidth;
  const largerNumberOfRows =
    priceListEstandar.length > priceListBolsa.length
      ? priceListEstandar.length
      : priceListBolsa.length;
  const height = 15 + marginHeight * 3 + cellHeight * largerNumberOfRows;

  const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const page = pdfDoc.addPage([width, height]);

  const titleText = 'The Trader';
  const fontSize = 20;

  const titleX = (width - titleText.length * fontSize * 0.6) / 2;
  const titleY = height - marginHeight;

  page.drawText(titleText, {
    x: titleX,
    y: titleY,
    size: fontSize,
    color: rgb(0, 0, 0)
  });

  const subtitleText = `${dateFormatFn(new Date().toISOString(), 'DD/MM/YYYY HH:mm:ss', false)}`;
  const subfontSize = 14;

  const subtitleX = (width - subtitleText.length * subfontSize * 0.6) / 2;
  const subtitleY = titleY - 15;

  page.drawText(subtitleText, {
    x: subtitleX,
    y: subtitleY,
    size: subfontSize,
    color: rgb(0, 0, 0)
  });

  // *********** Lista de precios ************* //

  const tableHeight = titleY - 2 * marginWidth;

  page.drawText('LISTA DE PRECIOS', {
    x: marginWidth,
    y: subtitleY - marginHeight + 10,
    size: 10,
    color: transformColor('#000000'),
    font: HelveticaBold
  });
  page.drawText(
    `*** Descuento de $${formatStringNumber(
      chargePerMajorPriceList.toString()
    )} al llevar 4 cajas o más del mismo corte ***`,
    {
      x: marginWidth,
      y: subtitleY - marginHeight - 15,
      size: 12,
      color: transformColor('#000000'),
      font: HelveticaBold
    }
  );

  const numRows = Math.floor(tableHeight / cellHeight);

  const leftTableY = subtitleY - marginHeight - 20;

  priceListEstandar.slice(0, numRows).forEach((row: SimpleObject, index: number) => {
    let x = marginWidth;
    Object.keys(row).forEach((key) => {
      const y = leftTableY - (index + 1) * cellHeight;

      const color = transformColor('#FFFFFF');

      page.drawRectangle({
        x,
        y,
        width: maxWidths[key],
        height: cellHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
        color
      });

      const value =
        row[key] === '0' || row[key] === 0
          ? ''
          : typeof row[key] === 'number'
          ? `$${formatStringNumber(row[key])}`
          : row[key];

      let textColor = transformColor('#000000');

      page.drawText(value ? value : '', {
        x: x + 5,
        y: y + 5,
        size: fontSizeForAllCells,
        color: textColor,
        font: HelveticaBold
      });
      x += maxWidths[key];
    });
  });

  // ******** Lista de precios Bolsa ********* //

  page.drawText('BOLSA DE LA CARNE', {
    x: marginWidth + leftTableWidth + marginWidth,
    y: subtitleY - marginHeight,
    size: 10,
    color: transformColor('#000000'),
    font: HelveticaBold
  });

  const rightTableY = subtitleY - marginHeight - 20;

  priceListBolsa.forEach((row: SimpleObject, index: number) => {
    let x = marginWidth + leftTableWidth + marginWidth;
    Object.keys(priceListBolsa[0]).forEach((key) => {
      const y = rightTableY - (index + 1) * cellHeight;

      const color = transformColor('#FFFFFF');

      page.drawRectangle({
        x,
        y,
        width: maxWidthsBolsa[key],
        height: cellHeight,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
        color
      });

      let textColor = transformColor('#000000');

      page.drawText(row[key].toString(), {
        x: x + 5,
        y: y + 5,
        size: fontSizeForAllCells,
        color: textColor,
        font: HelveticaBold
      });
      x += maxWidthsBolsa[key];
    });
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = 'lista_precios.pdf';
  a.click();
  URL.revokeObjectURL(url);
};
