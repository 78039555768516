import { LotItem } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const LotItemEntity: WebEntity<LotItem> = {
  name: 'lotItem',
  endpoint: 'trades/lotItems',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Producto',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Empaque',
      selector: 'packagingName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Actual',
      selector: 'packagingLotCurrentQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Inicial',
      selector: 'packagingLotInitialQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad de Medida',
      selector: 'unitOfMeasureName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Kilos Actuales',
      selector: 'lotCurrentQuantity',
      format: ({ lotCurrentQuantity }) =>
        `${formatStringNumber(lotCurrentQuantity.toString(), true, 0, 2)} Kg`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Kilos Iniciales',
      selector: 'lotInitialQuantity',
      format: ({ lotInitialQuantity }) =>
        `${formatStringNumber(lotInitialQuantity.toString(), true, 0, 2)} Kg`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio (Kg)',
      selector: 'lotPrice',
      format: ({ lotPrice }) => `$${formatStringNumber(lotPrice.toString())}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio Total',
      selector: 'lotPrice',
      format: ({ lotCurrentQuantity, lotPrice }) =>
        `$${formatStringNumber((lotCurrentQuantity * lotPrice).toString())}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Elaboración',
      selector: 'productionDate',
      format: ({ productionDate }) => dateFormatFn(productionDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      format: ({ expirationDate }) => dateFormatFn(expirationDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Creacion Lote',
      selector: 'dateCreated',
      format: ({ dateCreated }) => dateFormatFn(dateCreated, 'DD/MM/YYYY', true),
      omit: true
    }
  ],

  fields: [],
  editableFields: []
};

export default LotItemEntity;
