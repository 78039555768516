import React, { FunctionComponent } from 'react';
import { SkuCRUD } from '../components/GeneratedComponents';

const SkuAdminPage: FunctionComponent<{}> = ({}) => {
  return (
    <>
      <SkuCRUD />
    </>
  );
};

export default SkuAdminPage;
