import React, { Fragment, FunctionComponent, useState } from 'react';
import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button } from 'semantic-ui-react';
import { WebEntity } from '../../entities/types';
import { capitalize } from '../../utils/utils';
import { GenericMultiInputForm } from './GenericMultiInputForm';
import GenericIcons from './GenericIcons';

const ModalButton: FunctionComponent<{
  buttonName: string;
  endpoint: string;
  toggle: () => void;
}> = ({ buttonName, endpoint, toggle }) => (
  <button id={endpoint} color='green' onClick={toggle} className='btn-ingreso-manual'>
    <GenericIcons icon='add' imageStyle={{ margin: 0 }} />
    {buttonName}
  </button>
);

const renderModalButton = (
  alternativeButton: ((toggle: () => void) => JSX.Element) | undefined,
  toggle: () => void,
  name: string,
  endpoint: string
) =>
  alternativeButton ? (
    alternativeButton(toggle)
  ) : (
    <ModalButton toggle={toggle} buttonName={name} endpoint={endpoint} />
  );

const voidFunction = () => {};

type GenericModalMultiInputFormProps<T> = {
  webEntity: WebEntity<T>;
  errorMsg: string;
  isAuthenticated: boolean;
  isAdmin: boolean;
  actionFn: (formFields: any) => any;
  alternativeButton?: (toggle: () => void) => JSX.Element;
  title?: string;
  centered?: boolean;
  closeOnClickOut?: boolean;
  actionName?: string;
  buttonName?: string;
};

const GenericModalMultiInputForm = <T,>({
  webEntity,
  errorMsg = '',
  isAuthenticated,
  isAdmin,
  actionFn,
  alternativeButton,
  title = '',
  centered = false,
  closeOnClickOut = false,
  actionName = '',
  buttonName = ''
}: GenericModalMultiInputFormProps<T>) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const onSubmit = async (e: Event, formFields: T) => {
    e.preventDefault();

    // Add Entity
    const res = await actionFn(formFields);
    return res;
  };

  const { name, fields, endpoint } = webEntity;

  return (
    <>
      {isAuthenticated ? (
        renderModalButton(alternativeButton, toggleModal, buttonName || `Ingreso Manual`, endpoint)
      ) : (
        <h4 className='mb-3 ml4'>{`Identificate para administrar ${name}`}</h4>
      )}
      <Modal
        {...{
          isOpen: modal,
          autoFocus: false,
          centered,
          toggle: closeOnClickOut ? toggleModal : voidFunction,
          size: 'xl'
        }}>
        <ModalHeader toggle={toggleModal}>{title || buttonName || `Agregar ${name}`}</ModalHeader>
        <ModalBody>
          {errorMsg && <Alert color='danger'>{errorMsg}</Alert>}
          <GenericMultiInputForm
            {...{
              fields: fields,
              onSubmit,
              toggleModal,
              actionName,
              isAdmin,
              nameEndPoint: endpoint
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default GenericModalMultiInputForm;
