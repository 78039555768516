import { fieldSelector } from './utils';
import * as XLSX from 'xlsx';

const DEFAULT_CSV_FILENAME = 'reporte';

export const convertArrayOfObjectsToCSV = (
  array: any[],
  selectors: any[],
  columnNames: string[],
  columnDelimiter = ';'
) => {
  let result: any;

  const lineDelimiter = '\n';

  result = '';
  result += columnNames.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    selectors.forEach((selector: any) => {
      if (ctr > 0) result += columnDelimiter;
      result += fieldSelector(selector)(item);
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const convertArrayOfObjectToFieldsMatrix = (
  array: any[],
  selectors: any[],
  columnNames: string[]
) => {
  const result = [];

  result.push(columnNames);
  array.forEach((item) => result.push(selectors.map((selector) => fieldSelector(selector)(item))));

  return result;
};

export const downloadCSV = (fileData: Blob, fileName?: string) => {
  const currentDate = new Date().toISOString().split('T')[0];
  downloadFile(
    new Blob(['\ufeff', fileData]),
    `${fileName ? fileName : DEFAULT_CSV_FILENAME}_${currentDate}.csv`
  );
};

export const downloadXLSX = async (fileData: Blob, nameXLSX?: String) => {
  const csvText = await fileData.text();
  const filas: string[] = csvText.split('\n');
  const data: string[][] = filas.map((fila) => fila.split(';'));
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data, { dateNF: 'dd/mm/yyyy', skipHeader: true });
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
  const currentDate = new Date().toISOString().split('T')[0];
  const fileName = nameXLSX ? nameXLSX : `${DEFAULT_CSV_FILENAME}_${currentDate}`;
  XLSX.writeFile(workbook, `${fileName}.xlsx`, { bookType: 'xlsx', type: 'array' });
};

export const downloadBase64As = async ({
  base64,
  asFile
}: {
  base64: string;
  asFile: 'png' | 'pdf';
}) => {
  const blob = await (
    await fetch(`data:${asFile === 'pdf' ? 'application/pdf' : 'image/png'};base64,${base64}`)
  ).blob();

  downloadFile(blob, `${Date.now()}`);
};

const downloadFile = (fileData: Blob, fileName: string) => {
  const link = document.createElement('a');

  link.setAttribute('href', window.URL.createObjectURL(fileData));
  link.setAttribute('download', fileName);
  link.click();
};
