import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { AppLink } from './types';
import GenericDropdownMenu from './generics/GenericDropdownMenu/GenericDropdownMenu';

type Props = {
  auth: AuthState;
  sections: {
    adminLinks: AppLink[];
    operadorLinks: AppLink[];
  };
};

interface AppNavbarType {
  id: number;
  isOpened: boolean;
  label: string;
  href?: string;
  icon?: string;
  children?: AppNavbarType[] | undefined;
}

interface adjustTreeType {
  label: string;
  href?: string;
  icon?: string;
  children?: Array<adjustTreeType>;
}

const adjustChild = (children: adjustTreeType, idxParent: number, idxChild: number) => {
  const accChildrens: AppNavbarType[] = [];
  children.children?.forEach((child, idx) => {
    accChildrens.push(adjustChild(child, idxChild, idx));
  });
  const newChildren = {
    id: 1 + idxChild + 100 * (idxParent + 1),
    isOpened: false,
    label: children.label,
    href: children.href,
    icon: children.icon,
    children: accChildrens.length > 0 ? accChildrens : undefined
  };
  return newChildren;
};

const adjustTree = (simpleTree: Array<adjustTreeType>): AppNavbarType[] => {
  const newTree = [] as AppNavbarType[];
  simpleTree.forEach((item, index) => {
    const children: AppNavbarType[] = [];

    item.children?.forEach((child, idx) => {
      children.push(adjustChild(child, index, idx));
    });

    newTree.push({
      id: index + 1,
      isOpened: false,
      label: item.label,
      href: item.href,
      icon: item.icon,
      children: children.length > 0 ? children : undefined
    });
  });

  return newTree;
};

export const protoAdminTree = [
  {
    label: 'Home',
    href: '/control-panel/home',
    icon: 'HouseIcon'
  },
  {
    label: 'Stock y Precios',
    icon: 'PackageIcon',
    children: [
      {
        label: 'Lista de Precios',
        href: '/control-panel/price-list'
      },
      {
        label: 'Administracion Precios',
        href: '/control-panel/sku-admin'
      },
      {
        label: 'Administracion Stock',
        href: '/control-panel/stock-load-admin'
      },
      {
        label: 'Administracion Lote',
        href: '/control-panel/lot-stock-admin'
      }
    ]
  },
  {
    label: 'Ventas',
    icon: 'PackageIcon',
    children: [
      {
        label: 'Completar Ordenes',
        href: '/control-panel/completar-orden-admin'
      },
      {
        label: 'Finalizar Ventas',
        href: '/control-panel/confirmacion-ventas'
      },
      {
        label: 'Ventas Realizadas',
        href: '/control-panel/ventas-realizadas'
      }
    ]
  },
  {
    label: 'Devoluciones y Descartes',
    icon: 'report',
    children: [
      {
        label: 'Devoluciones',
        href: '/control-panel/devoluciones'
      },
      {
        label: 'Descartados',
        href: '/control-panel/descartados'
      }
    ]
  },
  {
    label: 'Mantenedores',
    href: '/control-panel/mantenedores',
    icon: 'GearSixIcon'
  },
  {
    label: 'Administracion de Subastas',
    href: '/control-panel/auction-admin',
    icon: 'count'
  },
  {
    label: 'Ventas Usuarios',
    href: '/control-panel/ventas-usuarios',
    icon: 'count'
  },
  {
    label: 'Parametros Subasta',
    href: '/control-panel/parametros-subasta',
    icon: 'count'
  },
  {
    label: 'Reglas de Venta',
    href: '/control-panel/parametros-venta',
    icon: 'count'
  },
  {
    label: 'Buscador Barcode',
    href: '/control-panel/buscador-barcode',
    icon: 'count'
  },
  {
    label: 'Registro Auditoria Stock',
    href: '/control-panel/registros-auditoría',
    icon: 'count'
  },
  {
    label: 'Generar Clave Autorizacion',
    href: '/control-panel/generacion-clave-autorizacion',
    icon: 'count'
  }
];

export const protoOperadorTree: Array<adjustTreeType> = [
  {
    label: 'Lista de Precios',
    href: '/control-panel/price-list',
    icon: 'count'
  }
];

const AppNavbar: FunctionComponent<Props> = ({ auth, sections }) => {
  const adminTree = adjustTree(protoAdminTree);
  const operadorTree = adjustTree(protoOperadorTree);

  const [tree, setTree] = useState<AppNavbarType[]>([]);

  useEffect(() => {
    if (auth.user) {
      setTree(auth.user.userTypeName === 'ADMIN' ? adminTree : operadorTree);
    }
  }, [auth.user]);

  return <GenericDropdownMenu menu={tree} />;
};

export default connect(({ auth }: AppState) => ({ auth }))(AppNavbar);
