import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { AppState } from '../store';
import {
  LotActiveCRUD,
  StockToLotCRUD,
  LotActiveDependentCRUD
} from '../components/GeneratedComponents';
import AdvancedUnlotPage from './AdvancedUnlot';
import { fetchLotDetail } from '../actions/middleActions';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import * as E from '../entities';

const ConnectedRefreshLot = connect(
  ({ entities: { lot } }: AppState) => ({
    state: lot,
    entityName: E.LotActiveDependentEntity.name
  }),
  {
    fetchFunction: fetchLotDetail
  }
)(RefresherComponent);

const LotStockAdminPage: FunctionComponent = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Tab
        menu={{ color: 'red', pointing: true }}
        panes={[
          {
            menuItem: {
              key: 'stock',
              icon: 'list alternate',
              content: 'Lotear Stock'
            },
            render: () => (
              <Tab.Pane attached={true}>
                <StockToLotCRUD />
              </Tab.Pane>
            )
          },
          {
            menuItem: { key: 'lotAdmin', icon: 'list alternate', content: 'Lotes Activos' },
            render: () => (
              <Tab.Pane attached={true}>
                <ConnectedRefreshLot />
                <LotActiveCRUD />
                <LotActiveDependentCRUD />
              </Tab.Pane>
            )
          },
          {
            menuItem: {
              key: 'lotSearchAdmin',
              icon: 'list alternate',
              content: 'Deshacer lote avanzado'
            },
            render: () => (
              <Tab.Pane attached={true}>
                <AdvancedUnlotPage />
              </Tab.Pane>
            )
          }
        ]}
      />
    </div>
  );
};

export default LotStockAdminPage;
