import { UserType } from '../types';
import { FieldTypes, WebEntity } from './types';

const UserTypeEntity: WebEntity<UserType> = {
  name: 'userType',
  endpoint: 'userTypes',
  referenceColumn: 'userTypeName',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre',
      selector: 'userTypeName',
      sortable: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'userTypeName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default UserTypeEntity;
