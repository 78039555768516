import { DiscountRule } from '../types';
import { FieldTypes, WebEntity } from './types';

const CountryEntity: WebEntity<DiscountRule> = {
  name: 'discountRule',
  endpoint: 'rules/discount',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Regla de Descuento'
  },
  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Condicion',
      selector: 'conditionSign',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Condicion',
      selector: 'quantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad Condicion',
      selector: 'unity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Operador',
      selector: 'operatorSign',
      sortable: true,
      wrap: true
    },
    {
      name: 'Valor a Operar (sobre valor por Kg)',
      selector: 'valueToOperate',
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Condicion',
      selector: 'conditionSign',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'conditionSign',
        select: 'name',
        show: 'name',
        data: [{ name: '<' }, { name: '>' }, { name: '<=' }, { name: '>=' }]
      }
    },
    {
      name: 'Cantidad Condicion',
      selector: 'quantity',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Condicion',
      selector: 'unity',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'unity',
        select: 'name',
        show: 'name',
        data: [{ name: 'cajas' }, { name: '% de lote' }]
      }
    },
    {
      name: 'Operacion',
      selector: 'operatorSign',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'operatorSign',
        select: 'name',
        show: 'name',
        data: [{ name: '+' }, { name: '-' }, { name: '*' }]
      }
    },
    {
      name: 'Valor a Operar  (sobre valor por Kg)',
      selector: 'valueToOperate',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Condicion',
      selector: 'conditionSign',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'conditionSign',
        select: 'name',
        show: 'name',
        data: [{ name: '<' }, { name: '>' }, { name: '<=' }, { name: '>=' }]
      }
    },
    {
      name: 'Cantidad Condicion',
      selector: 'quantity',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Condicion',
      selector: 'unity',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'unity',
        select: 'name',
        show: 'name',
        data: [{ name: 'cajas' }, { name: '% de lote' }]
      }
    },
    {
      name: 'Operacion',
      selector: 'operatorSign',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'operatorSign',
        select: 'name',
        show: 'name',
        data: [{ name: '+' }, { name: '-' }, { name: '*' }]
      }
    },
    {
      name: 'Valor a Operar  (sobre valor por Kg)',
      selector: 'valueToOperate',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default CountryEntity;
