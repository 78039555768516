import React, { FunctionComponent, useEffect } from 'react';
import { BasicEntity } from '../../types';
import { SingleEntityState } from '../../reducers/types';
import { WebEntityName } from '../../entities/types';

export const RefresherComponent: FunctionComponent<{
  state: SingleEntityState<BasicEntity>;
  entityName: WebEntityName;
  fetchFunction: any;
}> = ({ state, entityName, fetchFunction }) => {
  useEffect(() => {
    const update = async () => {
      if (state.singleSelected?._id) {
        await fetchFunction(entityName, state, null);
      }
    };

    update();
  }, [state.singleSelected?._id, fetchFunction]);

  return <></>;
};
