import { UserDeliveryAddress } from '../types';
import { FieldTypes, WebEntity } from './types';
import { dateFormatFn, booleanFormatFn } from '../utils/utils';

const UserDeliveryAddressEntity: WebEntity<UserDeliveryAddress> = {
  name: 'userDeliveryAddressDependent',
  endpoint: 'userDeliveryAddresses',
  referenceColumn: 'deliveryAddress',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Id Usuario',
      selector: 'userId',
      omit: true
    },
    {
      name: 'Nombre Usuario',
      selector: 'userUsername',
      sortable: true
    },
    {
      name: 'Direccion Despacho',
      selector: 'deliveryAddress',
      wrap: true,
      sortable: true
    },
    {
      name: 'Por Defecto',
      selector: 'defaultDeliveryAddress',
      format: ({ defaultDeliveryAddress }) => booleanFormatFn(defaultDeliveryAddress),
      wrap: true,
      sortable: true
    },
    {
      name: 'Creado en',
      selector: 'dateCreated',
      format: ({ dateCreated }) => dateFormatFn(dateCreated),
      sortable: true,
      wrap: true
    },
    {
      name: 'Ultima actualizacion',
      selector: 'dateUpdated',
      format: ({ dateUpdated }) => dateFormatFn(dateUpdated),
      sortable: true,
      wrap: true
    }
  ],

  fields: [],

  editableFields: [
    {
      name: 'Direccion Despacho',
      selector: 'deliveryAddress',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default UserDeliveryAddressEntity;
