import { WebEntityName, FieldTypes, CustomButtonNames } from './types';
import UserTypeEntity from './UserTypeEntity';
import UserEntity from './UserEntity';
import CountryEntity from './CountryEntity';
import BrandEntity from './BrandEntity';
import LotEntity from './LotEntity';
import StockEntity from './StockEntity';
import AvailableStockEntity from './AvailableStockEntity';
//import ItemStockEntity from './ItemStockEntity';
import LotItemEntity from './LotItemEntity';
import ItemEntity from './ItemEntity';
import ShoppingCartItemsEntity from './ShoppingCartItemsEntity';
import ShoppingCartEntity from './ShoppingCartEntity';
import SaleOrderEntity from './SaleOrderEntity';
import SaleOrderDetailEntity from './SaleOrderDetailEntity';
import CartEntity from './CartEntity';
import CartDetailEntity from './CartDetailEntity';
import ElementEntity from './ElementEntity';
import BarcodeSearchEntity from './BarcodeSearchEntity';
import DiscountRuleEntity from './DiscountRuleEntity';
import GeneralSaleRuleEntity from './GeneralSaleRuleEntity';
import UserDeliveryAddressEntity from './UserDeliveryAddressEntity';
import PriceListEstandarEntity from './PriceListEstandarEntity';
import PriceListBolsaEntity from './PriceListBolsaEntity';
import SkuEntity from './SkuEntity';
import StockMovementAuditEntity from './StockMovementAuditEntity';

const PendingPaymentCartAdminEntity = {
  ...CartEntity,
  name: 'pendingPaymentCartAdmin' as WebEntityName
};

const PendingPaymentCartAdminDependentEntity = {
  ...CartDetailEntity,
  name: 'pendingPaymentCartAdminDependent' as WebEntityName
};

const SoldCartAdminEntity = {
  ...CartEntity,
  name: 'soldCartAdmin' as WebEntityName,
  endpoint: 'trades/shoppingCart/sold'
};

const SoldCartAdminDependentEntity = {
  ...CartDetailEntity,
  name: 'soldCartAdminDependent' as WebEntityName
};

const ReturnedCartAdminEntity = {
  ...CartEntity,
  name: 'returnedCartAdmin' as WebEntityName,
  endpoint: 'trades/shoppingCart/returned'
};

const ReturnedCartAdminDependentEntity = {
  ...CartDetailEntity,
  name: 'returnedCartAdminDependent' as WebEntityName
};

const SoldCartProfileEntity = {
  ...CartEntity,
  name: 'soldCartProfile' as WebEntityName,
  endpoint: 'trades/shoppingCart/sold'
};

const SoldCartProfileDependentEntity = {
  ...CartDetailEntity,
  name: 'soldCartProfileDependent' as WebEntityName
};

const PendingPaymentCartProfileEntity = {
  ...CartEntity,
  name: 'pendingPaymentCartProfile' as WebEntityName
};

const PendingPaymentCartProfileDependentEntity = {
  ...CartDetailEntity,
  name: 'pendingPaymentCartProfileDependent' as WebEntityName
};

const UserCartPerUserPageAdminEntity = {
  ...UserEntity,
  name: 'userCartPerUserPageAdmin' as WebEntityName
};

const SalesOfAUserCartPerUserPageAdminDependentEntity = {
  ...CartEntity,
  name: 'salesOfAUserCartPerUserPageAdminDependent' as WebEntityName,
  endpoint: 'trades/shoppingCart/sold'
};

const CartSalesOfAUserCartPerUserPageAdminDependentEntity = {
  ...CartDetailEntity,
  name: 'cartSalesOfAUserCartPerUserPageAdminDependent' as WebEntityName
};

const UserDeliveryAddressProfileEntity = {
  ...UserDeliveryAddressEntity,
  name: 'userDeliveryAddressProfile' as WebEntityName,
  endpoint: 'userDeliveryAddresses/profile',
  customButtonNames: {
    addButtonName: 'Agregar Dirección'
  } as CustomButtonNames,
  fields: [
    {
      name: 'Dirección',
      selector: 'deliveryAddress',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

const CartSearchEntity = {
  ...CartEntity,
  name: 'cartSearch' as WebEntityName,
  endpoint: 'carts/cartSearch'
};

const CartSearchDependentEntity = {
  ...CartDetailEntity,
  name: 'cartSearchDependent' as WebEntityName
};

const StockToLotEntity = {
  ...StockEntity,
  name: 'stockToLot' as WebEntityName
};

const AvailableStockDependentEntity = {
  ...StockEntity,
  name: 'availableStockDependent' as WebEntityName,
  endpoint: 'stock/available',
  filterFields: [],
  customButtonNames: { deleteButtonName: 'Despublicar' }
};

const LotActiveDependentEntity = {
  ...StockEntity,
  name: 'lotActiveDependent' as WebEntityName,
  endpoint: 'stock/lotDetail',
  filterFields: []
};

export {
  UserEntity,
  UserTypeEntity,
  CountryEntity,
  BrandEntity,
  LotEntity,
  LotActiveDependentEntity,
  ItemEntity,
  //ItemStockEntity,
  StockToLotEntity,
  StockEntity,
  AvailableStockEntity,
  AvailableStockDependentEntity,
  ShoppingCartEntity,
  SaleOrderEntity,
  SaleOrderDetailEntity,
  ShoppingCartItemsEntity,
  CartEntity,
  CartDetailEntity,
  LotItemEntity,
  ElementEntity,
  PendingPaymentCartAdminEntity,
  PendingPaymentCartAdminDependentEntity,
  SoldCartAdminEntity,
  SoldCartAdminDependentEntity,
  ReturnedCartAdminEntity,
  ReturnedCartAdminDependentEntity,
  SoldCartProfileEntity,
  SoldCartProfileDependentEntity,
  PendingPaymentCartProfileEntity,
  PendingPaymentCartProfileDependentEntity,
  UserCartPerUserPageAdminEntity,
  SalesOfAUserCartPerUserPageAdminDependentEntity,
  CartSalesOfAUserCartPerUserPageAdminDependentEntity,
  BarcodeSearchEntity,
  GeneralSaleRuleEntity,
  DiscountRuleEntity,
  UserDeliveryAddressEntity,
  UserDeliveryAddressProfileEntity,
  CartSearchEntity,
  CartSearchDependentEntity,
  PriceListEstandarEntity,
  PriceListBolsaEntity,
  SkuEntity,
  StockMovementAuditEntity
};
