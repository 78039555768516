import { Country } from '../types';
import { FieldTypes, WebEntity } from './types';

const CountryEntity: WebEntity<Country> = {
  name: 'country',
  endpoint: 'countries',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Pais Origen'
  },
  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre',
      selector: 'countryName',
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'countryName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default CountryEntity;
