import React, { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import { performUncatchedRequest } from '../utils/request';
import { successPopAlert } from '../components/PopAlert';
import { SimpleObject } from '../types';

const ParametersPage: FunctionComponent<{}> = () => {
  const [priceInterval, setPriceInterval] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleInputChange =
    (callback: (input: number) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      if (/^[0-9]\d*$/.test(event.target.value)) {
        callback(Number(event.target.value));
      }
    };

  useEffect(() => {
    const getParameters = async () => {
      setLoading(true);
      const { data } = await performUncatchedRequest({
        endpoint: 'parameters',
        method: 'get'
      });

      const paramSetterObject = {
        auction_price_interval: setPriceInterval
      } as SimpleObject;

      data.forEach((param: { _id: string; paramName: string; paramValue: string }) => {
        if (paramSetterObject[param.paramName]) {
          paramSetterObject[param.paramName](param.paramValue);
        }
      });
      setLoading(false);
    };

    getParameters();
  }, []);

  const handleGuardar = async () => {
    // Enviar datos al backend
    try {
      setLoading(true);
      await performUncatchedRequest({
        endpoint: 'parameters',
        method: 'post',
        query: { priceInterval }
      });

      successPopAlert('Parametros guardados correctamente!');
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Tab
        menu={{ color: 'red', pointing: true }}
        panes={[
          {
            menuItem: {
              key: 'auction-parameters-admin',
              icon: 'cogs',
              content: 'Paramentros de Subasta'
            },
            render: () => (
              <Tab.Pane attached={false}>
                <div>
                  <h1>Parametros de subasta</h1>

                  {loading ? (
                    <b>cargando...</b>
                  ) : (
                    <div style={{ marginTop: '3em', marginLeft: '3em' }}>
                      <table>
                        <tr>
                          <td>
                            <label>Intervalo de Precio (por Kg)</label>
                          </td>
                          <td>
                            <input
                              type='number'
                              value={priceInterval}
                              onChange={handleInputChange(setPriceInterval)}
                            />
                          </td>
                        </tr>
                      </table>

                      <div style={{ marginTop: '3em', marginLeft: '10em' }}>
                        <button onClick={handleGuardar}>Guardar</button>
                      </div>
                    </div>
                  )}
                </div>
              </Tab.Pane>
            )
          }
        ]}
      />
    </div>
  );
};

export default ParametersPage;
