import { Sku } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const SkuEntity: WebEntity<Sku> = {
  name: 'sku',
  endpoint: 'sku',
  referenceColumn: '_id',
  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Item',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio',
      selector: 'skuUnitOfMeasurePrice',
      format: ({ skuUnitOfMeasurePrice }) =>
        `$${formatStringNumber(skuUnitOfMeasurePrice.toString(), false)}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Usuario Ult. Modificación',
      selector: 'userUsername',
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Creación',
      selector: 'dateCreated',
      format: ({ dateCreated }) => dateFormatFn(dateCreated),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Modificación',
      selector: 'dateUpdated',
      format: ({ dateUpdated }) => dateFormatFn(dateUpdated),
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Item',
      selector: 'itemId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'itemId',
        endpoint: 'items',
        select: 'itemId',
        show: 'itemName, brandName, countryName'
      }
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'stockOriginId',
        endpoint: 'stock/stockOrigin',
        select: 'stockOriginId',
        show: 'stockOriginName'
      }
    },
    {
      name: 'Precio',
      selector: 'skuUnitOfMeasurePrice',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Item',
      selector: 'itemName',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Pais',
      selector: 'countryName',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Precio',
      selector: 'skuUnitOfMeasurePrice',
      type: FieldTypes.Text,
      required: true
    }
  ],
  filterFields: [
    {
      name: 'Item',
      selector: 'itemId',
      type: FieldTypes.Reference,
      reference: {
        name: 'itemId',
        endpoint: 'items',
        select: 'itemId',
        show: 'itemName, brandName, countryName'
      }
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginId',
      type: FieldTypes.Reference,
      reference: {
        name: 'stockOriginId',
        endpoint: 'stock/stockOrigin',
        select: 'stockOriginId',
        show: 'stockOriginName'
      }
    }
  ]
};

export default SkuEntity;
