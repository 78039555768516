import { Stock } from '../types';
import { dateFormatFn } from '../utils/utils';
import { WebEntity, FieldTypes } from './types';

const StockEntity: WebEntity<Stock> = {
  name: 'stock',
  endpoint: 'stock',
  referenceColumn: 'barcode',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Barcode',
      selector: 'barcode',
      sortable: true,
      wrap: true
    },
    {
      name: 'Estado',
      selector: 'stateName',
      sortable: true,
      format: ({ stateName }) => (stateName ? stateName : 'Sin Publicar'),
      wrap: true
    },
    {
      name: 'Producto',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Empaque',
      selector: 'packagingName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad de Medida',
      selector: 'unitOfMeasureName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Kgs',
      selector: 'unitOfMeasureQuantity',
      format: ({ unitOfMeasureQuantity }) => `${unitOfMeasureQuantity}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Elaboración',
      selector: 'elaborationDate',
      format: ({ elaborationDate }) => dateFormatFn(elaborationDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      format: ({ expirationDate }) => dateFormatFn(expirationDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Creado en',
      selector: 'dateCreated',
      format: ({ dateCreated }) => dateFormatFn(dateCreated, 'DD/MM/YYYY HH:mm:ss', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Ultima actualizacion',
      selector: 'dateUpdated',
      format: ({ dateUpdated }) => dateFormatFn(dateUpdated, 'DD/MM/YYYY HH:mm:ss', true),
      sortable: true,
      wrap: true
    }
  ],

  fields: [],
  editableFields: [
    {
      name: 'Fecha Elaboración',
      selector: 'elaborationDate',
      type: FieldTypes.Date,
      required: true
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      type: FieldTypes.Date,
      required: true
    }
  ],
  filterFields: [
    {
      name: 'Item',
      selector: 'itemName',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'itemName',
        endpoint: 'elements',
        select: 'elementName',
        show: 'elementName'
      }
    },
    {
      name: 'Marca',
      selector: 'brandName',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'brandName',
        endpoint: 'brands',
        select: 'brandName',
        show: 'brandName'
      }
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'stockOriginName',
        endpoint: 'stock/stockOrigin',
        select: 'stockOriginName',
        show: 'stockOriginName'
      }
    },
    {
      name: 'Pais',
      selector: 'countryName',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'countryName',
        endpoint: 'countries',
        select: 'countryName',
        show: 'countryName'
      }
    },
    {
      name: 'Fecha Elaboración',
      selector: 'elaborationDate',
      type: FieldTypes.Date,
      required: false
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      type: FieldTypes.Date,
      required: false
    },
    {
      name: 'Creado en',
      selector: 'dateCreated',
      type: FieldTypes.Date,
      required: false
    },
    {
      name: 'Ultima actualizacion',
      selector: 'dateUpdated',
      type: FieldTypes.Date,
      required: false
    }
  ]
};

export default StockEntity;

export const STOCK_COLUMNS = {
  barcode: 'Barcode',
  customerItemId: 'Codigo',
  packagingName: 'Empaque',
  unitOfMeasureName: 'Unidad de Medida',
  unitOfMeasureQuantity: 'Cantidad Kgs',
  expirationDate: 'Fecha de Vencimiento'
};
