import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import { filterFromObjectByKeys } from '../utils/utils';
//import GeneratePDF from '../utils/GeneratePDF';
import {
  PendingPaymentCartCRUD,
  PendingPaymentCartDependentCRUD,
  SoldCartCRUD,
  SoldCartDependentCRUD,
  UserDeliveryAddressProfileCRUD
} from '../components/GeneratedComponents';
import GenericObjectTable from '../components/generics/GenericObjectTable';
import { SimpleObject } from '../types';
import { fetchCartDetail } from '../actions/middleActions';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import * as E from '../entities';

const ConnectedRefreshPendingPaymentCart = connect(
  ({ entities: { pendingPaymentCartProfile } }: AppState) => ({
    state: pendingPaymentCartProfile,
    entityName: E.PendingPaymentCartProfileDependentEntity.name
  }),
  {
    fetchFunction: fetchCartDetail
  }
)(RefresherComponent);

const ConnectedRefreshProfileSoldCart = connect(
  ({ entities: { soldCartProfile } }: AppState) => ({
    state: soldCartProfile,
    entityName: E.SoldCartProfileDependentEntity.name
  }),
  {
    fetchFunction: fetchCartDetail
  }
)(RefresherComponent);

interface IProps {
  auth: AuthState;
}

const transferenceData = {
  nombre: 'COMERCIAL AGROLUJUMA LIMITADA',
  rut: '77.856.140-9',
  banco: 'Banco Santander',
  tipoDeCuenta: 'Cuenta Corriente',
  numeroDeCuenta: '166996-6',
  email: 'acavalieri@carnenuestra.cl'
};

const UserHistory: FunctionComponent = () => {
  return (
    <Tab
      menu={{ color: 'red', pointing: true }}
      panes={[
        {
          menuItem: { key: 'pendings', icon: 'shop', content: 'Pendientes de pago' },
          render: () => (
            <Tab.Pane attached={true}>
              <ConnectedRefreshPendingPaymentCart />
              <PendingPaymentCartCRUD />
              <PendingPaymentCartDependentCRUD />
            </Tab.Pane>
          )
        },
        {
          menuItem: {
            key: 'history',
            icon: 'calendar alternate',
            content: 'Historial de compras'
          },
          render: () => (
            <Tab.Pane attached={true}>
              <ConnectedRefreshProfileSoldCart />
              <SoldCartCRUD />
              <SoldCartDependentCRUD />
            </Tab.Pane>
          )
        }
      ]}
    />
  );
};

const UserDeliveryAddress: FunctionComponent = () => {
  return <UserDeliveryAddressProfileCRUD />;
};

const Profile: FunctionComponent<IProps> = ({ auth }) => {
  const filteredUser = filterFromObjectByKeys(auth.user, [
    '_id',
    'userId',
    'userPassword',
    'dateCreated',
    'dateUpdated',
    'userTypeId'
  ]) as SimpleObject;
  filteredUser.userEmail = filteredUser.userEmail || '';

  return (
    <div style={{ marginTop: '2em', marginLeft: '2em' }}>
      <Tab
        menu={{ color: 'red', pointing: true, vertical: true }}
        menuPosition={'right'}
        panes={[
          {
            menuItem: {
              key: 'userProfile',
              icon: 'user',
              content: 'Mi Perfil'
            },
            render: () => (
              <Tab.Pane attached={true}>
                <GenericObjectTable object={filteredUser} />
              </Tab.Pane>
            )
          },
          {
            menuItem: { key: 'userRequests', icon: 'shop', content: 'Mis Pedidos' },
            render: () => (
              <Tab.Pane attached={true}>
                <UserHistory />
              </Tab.Pane>
            )
          },
          {
            menuItem: {
              key: 'userDeliveryAddress',
              icon: 'address book',
              content: 'Mis Direcciones'
            },
            render: () => (
              <Tab.Pane attached={true}>
                <UserDeliveryAddress />
              </Tab.Pane>
            )
          },
          {
            menuItem: {
              key: 'transferenceInfo',
              icon: 'cc visa',
              content: 'Datos de Transferencia'
            },
            render: () => (
              <Tab.Pane attached={true}>
                <GenericObjectTable object={transferenceData} />
              </Tab.Pane>
            )
          }
        ]}
      />
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(Profile);
