import * as Papa from 'papaparse';
import React, { ChangeEvent, FunctionComponent, useRef, useState } from 'react';
import { Input } from 'reactstrap';
import { Button, Icon } from 'semantic-ui-react';
import { errorPopAlert, popAlert, PopAlertType } from './PopAlert';
import GenericHeaderExportButton from '../components/generics/GenericHeaderExportButton';
import { SimpleObject, SimpleStringObject } from '../types';

type FileReaderColumnObject = {
  name: string;
  required?: boolean;
  export?: boolean;
  format?: (field: any) => string | boolean | number | Date;
};
export type FileReaderColumnProps = { [key: string]: string | FileReaderColumnObject };
const isNullOrUndefined = (value: any) => value === null || value === undefined;

type Props = {
  columns?: FileReaderColumnProps;
  callback: (data: Array<{ [key: string]: string }>) => Promise<any>;
  dataInputName?: string; // NOTE: the name to show to the user in case of error
};

const FileReaderCSV: FunctionComponent<Props> = ({ columns = {}, callback, dataInputName }) => {
  const exportColumns = [];

  const columnMap: SimpleStringObject = {};
  const headerTemplateColumnMap: SimpleStringObject = {};
  const requiredColumns = [] as Array<string>;
  const desiredColumns = [] as Array<string>;
  const columnsFormat = {} as SimpleObject;

  for (const key in columns) {
    // NOTE: Columns Format
    if (typeof columns[key] === 'object' && (columns[key] as FileReaderColumnObject).format) {
      columnsFormat[key] = (columns[key] as FileReaderColumnObject).format;
    }

    // NOTE: Classify required and desired columns
    const isRequired =
      typeof columns[key] === 'string' ||
      (typeof columns[key] === 'object' &&
        (columns[key] as FileReaderColumnObject)?.required !== false);

    if (isRequired) {
      requiredColumns.push(key);
    } else {
      desiredColumns.push(key);
    }

    // NOTE: Getting the export columns
    const isExport =
      // IF is explicitly true
      (columns[key] as FileReaderColumnObject)?.export === true ||
      // OR in CASE to be undefined or null, is export only if `isRequired` is also true
      (isNullOrUndefined((columns[key] as FileReaderColumnObject)?.export) && isRequired);

    if (typeof columns[key] === 'string') {
      exportColumns.push(columns[key]);
    } else if (typeof columns[key] === 'object' && isExport) {
      exportColumns.push((columns[key] as any).name);
    }

    // NOTE: map of columns and headers
    if (typeof columns[key] === 'object' && (columns[key] as any).name) {
      columnMap[(columns[key] as FileReaderColumnObject).name] = key;
      headerTemplateColumnMap[key] = (columns[key] as FileReaderColumnObject).name;
    } else {
      columnMap[columns[key] as string] = key;
      headerTemplateColumnMap[key] = columns[key] as string;
    }
  }

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCsvFile(event.target.files![0]);
  };

  const headerTemplate = [...exportColumns].map(
    (header) => (headerTemplateColumnMap as any)[header] || header
  );

  const processedData: Array<any> = []; // Almacenará los datos procesados
  const importCSV = () => {
    try {
      setLoading(true);
      Papa.parse(csvFile!, {
        complete: () => {
          updateData(processedData);
        }, // NOTE: when step is used, we have to manage the data by ourselves
        header: true,
        delimiter: ';',
        step: (row: any) => {
          try {
            // NOTE: Checking the last line that always come with only one column and without data
            if (Object.keys(row.data).length === 1 && !row.data[Object.keys(row.data)[0]]) {
              return;
            }

            // This function apply the 'format' function to the specific field of the row
            if (Object.keys(columnsFormat).length > 0) {
              Object.keys(columnsFormat).forEach((rowDataKey) => {
                row.data[rowDataKey] = columnsFormat[rowDataKey](row.data[rowDataKey]);
              });
            }
            processedData.push(row.data);
          } catch (error: any) {
            // NOTE: Only catch to show the error
            errorPopAlert(
              'Ha ocurrido un error mientras de intentaba cargar el archivo',
              error.message
            );
            setLoading(false);
            // NOTE: Throw error anyway to avoid the file upload
            throw new Error(`'Ha ocurrido un error mientras de intentaba cargar el archivo`);
          }
        },
        transformHeader: (header: string) => {
          return (columnMap as any)[header] || header;
        }
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // NOTE: By using 'step' callback you should manage the data by yourself. otherwhise the 'complete' callback will gives you the 'result: Papa.ParseResult<any>'
  //const updateData = async (result: Papa.ParseResult<any>) => {
  const updateData = async (data: Array<any>) => {
    try {
      setLoading(true);

      if (data.length < 1) {
        throw new Error('El archivo recibido no tiene filas con registros');
      }

      const uniqueFn = (c: string, idx: number, self: Array<string>) => self.indexOf(c) === idx;

      const classifiedRows = data.reduce(
        (acc: any, row: Array<SimpleObject>, idx: any) => {
          const notFoundRequiredColumns: any = requiredColumns.find(
            (c: any) => typeof row[c] !== 'boolean' && !row[c]
          );
          const notFoundDesiredColumns = desiredColumns.find(
            (c: any) => typeof row[c] !== 'boolean' && !row[c]
          );
          !notFoundRequiredColumns && acc.valids.push(row);
          notFoundRequiredColumns && acc.invalids.push(row);
          notFoundDesiredColumns && acc.warnings.push(row);
          notFoundRequiredColumns && acc.notFoundRequiredColumns.push(notFoundRequiredColumns);
          notFoundDesiredColumns && acc.notFoundDesiredColumns.push(notFoundDesiredColumns);
          notFoundRequiredColumns && acc.notFoundRequiredOnRows.push(idx + 2); // +2 beacuse idx start from 0 (then +1) plus the header row

          return acc;
        },
        {
          valids: [],
          invalids: [],
          warnings: [],
          notFoundRequiredColumns: [],
          notFoundDesiredColumns: [],
          notFoundRequiredOnRows: []
        }
      );

      classifiedRows.notFoundRequiredColumns =
        classifiedRows.notFoundRequiredColumns.filter(uniqueFn);
      classifiedRows.notFoundDesiredColumns =
        classifiedRows.notFoundDesiredColumns.filter(uniqueFn);

      const columnNames: { [key: string]: string } = Object.keys(columns).reduce((acc, key) => {
        if (typeof columns[key] === 'object' && (columns[key] as FileReaderColumnObject)) {
          return { ...acc, ...{ [key]: (columns[key] as any).name } };
        }
        return { ...acc, ...{ [key]: columns[key] } };
      }, {});

      if (classifiedRows.invalids.length > 0) {
        const invalidRowText = Object.keys(classifiedRows.invalids[0]).reduce(
          (acc: string[], key) => {
            acc.push(
              `${columnNames[key]}: ${
                classifiedRows.invalids[0][key] ? `"${classifiedRows.invalids[0][key]}"` : `[vacío]`
              }`
            );
            return acc;
          },
          []
        );

        const invalidRowTextString = invalidRowText.join('\n');
        const notFoundRequiredColumns = classifiedRows.notFoundRequiredColumns.map(
          (c: string) => `"${columnNames[c]}"`
        );

        popAlert({
          type: PopAlertType.ERROR,
          title: `${
            dataInputName ? `Asegúrese de que esta cargando un ${dataInputName}.\n` : ''
          }El archivo no posee todas las columnas requeridas, revise e intente nuevamente por favor.`,
          details: `No se encontraron las columnas: ${notFoundRequiredColumns.join(
            ', '
          )} en las filas: ${classifiedRows.notFoundRequiredOnRows}.

Detalle (Primera fila con error):
\n ${invalidRowTextString}`
        });

        setLoading(false);
        return;
      }

      setLoading(false);

      setUploading(true);
      await callback(classifiedRows.valids);
      setUploading(false);

      // Reset values to null
      fileInput.current!.value = '';
      setCsvFile(null);
    } catch (error: any) {
      console.log(error);
      setUploading(false);
      setLoading(false);

      errorPopAlert(
        'Ha ocurrido un error mientras de intentaba cargar el archivo',
        '',
        error.message
      );

      // Reset values to null
      fileInput.current!.value = '';
      setCsvFile(null);
    }
  };

  return (
    <div>
      <h4>Ingresa los datos a partir de un archivo CSV</h4>

      <Input
        type='file'
        name='file'
        innerRef={fileInput}
        placeholder={'Lista de solicitudes'}
        accept='.csv'
        onChange={handleChange}
      />
      <GenericHeaderExportButton headers={headerTemplate} />

      <Button
        disabled={!csvFile}
        color='black'
        onClick={importCSV}
        style={{ display: 'block', marginTop: '1em' }}
      >
        <Icon name='upload' />
        Procesar Archivo
      </Button>

      {loading ? <h3>Leyendo datos...</h3> : uploading ? <h3>Procesando datos...</h3> : null}
    </div>
  );
};

export default FileReaderCSV;
