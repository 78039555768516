import React, { FunctionComponent, useEffect, useState, useRef, useMemo, Fragment } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import {
  createChart,
  ChartOptions,
  LineStyle,
  CreatePriceLineOptions,
  IChartApi
} from 'lightweight-charts';
import { getUncatchEndpointData } from '../utils/request';
import '../css/historicalPriceChart.css';
import { formatStringNumber } from '../utils/utils';

const GenericDropDownList: FunctionComponent<{
  value: string;
  placeholder: string;
  data: any[];
  valueField: 'itemNameConcat';
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
}> = ({ value, placeholder, data, valueField, onChange }) => {
  const options = React.useMemo(
    () =>
      data && data.length > 0
        ? data.map((opt, idx) => ({
            key: `${valueField}_${idx}`,
            value: opt[valueField],
            text: opt[valueField]
          }))
        : [],
    [data]
  );
  const safeValue = !value ? [] : value;

  return (
    <Dropdown
      {...{
        className: 'mb-3 dropdown-historical-sale-price-chart',
        placeholder,
        fluid: true,
        lazyLoad: true,
        search: true,
        selection: true,
        options,
        onChange,
        value: safeValue
      }}
    />
  );
};

type PriceMetricsRowType = {
  time: { year: number; month: number; day: number };
  value: number;
};

const chartOptions = {
  width: 700,
  height: 400,
  layout: {
    textColor: '#d1d4dc',
    background: { type: 'solid', color: 'black' }
  },
  rightPriceScale: {
    scaleMargins: {
      top: 0.3,
      bottom: 0.25
    }
  },
  crosshair: {
    vertLine: {
      width: 4,
      color: 'rgba(224, 227, 235, 0.1)',
      style: 0
    },
    horzLine: {
      visible: false,
      labelVisible: false
    }
  },
  grid: {
    vertLines: {
      color: 'rgba(42, 46, 57, 0)'
    },
    horzLines: {
      color: 'rgba(42, 46, 57, 0)'
    }
  },
  handleScroll: {
    vertTouchDrag: false
  }
} as ChartOptions;

const HistoricalSalePriceChart: FunctionComponent<{}> = () => {
  const [priceMetricsData, setPriceMetricsData] = useState<PriceMetricsRowType[]>([]);
  const [distinctItems, setDistinctItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string>('POSTA NEGRA GUARANI');
  const [selectedPeriodId, setSelectedPeriodId] = useState<number>(0);
  const [chart, setChart] = useState<IChartApi | null>(null);
  const [percentDiff, setPercentDiff] = useState<number>(0);
  const [availablePeriods, setAvailablePeriods] = useState<Array<String[]>>([['', '']]);

  const isMounted = useRef(true);

  useEffect(() => {
    const getItems = async () => {
      try {
        const fetchedAvailablePeriods = await getUncatchEndpointData({
          endpoint: 'historicalPrices/availablePeriods'
        });
        const items = await getUncatchEndpointData({
          endpoint: 'historicalPrices/distinctItemsBrands'
        });
        // NOTE: Avoid state changes when the component is unmouted
        if (!isMounted.current) {
          //return;
        }
        setDistinctItems(items);
        setAvailablePeriods(fetchedAvailablePeriods);
        // Only the first load. Select by default the largest period
        setSelectedPeriodId(fetchedAvailablePeriods.length - 1);
      } catch (error) {
        console.log(error);
      }
    };
    getItems();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getPriceMetrics = async (selectedItem: string) => {
      try {
        setLoading(true);

        if (availablePeriods[selectedPeriodId].every((period) => period === '')) {
          setLoading(false);
          return;
        }

        const fetchedPriceMetricsData = await getUncatchEndpointData({
          endpoint: 'historicalPrices/priceMetrics',
          query: { itemName: selectedItem, period: availablePeriods[selectedPeriodId][0] }
        });

        // NOTE: LOGS TO DEBUG
        /* const repetaed = fetchedPriceMetricsData.reduce((acc: any, curr: any, idx: any) => {
*   const firstIdxFound = fetchedPriceMetricsData.findIndex(
*     (d: any) => d.year === curr.year && d.month === curr.month && d.day === curr.day
*   );
*   if (firstIdxFound !== idx) {
*     acc.push(curr);
*     console.log(`First found: ${firstIdxFound}`);
*     console.log(`Repeated found: ${idx}`);
*   }
*   return acc;
* }, []);

* console.log('DATOS NULOS =======================');
* console.log(
*   fetchedPriceMetricsData.filter(
*     (d: any) => !d.year || !d.month || !d.day || !d.dailySalesPrice
*   )
* );

* console.log('DATOS REPETIDOS =======================');
* console.log(repetaed); */

        // NOTE: Avoid state changes when the component is unmouted
        if (!isMounted.current) {
          //return;
        }

        const processedPriceMetricsData = fetchedPriceMetricsData.reduce(
          (acc: any[], d: any) =>
            d.dailySalesPrice && Number(d.dailySalesPrice) > 0
              ? [
                  ...acc,
                  {
                    time: { year: d.year, month: d.month, day: d.day },
                    value: Number(d.dailySalesPrice)
                  }
                ]
              : acc,
          []
        );

        setPriceMetricsData(processedPriceMetricsData);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getPriceMetrics(selectedItem);

    return () => {
      isMounted.current = false;
    };
  }, [selectedItem, availablePeriods, selectedPeriodId]);

  const onChangeItem = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
    setSelectedItem(data.value as string);

  const handleResize = () => {
    const newWidth = getWidthInPixels();
    chart?.resize(newWidth, 400);
  };

  const getWidthInPixels = () => {
    const chartElement = document.getElementById('historicalSalePriceChart') as HTMLElement;
    return chartElement?.clientWidth || 0;
  };

  useEffect(() => {
    const chartElement = document.getElementById('historicalSalePriceChart') as HTMLElement;
    chartElement.innerHTML = '';

    if (chartElement) {
      const initialWidth = getWidthInPixels();
      const initialHeight = 400;

      chartOptions.width = initialWidth;
      chartOptions.height = initialHeight;

      //const chart = createChart(chartElement, chartOptions);
      const newChart = createChart(chartElement, chartOptions);

      const lineSeries = newChart.addLineSeries({
        //color: 'rgb(0, 120, 255)',
        color: '#F3D914',
        lineWidth: 2,
        crosshairMarkerVisible: false,
        lastValueVisible: false,
        priceLineVisible: false
      });

      lineSeries.setData(priceMetricsData);
      const { minPrice, maxPrice, idxMin, idxMax } = priceMetricsData.reduce(
        (range, metrics, idx) => ({
          minPrice:
            idx === 0
              ? metrics.value
              : metrics.value < range.minPrice
              ? metrics.value
              : range.minPrice,
          maxPrice: metrics.value > range.maxPrice ? metrics.value : range.maxPrice,
          idxMin: idx === 0 ? metrics.value : metrics.value < range.minPrice ? idx : range.idxMin,
          idxMax: metrics.value > range.maxPrice ? idx : range.idxMax
        }),

        { minPrice: 0, maxPrice: 0, idxMin: 0, idxMax: 0 }
      );
      const avgPrice = (minPrice + maxPrice) / 2;

      const lineWidth = 2;
      const minPriceLine = {
        price: minPrice,
        color: '#be1238',
        lineWidth: lineWidth,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: 'minimum price'
      } as CreatePriceLineOptions;
      const avgPriceLine = {
        price: avgPrice,
        color: '#be1238',
        lineWidth: lineWidth,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: 'average price'
      } as CreatePriceLineOptions;
      const maxPriceLine = {
        price: maxPrice,
        color: '#be1238',
        lineWidth: lineWidth,
        lineStyle: LineStyle.Solid,
        axisLabelVisible: true,
        title: 'maximum price'
      } as CreatePriceLineOptions;

      lineSeries.createPriceLine(minPriceLine);
      lineSeries.createPriceLine(avgPriceLine);
      lineSeries.createPriceLine(maxPriceLine);

      newChart.timeScale().fitContent();

      if (priceMetricsData.length > 1) {
        const localPercentDiff = calculatePricePercentageDiff(
          priceMetricsData[priceMetricsData.length - 1].value,
          priceMetricsData[0].value
        );
        setPercentDiff(localPercentDiff);
      } else {
        setPercentDiff(0);
      }

      setChart(newChart);

      return () => {
        newChart?.remove();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [priceMetricsData]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chart]);

  const liPeriods = useMemo(
    () =>
      availablePeriods.map((period, idx) => (
        <li
          onClick={() => {
            setSelectedPeriodId(idx);
          }}
          style={selectedPeriodId === idx ? { color: '#F1BA0A' } : {}}
          key={`available_periods_key_${idx}`}>
          {period[1]}
        </li>
      )),
    [selectedPeriodId, availablePeriods]
  );

  const calculatePricePercentageDiff = (currentPrice: number, previousPrice: number) => {
    const diff = currentPrice - previousPrice;
    return (diff / previousPrice) * 100;
  };

  const formatPricePercentageDiff = (percentage: number) => {
    const formattedPercentage = formatStringNumber(percentage.toString(), true, 0, 2);
    return percentage > 0 ? `+${formattedPercentage}%` : `${formattedPercentage}%`;
  };

  return (
    <Fragment>
      {loading && <div style={{ color: 'white' }}>Cargando....</div>}
      <div className='top-container-info-chart'>
        <div className='historical-price-chart-dropdown'>
          <GenericDropDownList
            {...{
              placeholder: 'Item',
              data: distinctItems,
              valueField: 'itemNameConcat',
              onChange: onChangeItem,
              value: selectedItem
            }}
          />
        </div>
        <div className='price-container'>
          <div className='price-percent-diff'>
            <div className='percent' style={{ color: percentDiff >= 0 ? '#0ecd82' : '#F23645' }}>
              {formatPricePercentageDiff(percentDiff)}
            </div>
            <div className='period'>({availablePeriods[selectedPeriodId][1]})</div>
          </div>

          {priceMetricsData.length > 0 && (
            <h1 className='big-current-price'>
              ${formatStringNumber(priceMetricsData[priceMetricsData.length - 1].value.toString())}
              <span className='currency'>(CLP)</span>
            </h1>
          )}

          <div className='small-initial-price'>
            {priceMetricsData.length > 0 && (
              <>
                Precio inicial en periodo: $
                {formatStringNumber(priceMetricsData[0].value.toString())} (CLP)
              </>
            )}
          </div>
        </div>
      </div>
      <div className='div-ul-inline'>
        <ul className='ul-inline'>{liPeriods}</ul>
      </div>
      <div id='historicalSalePriceChart'></div>
    </Fragment>
  );
};

export default HistoricalSalePriceChart;
