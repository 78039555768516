import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { fetchUserCarts, fetchCartDetail } from '../actions/middleActions';
import {
  UserCartPerUserPageCRUD,
  SalesOfAUserCartPerUserPageDependentCRUD,
  CartSalesOfAUserCartPerUserPageDependentCRUD
} from '../components/GeneratedComponents';
import { AppState } from '../store';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import * as E from '../entities';

const ConnectedRefreshUserCartPerUserPage = connect(
  ({ entities: { userCartPerUserPageAdmin } }: AppState) => ({
    state: userCartPerUserPageAdmin,
    entityName: E.SalesOfAUserCartPerUserPageAdminDependentEntity.name
  }),
  {
    fetchFunction: fetchUserCarts
  }
)(RefresherComponent);

const ConnectedRefreshCartSalesOfAUserCartPerUserPageDependent = connect(
  ({ entities: { salesOfAUserCartPerUserPageAdminDependent } }: AppState) => ({
    state: salesOfAUserCartPerUserPageAdminDependent,
    entityName: E.CartSalesOfAUserCartPerUserPageAdminDependentEntity.name
  }),
  {
    fetchFunction: fetchCartDetail
  }
)(RefresherComponent);

const CartPerUserPage: FunctionComponent = () => {
  return (
    <div>
      <ConnectedRefreshUserCartPerUserPage />
      <ConnectedRefreshCartSalesOfAUserCartPerUserPageDependent />
      <UserCartPerUserPageCRUD />
      <SalesOfAUserCartPerUserPageDependentCRUD />
      <CartSalesOfAUserCartPerUserPageDependentCRUD />
    </div>
  );
};

export default CartPerUserPage;
