import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '../../actions/authActions';
import { pagesName } from '../../pages/AnonimousPage';

interface IProps {
  logout: typeof logout;
  setPageContentName?: React.Dispatch<React.SetStateAction<string>>;
}

const Logout: FunctionComponent<IProps> = ({ logout, setPageContentName }) => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    if (setPageContentName) {
      setPageContentName(pagesName.home);
    } else {
      history.push('/');
    }
  };

  return <a onClick={handleLogout}>Cerrar Sesión</a>;
};

export default connect(null, { logout })(Logout);
