import React, { useState, useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { SignalValues } from '../types';
import GenericIcons from '../components/generics/GenericIcons';
import './css/tradePage.css';

export type ResumeType = {
  [key: string]: {
    barcode: string;
    itemName: string;
    expirationDate: string;
    productPackagingQuantity: number;
    unitOfMeasurePrice: number;
    totalKgs: number;
    appliedDiscountUnitPrice: number;
    discountPerUnit: number;
    deliveryChargePerUnit: number;
    itemId: string;
    cartId: number;
    cartOrderDate: string;
  };
};

const Countdown: React.FC<{ trigger?: any; expirationDate: Date | null }> = ({
  trigger,
  expirationDate
}) => {
  const [countdown, setCountdown] = useState(0); // Inicializar el contador en 10 minutos (10 * 60 segundos)
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const initializeIntervalIfNotExist = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
  };

  const localClearInterval = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    initializeIntervalIfNotExist();

    // Clear interval on unmount
    return () => {
      localClearInterval();
    };
  }, []);

  useEffect(() => {
    if (expirationDate === null) {
      setCountdown(0);
      return;
    }

    const now = new Date();
    const milisecondDiff = expirationDate.getTime() - now.getTime();
    const secondDiff = milisecondDiff / 1000;
    const additionalTime = 2; // NOTE: Give time to the backend to update its data

    setCountdown(Math.round(secondDiff) + additionalTime);
  }, [expirationDate]);

  useEffect(() => {
    // clear interval when countdown is 0
    if (countdown <= 0) {
      localClearInterval();

      if (trigger) {
        trigger();
      }
    }

    // On countdown updates its value, if there is not interval, it is created again
    if (countdown > 0) {
      initializeIntervalIfNotExist();
    }
  }, [countdown]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  if (countdown === 0) {
    return null;
  }

  return (
    <div>
      <h2>{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</h2>
    </div>
  );
};

export const Cart: React.FC<{
  onClick: () => void;
  refreshShoppingCart: () => Promise<void>;
  socket: Socket | null;
}> = ({ onClick, refreshShoppingCart, socket }) => {
  const [cartItems, setCartItems] = useState<number>(0);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  const updateCartData = (cartData: { saleOrderExpirationDate: Date | null; count: number }) => {
    if (!cartData || !cartData.saleOrderExpirationDate) {
      setExpirationDate(null);
      return;
    }

    const shoppingCartExpirationDate = new Date(cartData.saleOrderExpirationDate);
    if (shoppingCartExpirationDate) {
      setExpirationDate(shoppingCartExpirationDate);
      setCartItems(cartData.count);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on(SignalValues.cartDataBroadcast, updateCartData);
    }
  }, [socket]);

  const clear = () => {
    setCartItems(0);
    refreshShoppingCart();
  };

  return (
    <div className={'shopping-cart-container'} onClick={onClick}>
      {cartItems === 0 ? (
        <div>
          <GenericIcons icon={'truckIconWhite'} imageStyle={{ width: '26px', height: '26px' }} />
        </div>
      ) : (
        <div className={'shopping-cart-with-items'}>
          <GenericIcons
            className='truck-icon'
            icon={'truckIconWhite'}
            imageStyle={{ width: '26px', height: '26px' }}
          />
          <span className='shoppingCartCounter'>{cartItems}</span>
        </div>
      )}
      <Countdown trigger={clear} expirationDate={expirationDate} />
    </div>
  );
};
