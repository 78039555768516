import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { AppState } from '../store';
import { StockCRUD } from '../components/GeneratedComponents';
import { addStock } from '../actions/middleActions';
import FileReaderCSV from '../components/FileReader';
import GenericModal from '../components/generics/GenericModal';
import { ButtonCargarInventario } from '../components/generics/GenericThemes';
import Swal from 'sweetalert2';
import { STOCK_COLUMNS } from '../entities/StockEntity';
import { Stock, StockOriginValues } from '../types';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import * as E from '../entities';
import { fetchAvailableStockDetail } from '../actions/middleActions';
import { GenericDropDownList } from '../components/generics/GenericForm';
import { performUncatchedRequest } from '../utils/request';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { generateCRUD } from '../components/CRUDGenerator';
import { voidEntitiesAction } from '../actions/entitiesActions';
import {
  UnpublishStockButton,
  MassiveStockOriginEditionButton
} from '../components/SecondaryButton';

const generateAvailableStockCRUD = (stockOriginName: string) =>
  generateCRUD({
    webEntity: E.AvailableStockEntity,
    title: 'Stock Disponible',
    query: { stockOriginName },
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false, single: true }
    }
  });

const generateAvailableStockDependentCRUD = (stockOriginNameToFetch: string) =>
  generateCRUD({
    webEntity: E.AvailableStockDependentEntity,
    title: 'Detalle',
    additionalTableActions: ({ selected }) => {
      const stockIds = selected.map((stock) => stock._id);
      const itemId = selected.length > 0 ? selected[0].itemId : '';
      const stockOriginName = selected.length > 0 ? selected[0].stockOriginName || '' : '';
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <UnpublishStockButton
            stockIds={stockIds}
            itemId={itemId}
            stockOriginName={stockOriginName}
          />
          <MassiveStockOriginEditionButton
            stockIds={stockIds}
            stockOriginNameToFetch={stockOriginNameToFetch}
          />
        </div>
      );
    },
    dummy: true,
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: true,
      select: { enable: true }
    }
  });

const ConnectedRefreshAvailableStock = connect(
  ({ entities: { availableStock } }: AppState) => ({
    state: availableStock,
    entityName: E.AvailableStockDependentEntity.name
  }),
  {
    fetchFunction: fetchAvailableStockDetail
  }
)(RefresherComponent);

const StockLoadAdminPage: FunctionComponent<{
  addStock: (data: Array<any>, stockOriginId: string) => any;
  cleanAvailableDependentCRUD: () => void;
}> = ({ addStock, cleanAvailableDependentCRUD }) => {
  const [stockOriginId, setStockOriginId] = useState('');
  const [stockOrigins, setStockOrigins] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStockOrigin = async () => {
      setLoading(true);
      try {
        const { data } = await performUncatchedRequest({
          endpoint: `stock/stockOrigin`,
          method: 'get'
        });

        setStockOrigins(data);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetchStockOrigin();
  }, []);

  const callback = useMemo(
    () => async (data: Array<any>) => {
      const res = await addStock(data, stockOriginId);

      if (res?.status !== 200) {
        return;
      }

      let existingBarcodes = [];

      if (res.data.existingBarcodes.length > 0) {
        existingBarcodes = res.data.existingBarcodes.map((el: Stock) => el.barcode);
      }
      Swal.fire({
        icon: existingBarcodes.length > 0 ? 'warning' : 'success',
        title: `<h3>${res.data.quantityInsert} items registrados. \n${
          existingBarcodes.length > 0
            ? `Se omitieron los siguientes barcode (${existingBarcodes.length}):`
            : ''
        }</h3>`,
        text: existingBarcodes.length > 0 ? `${existingBarcodes.join(', ')}` : undefined
      });
    },
    [stockOriginId]
  );

  const stockOriginOnChange = (_: any, data: any) => {
    setStockOriginId(data.value);
  };

  const [valueFormGroup, setValueFormGroup] = React.useState<string>(StockOriginValues.Bolsa);

  const handleChangeFormGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanAvailableDependentCRUD();
    setValueFormGroup((event.target as HTMLInputElement).value);
  };

  const AvailableStock = useMemo(
    () => generateAvailableStockCRUD(valueFormGroup),
    [valueFormGroup]
  );

  const AvailableStockDetail = useMemo(
    () => generateAvailableStockDependentCRUD(valueFormGroup),
    [valueFormGroup]
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Tab
        menu={{ color: 'red', pointing: true }}
        panes={[
          {
            menuItem: {
              key: 'stock',
              icon: 'list alternate',
              content: 'Ingreso de Stock'
            },
            render: () => (
              <Tab.Pane attached={true}>
                <div>
                  <GenericModal
                    title='Carga de Stock'
                    style={ButtonCargarInventario}
                    buttonName='CARGAR STOCK'
                    icon='update'
                    localIcon={true}
                  >
                    <div>
                      <span>
                        <b>NOTA:</b> <br />- Archivo <b>.csv</b> debe estar separado por <b>(;)</b>{' '}
                        punto y coma. <br />- Decimales separados por <b>(,)</b> coma. <br />-
                        Columnas de texto dentro de <b>("")</b> comillas dobles dobles.
                        <br />
                        <br />
                      </span>
                      <div>
                        <GenericDropDownList
                          {...{
                            fieldName: 'Origen Stock',
                            data: stockOrigins,
                            valueField: 'stockOriginId',
                            textField: 'stockOriginName',
                            onChange: stockOriginOnChange,
                            value: stockOriginId,
                            required: true,
                            loading: loading
                          }}
                        />
                      </div>
                      <div>
                        <FileReaderCSV columns={STOCK_COLUMNS} callback={callback} />
                      </div>
                    </div>
                  </GenericModal>
                  <StockCRUD />
                </div>
              </Tab.Pane>
            )
          },
          {
            menuItem: {
              key: 'availableStock',
              icon: 'list alternate',
              content: 'Stock Disponible'
            },
            render: () => (
              <Tab.Pane attached={true}>
                <div>
                  <ConnectedRefreshAvailableStock />
                  <RadioGroup
                    row
                    aria-labelledby='demo-radio-buttons-group-label'
                    name='radio-buttons-group'
                    value={valueFormGroup}
                    onChange={handleChangeFormGroup}
                  >
                    <FormControlLabel
                      value={StockOriginValues.Bolsa}
                      control={<Radio />}
                      label={StockOriginValues.Bolsa}
                    />
                    <FormControlLabel
                      value={StockOriginValues.Estandar}
                      control={<Radio />}
                      label={StockOriginValues.Estandar}
                    />
                  </RadioGroup>
                  <AvailableStock />
                  <AvailableStockDetail />
                </div>
              </Tab.Pane>
            )
          }
        ]}
      />
    </div>
  );
};

export default connect(({ auth, entities }: AppState) => ({ auth, entities }), {
  addStock,
  cleanAvailableDependentCRUD: voidEntitiesAction(E.AvailableStockDependentEntity.name)
})(StockLoadAdminPage);
