import { Lot } from '../types';
import {
  dateFormatFn,
  formatStringNumber,
  booleanFormatFn,
  dateFormatTimezoneFn
} from '../utils/utils';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';

const updateDependencyDate =
  (
    fieldName: string,
    fieldNameToUpdate: 'auctionStartDate' | 'auctionEndDate'
  ): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      const field = getField(fieldNameToUpdate);
      setLoadings([field], true);

      const fieldValueToUpdate = getFieldValue(fieldNameToUpdate);

      let newValueToUpdate = fieldValueToUpdate;

      if (fieldValue && fieldValueToUpdate) {
        if (
          // NOTE: Make sure that end date never be less than start and start date never be bigger than end date
          (fieldName === 'auctionStartDate' && fieldValue > fieldValueToUpdate) ||
          (fieldName === 'auctionEndDate' && fieldValue < fieldValueToUpdate)
        ) {
          newValueToUpdate = fieldValue;
        }
      } else if (
        // NOTE: If clean start, clean both of them, if choose end without a start, give the start the same date as end
        (fieldName === 'auctionStartDate' && !fieldValue && fieldValueToUpdate) ||
        (fieldName === 'auctionEndDate' && fieldValue && !fieldValueToUpdate)
      ) {
        newValueToUpdate = fieldValue;
      }

      updateFieldValue(fieldNameToUpdate, newValueToUpdate);
      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const enableAuctionDates: UpdateDependencyFunc = async (
  fieldValue,
  getField,
  setLoadings,
  _,
  __,
  ___
): Promise<void> => {
  try {
    const auctionStartDateField = getField('auctionStartDate');
    const auctionEndDateField = getField('auctionEndDate');
    setLoadings([auctionStartDateField, auctionEndDateField], true);
    if (fieldValue) {
      auctionStartDateField.disabled = false;
      auctionEndDateField.disabled = false;
    } else {
      auctionStartDateField.disabled = true;
      auctionEndDateField.disabled = true;
    }
    setLoadings([auctionStartDateField, auctionEndDateField], false);
  } catch (error) {
    console.log(error);
  }
};

const LotEntity: WebEntity<Lot> = {
  name: 'lot',
  endpoint: 'lots',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Numero de lote',
      selector: 'lotId',
      sortable: true,
      wrap: true
    },
    {
      name: 'Producto',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad de Medida',
      selector: 'unitOfMeasureName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio Unitario',
      selector: 'lotPrice',
      format: ({ lotPrice }) => `$${formatStringNumber(lotPrice.toString())}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Inicial',
      selector: 'packagingLotInitialQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad Actual',
      selector: 'packagingLotCurrentQuantity',
      sortable: true,
      wrap: true
    },
    {
      name: 'Kgs',
      selector: 'lotInitialQuantity',
      format: ({ lotInitialQuantity }) =>
        `${formatStringNumber(lotInitialQuantity.toString(), true, 0, 2)} Kg`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio Total',
      selector: 'lotPrice',
      format: ({ lotPrice, lotInitialQuantity }) =>
        `$${formatStringNumber((lotPrice * lotInitialQuantity).toString())}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Es Publico?',
      selector: 'isPublic',
      format: ({ isPublic }) => booleanFormatFn(isPublic),
      sortable: true,
      wrap: true
    },
    {
      name: 'Para remate?',
      selector: 'isForAuction',
      format: ({ isForAuction }) => booleanFormatFn(isForAuction),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha inicio remate',
      selector: 'auctionStartDate',
      format: ({ auctionStartDate }) =>
        dateFormatTimezoneFn(auctionStartDate, 'DD/MM/YYYY HH:mm:ss', 'America/Santiago'),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha termino remate',
      selector: 'auctionEndDate',
      format: ({ auctionEndDate }) =>
        dateFormatTimezoneFn(auctionEndDate, 'DD/MM/YYYY HH:mm:ss', 'America/Santiago'),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Elaboración',
      selector: 'productionDate',
      format: ({ productionDate }) => dateFormatFn(productionDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      format: ({ expirationDate }) => dateFormatFn(expirationDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Creado en',
      selector: 'dateCreated',
      format: ({ dateCreated }) => dateFormatFn(dateCreated),
      sortable: true,
      wrap: true
    },
    {
      name: 'Actualizado en',
      selector: 'dateUpdated',
      format: ({ dateUpdated }) => dateFormatFn(dateUpdated),
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Precio',
      selector: 'precio',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Es Publico?',
      selector: 'isPublic',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Precio Unitario',
      selector: 'lotPrice',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Para remate?',
      selector: 'isForAuction',
      type: FieldTypes.Boolean,
      updateDependency: enableAuctionDates,
      executeUpdateDependencyOnMount: true,
      required: true
    },
    {
      name: 'Fecha inicio remate',
      selector: 'auctionStartDate',
      type: FieldTypes.DateTime,
      timezone: 'America/Santiago',
      updateDependency: updateDependencyDate('auctionStartDate', 'auctionEndDate'),
      required: false
    },
    {
      name: 'Fecha termino remate',
      selector: 'auctionEndDate',
      type: FieldTypes.DateTime,
      timezone: 'America/Santiago',
      //updateDependency: updateDependencyDate('auctionEndDate', 'auctionStartDate'),
      required: false
    }
  ]
};

export default LotEntity;
