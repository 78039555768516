import { SaleOrder, UserTypeValues } from '../types';
import { formatStringNumber, dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

/*
   saleOrderId: string;
  userBuyerId: string;
  userRequesterId: string;
  subtotal: string;
  saleOrderDate: string;
  saleOrderExpirationDate: Date;
  saleOrderStateId: string;
  dateCreated: string;
  dateUpdated: string;
*/

const SaleOrderEntity: WebEntity<SaleOrder> = {
  name: 'saleOrder',
  endpoint: 'saleOrders',
  referenceColumn: '_id',
  customButtonNames: {
    deleteButtonName: 'Quitar del carro'
  },

  tableColumns: [
    {
      name: 'ID de orden',
      selector: '_id'
      //omit: true
    },
    {
      name: 'Cliente',
      selector: 'userBuyerId',
      format: ({ userBuyerFullname, userBuyerId }) => userBuyerFullname || userBuyerId,
      sortable: true,
      wrap: true
    },
    {
      name: 'Correo Cliente',
      selector: 'userBuyerId',
      format: ({ userBuyerEmail, userBuyerId }) => userBuyerEmail || userBuyerId,
      sortable: true,
      wrap: true
    },
    {
      name: 'Operador',
      selector: 'userRequesterId',
      format: ({ userRequesterFullname, userRequesterId }) =>
        userRequesterFullname || userRequesterId,
      sortable: true,
      wrap: true
    },
    {
      name: 'Subtotal Estimado',
      selector: 'subtotal',
      format: ({ subtotal }) => `$${formatStringNumber(subtotal)}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha de orden',
      selector: 'saleOrderDate',
      format: ({ saleOrderDate }) => `${dateFormatFn(saleOrderDate, 'DD/MM/YYYY')}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Estado de Orden',
      selector: 'saleOrderStateId',
      format: ({ saleOrderStateName, saleOrderStateId }) => saleOrderStateName || saleOrderStateId,
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Precio',
      selector: 'precio',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [],

  filterFields: [
    {
      name: 'Item',
      selector: 'itemId',
      type: FieldTypes.MultiReference,
      required: false,
      reference: {
        name: 'item',
        endpoint: 'items',
        select: 'itemId',
        show: 'itemName,brandName,countryName'
      }
    },
    {
      name: 'Comprador',
      selector: 'userBuyerId',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'user',
        endpoint: 'users',
        select: 'userId',
        show: 'userFullname,nationalId',
        filter: { userTypeNames: [UserTypeValues.Cliente] }
      }
    },
    {
      name: 'Vendedor',
      selector: 'userRequesterId',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'user',
        endpoint: 'users',
        select: 'userId',
        show: 'userFullname,nationalId',
        filter: { userTypeNames: [UserTypeValues.Operador, UserTypeValues.Admin] }
      }
    }
  ]
};

export default SaleOrderEntity;
