import React, { FunctionComponent, Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Form, FormGroup, Icon, Image } from 'semantic-ui-react';
import { toBase64 } from '../utils/convert';
import { warningPopAlert, errorPopAlert, requestErrorPopAlert } from './PopAlert';
import { Element } from '../types';
import { performUncatchedRequest, sendFileWithData } from '../utils/request';
import { SimpleObject } from '../types';
import { refreshEntity } from '../actions/entitiesActions';
import { connect } from 'react-redux';

const PhotoForm = ({
  callback,
  entity,
  element
}: {
  callback: any;
  entity: SimpleObject;
  element: Element;
}) => {
  const [image, setImage] = React.useState('');
  const [isImageOpen, setIsImageOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const switchModal = (modalFn: any, isOpen: boolean) => () => modalFn(isOpen);

  const onClickModalImage = () => () => {
    switchModal(setIsImageOpen, true)();
  };

  const onDeleteModalImage = () => () => {
    setImage('');
    switchModal(setIsImageOpen, false)();
  };

  const onSelectStoredPhoto = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      const img = await toBase64(selectedFile).catch((e) => Error(e));
      if (img instanceof Error) {
        console.log('Error: ', img.message);
        return;
      }

      if ('image/png' !== selectedFile.type) {
        errorPopAlert('Error en la carga de imagen', '', 'El formato de la imagen debe ser PNG.');
        return;
      }

      setImage(img as string);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (!image) {
      warningPopAlert('Se debe cargar una imagen');
      setIsLoading(false);
      return;
    }

    const elementUpdate = { element, image };

    try {
      await sendFileWithData({
        endpoint: `${entity.endpoint}/uploadImg`,
        dataObject: { elementUpdate }
      });
      if (callback) {
        callback();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      requestErrorPopAlert(error);
    }
  };

  return (
    <Fragment key={`generic_form_imagen`}>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {image && (
          <div
            key={`generic_form_0`}
            onClick={onClickModalImage()}
            style={{ marginLeft: '1em', marginTop: '1em' }}>
            <Image wrapped size='tiny' src={image} />
          </div>
        )}
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <div style={{ display: 'block' }}>
              <input
                id='upload-button'
                type='file'
                accept='image/*'
                style={{ display: 'none' }}
                name='storedImage'
                onChange={onSelectStoredPhoto}
              />
              <label htmlFor='upload-button' style={{ cursor: 'pointer' }}>
                <Icon
                  name='upload'
                  color='black'
                  size='big'
                  inverted
                  style={{ marginLeft: '0.2em' }}
                />
              </label>
            </div>
            <Button style={{ marginTop: '2rem' }}>Agregar</Button>
            {isLoading && <label>Cargando...</label>}
          </FormGroup>
        </Form>

        <Modal isOpen={isImageOpen} autoFocus={true} toggle={switchModal(setIsImageOpen, false)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <Image wrapped src={image} onClick={switchModal(setIsImageOpen, false)} />
            <Button style={{ width: '100%' }} type='button' onClick={onDeleteModalImage()}>
              <Icon name='trash' />
              Eliminar
            </Button>
          </div>
        </Modal>
      </div>
    </Fragment>
  );
};

const SimpleManageImage: FunctionComponent<{
  entity: SimpleObject;
  element: Element;
  refreshEntity: any;
}> = ({ entity, element, refreshEntity }) => {
  const switchModal = (modalFn: any, isOpen: boolean) => () => modalFn(isOpen);
  const [isFormOpen, setIsFormOpen] = React.useState(false);
  const [existImg, setExistImg] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchImage = async () => {
      try {
        const res = await performUncatchedRequest({
          endpoint: `${entity.endpoint}/getImg`,
          method: 'get',
          query: { elementId: element.elementId }
        });
        const img = res.data.element ? res.data.element.elementImg : null;
        setExistImg(img);
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('Error desconocido');
        }
        setIsLoading(false);
      }
    };

    fetchImage();
  }, [entity.endpoint, element]);

  const onDelete = async () => {
    try {
      if (window.confirm(`¿Seguro que deseas eliminar la imagen: \r ${element.elementName}?`)) {
        await performUncatchedRequest({
          endpoint: `${entity.endpoint}/deleteImg`,
          method: 'patch',
          query: { element: element }
        });
        refreshEntity(entity.name);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Error desconocido');
      }
    }
  };

  const setIsOpen = () => setIsFormOpen(true);

  const localRefreshEntity = () => refreshEntity(entity.name);

  return (
    <>
      {isLoading ? (
        <p>Cargando...</p>
      ) : existImg ? (
        <>
          <Image wrapped size='tiny' src={existImg} />
          <Button style={{ width: '100%' }} type='button' onClick={onDelete}>
            <Icon name='trash' />
            Eliminar
          </Button>
        </>
      ) : (
        <>
          {error && <p>No se encontró la imagen</p>}
          <Button onClick={setIsOpen}>Agregar imagen</Button>
          <Modal isOpen={isFormOpen} autoFocus={true}>
            <ModalHeader toggle={switchModal(setIsFormOpen, false)}>Agregar Imagen</ModalHeader>
            <ModalBody>
              <PhotoForm callback={localRefreshEntity} entity={entity} element={element} />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export const ManageImage = connect(null, { refreshEntity })(SimpleManageImage);
