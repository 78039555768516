import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import '../../css/login.css';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';
import { clearErrors } from '../../actions/errorActions';
import { AuthState, ErrorState, LOGIN_FAIL, LOGIN_SUCCESS } from '../../reducers/types';
import { login } from '../../actions/authActions';
import { pagesName } from '../../pages/AnonimousPage';

const LoginForm: FunctionComponent<{
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  msg: string | null;
  isLoading: boolean;
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
}> = ({ onChange, onSubmit, msg, isLoading, setPageContentName }) => {
  return (
    <div className={'login-form'}>
      <h1 className={'title'}>Bienvenido</h1>
      <p className={'text-under-title'}>Debes iniciar sesión para comenzar</p>
      <Form onSubmit={onSubmit}>
        <FormGroup className={'form-group'}>
          {/* User */}
          <Label for='userUsername' className={'user-label'}>
            Ingresar usuario
          </Label>
          <Input
            name='userUsername'
            type='text'
            placeholder='Escribir usuario'
            className={'user-input'}
            onChange={onChange}
          />

          {/* Password */}
          <Label for='userPassword' className={'password-label'}>
            Contraseña
          </Label>
          <Input
            name='userPassword'
            type='password'
            placeholder='Escribir contraseña'
            className={'password-input'}
            onChange={onChange}
          />
          {/* <p className={'reset-password'}>¿Recuperar contraseña?</p> */}

          {/* Login Button */}
          <div className={'submit-button'}>
            {isLoading && <span className='loader'></span>}
            {msg && <Alert color='danger'>{msg}</Alert>}
            <button>Iniciar Sesión</button>
          </div>
        </FormGroup>
      </Form>
      <div className='sign-up-container'>
        <hr />
        <button onClick={() => setPageContentName(pagesName.signUp)}>Registrarse</button>
      </div>
    </div>
  );
};

type Props = {
  auth: AuthState;
  error: ErrorState;
  login: ({
    userUsername,
    userPassword
  }: {
    userUsername: string;
    userPassword: string;
  }) => Promise<'LOGIN_SUCCESS' | 'LOGIN_FAIL'>;
  clearErrors: typeof clearErrors;
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
};

const LoginPage: FunctionComponent<Props> = ({
  auth,
  error,
  login,
  clearErrors,
  setPageContentName
}) => {
  const [userInfo, setUserInfo] = useState<{ userUsername: string; userPassword: string }>({
    userUsername: '',
    userPassword: ''
  });
  const [msg, setMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      // Check for register error
      if (error.id === LOGIN_FAIL) {
        setMsg(error.msg.msg);
      } else {
        setMsg(null);
      }
    }
  }, [error]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    clearErrors();
    setMsg(null);
    if ((await login(userInfo)) === LOGIN_SUCCESS) {
      setPageContentName(pagesName.home);
    }
  };

  return (
    <div className={'login-page-container'}>
      <LoginForm
        onSubmit={handleOnSubmit}
        onChange={handleOnChange}
        msg={msg}
        isLoading={isLoading}
        setPageContentName={setPageContentName}
      />
    </div>
  );
};

export default connect(({ auth, error }: AppState) => ({ auth, error }), { login, clearErrors })(
  LoginPage
);
