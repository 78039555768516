import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import './css/productStorePage.css';
import GenericIcons from '../components/generics/GenericIcons';
import {
  GeneralSaleRule,
  ItemStock,
  StockOriginValues,
  StockOriginValuesType,
  UserTypeValues,
  UserTypeValuesType
} from '../types';
import { getUncatchEndpointData, performUncatchedRequest } from '../utils/request';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import { requestErrorPopAlert } from '../components/PopAlert';
import { Button } from 'semantic-ui-react';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  border: '2px solid #000',
  boxShadow: 24
};

const PriceInputModal: FunctionComponent<{
  forceRefresh: () => void;
  item: ItemStock;
  minPackagingQuantityForDiscount: number;
  stockOriginName: StockOriginValuesType;
  auth: AuthState;
}> = ({ forceRefresh, item, minPackagingQuantityForDiscount, stockOriginName, auth }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [quantityInput, setQuantityInput] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hasDiscount, setHasDiscount] = useState<boolean>(false);
  const [newPrice, setNewPrice] = useState<number>(item.unitOfMeasurePrice);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  useEffect(() => {
    const getNewPrice = async () => {
      setIsCalculating(true);
      const res = await getUncatchEndpointData({
        endpoint: 'rules/calculateDiscountByItem',
        query: { itemId: item._id, quantityInput, stockOriginName }
      });
      setNewPrice(res);
      setIsCalculating(false);
    };

    try {
      if (
        quantityInput >= minPackagingQuantityForDiscount &&
        Number(item.stockQuantity) >= minPackagingQuantityForDiscount
      ) {
        getNewPrice();
        setHasDiscount(true);
      } else {
        setHasDiscount(false);
      }
    } catch (error: unknown) {
      requestErrorPopAlert(error);
      setOpenModal(false);
      setLoading(false);
    }
  }, [openModal, quantityInput]);

  const onClickAddCart = async () => {
    setLoading(true);
    try {
      const res = await performUncatchedRequest({
        endpoint: 'trades/saleOrder',
        method: 'post',
        data: { item, quantityInput, stockOriginName }
      });

      if (res) {
        Swal.fire('Se agrega al carrito de compras');
      }
    } catch (error) {
      requestErrorPopAlert(error);
      setOpenModal(false);
      setLoading(false);
    }

    setOpenModal(false);
    setLoading(false);
    forceRefresh();
  };

  const isInternalUser = (auth: AuthState) =>
    [UserTypeValues.Admin, UserTypeValues.Operador].includes(
      auth?.user?.userTypeName as UserTypeValuesType
    );

  const isClientUser = (auth: AuthState) =>
    [UserTypeValues.Cliente].includes(auth?.user?.userTypeName as UserTypeValuesType);

  const getMaxQuantity = (auth: AuthState, item: ItemStock) =>
    isInternalUser(auth) ? Number(item.totalStockQuantity) : Number(item.stockQuantity);

  const onClickChangeInput = (newInput: number) => {
    setLoading(true);
    if (quantityInput + newInput > 0 && quantityInput + newInput <= getMaxQuantity(auth, item)) {
      setQuantityInput(quantityInput + newInput);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <GenericIcons
          onClick={() => setOpenModal(true)}
          icon={isHovered ? 'PlusCircleYellowIcon' : 'PlusCircleWhiteIcon'}
          className='add-icon'
        />
      </div>

      <Modal
        open={openModal}
        onClose={() => {
          setQuantityInput(1);
          setOpenModal(false);
          //forceRefresh();
        }}
      >
        <Box sx={{ ...style }}>
          <div className='price-input-modal-container'>
            <div className='price-input-modal-left'>
              <div className='price-input-modal-title'>
                <h2>{item.brandName}</h2>
                <h1>{item.itemName}</h1>
              </div>
              <div className='price-input-modal-description'>
                <p>
                  Vendido por <span>Agrolujuma</span>
                </p>
                {true /*stockOriginName === StockOriginValues.Bolsa*/ && (
                  <>
                    {isInternalUser(auth) ? (
                      <>
                        <p>
                          Stock Publicado: <span>{item.stockQuantity}</span>
                        </p>
                        <p>
                          Stock Disponible: <span>{item.totalStockQuantity}</span>
                        </p>
                      </>
                    ) : isClientUser(auth) ? (
                      <p>
                        Stock: <span>{item.stockQuantity}</span>
                      </p>
                    ) : null}
                    <p>
                      Fecha Expiración:{' '}
                      <span>{dateFormatFn(item.expirationDate, 'DD/MM/YYYY', true)}</span>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className='price-input-modal-right'>
              <div className='price-input-modal-right-price-text'>
                {isCalculating ? (
                  <span className='loader'></span>
                ) : (
                  <Fragment>
                    {hasDiscount ? (
                      <s className='price-without-discount'>{`$${formatStringNumber(
                        item.unitOfMeasurePrice.toString()
                      )}`}</s>
                    ) : null}

                    <p className={hasDiscount ? 'price-with-discount' : ''}>{`$${formatStringNumber(
                      newPrice.toString()
                    )}`}</p>
                  </Fragment>
                )}
              </div>

              <div className='price-input-modal-right-add-cart-container'>
                <div className='price-input-modal-right-quantity-input'>
                  <button onClick={() => onClickChangeInput(-1)}>-</button>
                  <input
                    className='input-quantity'
                    type='number'
                    min={1}
                    max={getMaxQuantity(auth, item)}
                    value={quantityInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        !e.target.value ||
                        isNaN(Number(e.target.value)) ||
                        Number(e.target.value) < 1 ||
                        Number(e.target.value) > getMaxQuantity(auth, item) ||
                        !/^\d+(.\d+)?$/.test(e.target.value)
                      ) {
                        return setQuantityInput(1);
                      }
                      return setQuantityInput(Number(e.target.value));
                    }}
                  />
                  <button onClick={() => onClickChangeInput(1)}>+</button>
                </div>
                <Button
                  onClick={onClickAddCart}
                  className='add-to-cart-button'
                  loading={loading}
                  disabled={isCalculating || loading}
                >
                  Agregar
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};
const ConnectedPriceInputModal = connect(({ auth }: AppState) => ({ auth }))(PriceInputModal);

const PriceListComponent: FunctionComponent<{
  stockOriginName: StockOriginValuesType;
}> = ({ stockOriginName }) => {
  const [priceList, setPriceList] = useState<ItemStock[]>([]);
  const [brands, setBrands] = useState<string[]>([]);
  const [items, setItems] = useState<string[]>([]);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [minPackagingQuantityForDiscount, setMinPackagingQuantityForDiscount] = useState<number>(0);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const endpoint =
        stockOriginName === StockOriginValues.Bolsa ? 'trades/itemsBolsa' : 'trades/itemsEstandar';
      const data = await getUncatchEndpointData({ endpoint });
      const generalSaleRules = await getUncatchEndpointData({ endpoint: 'rules/generalSale' });
      setPriceList(data);
      setMinPackagingQuantityForDiscount(
        generalSaleRules.find(
          (rule: GeneralSaleRule) => rule.ruleName === 'min_packaging_quantity_for_discount'
        ).ruleValue
      );
      setLoading(false);
    };
    getData();
  }, [forceRefresh, stockOriginName]);

  useEffect(() => {
    setItems(Array.from(new Set(priceList.map((row) => row.itemName))).sort());
    setBrands(Array.from(new Set(priceList.map((row) => row.brandName))).sort());
  }, [priceList]);

  return loading || priceList.length === 0 || brands.length === 0 || items.length === 0 ? (
    <div className='loading-table-text'>
      {loading ? <h2>Cargando...</h2> : <h2>No hay items para vender</h2>}
    </div>
  ) : (
    <table style={{ borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th className='col-type-product-name'></th>
          <th colSpan={brands.length} className='col-type-1 external-company-title'>
            Agrolujuma
          </th>
        </tr>
        <tr>
          <th className='col-type-product-name'></th>
          {brands.map((brand, idx) => (
            <th className='col-type-1' key={idx}>
              {brand}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, idx) => (
          <tr key={idx}>
            <td className='col-type-product-name'>{item}</td>
            {brands.map((brand, idxB) => {
              const foundItem = priceList.find(
                (row) => row.brandName === brand && row.itemName === item
              );
              return !foundItem ? (
                <td className='col-type-1' key={idxB}></td>
              ) : (
                <td className='col-type-1' key={idxB}>
                  <div>
                    <p>{`$${formatStringNumber(foundItem.unitOfMeasurePrice.toString())}`}</p>
                    <b>({`${formatStringNumber(foundItem.stockQuantity.toString())}`})</b>
                    <div className='add-icon-container'>
                      <ConnectedPriceInputModal
                        item={foundItem}
                        forceRefresh={() => setForceRefresh(!forceRefresh)}
                        minPackagingQuantityForDiscount={minPackagingQuantityForDiscount}
                        stockOriginName={stockOriginName}
                      />
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ProductStorePage: FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const changeTab = (numeroPestaña: number) => {
    setActiveTab(numeroPestaña);
  };

  return (
    <div className='product-store-page-container'>
      <div className='tab-container'>
        <span onClick={() => changeTab(1)} className={activeTab === 1 ? `active-tab` : ''}>
          <GenericIcons icon='ReceiptIcon' />
          <p>Lista de precios</p>
        </span>
        <span onClick={() => changeTab(2)} className={activeTab === 2 ? `active-tab` : ''}>
          <GenericIcons icon='YellowTagIcon' />
          <p>Bolsa de productos</p>
        </span>
      </div>
      <div className='data-table'>
        {activeTab === 1 ? (
          <PriceListComponent stockOriginName={StockOriginValues.Estandar} />
        ) : activeTab === 2 ? (
          <PriceListComponent stockOriginName={StockOriginValues.Bolsa} />
        ) : null}
      </div>
    </div>
  );
};

export default ProductStorePage;
