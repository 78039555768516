import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import GenericContainerSidebar from '../components/generics/GenericContainerSidebar/GenericContainerSidebar';
import { autoRoutes, adminLinks, operadorLinks } from '../components/App';
import AppNavbar from '../components/AppNavbar';
import Logout from '../components/auth/Logout';

interface IProps {
  auth: AuthState;
}
const ControlPanelPage: FunctionComponent<IProps> = ({ auth }) => {
  return (
    <GenericContainerSidebar
      titleTopNav='Meet Trader'
      AppNavbar={<AppNavbar sections={{ adminLinks, operadorLinks }} />}
      linkTopNav='/'
      user={auth.user?.userUsername}
      logout={<Logout />}>
      {autoRoutes()}
    </GenericContainerSidebar>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(ControlPanelPage);
