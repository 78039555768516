import React, { CSSProperties } from 'react';
import update from '../../img/CloudArrowUp-r.svg';
import datafy from '../../img/Logo.svg';
import edit from '../../img/PencilLine-r.svg';
import filter from '../../img/Funnel-r.svg';
import add from '../../img/Plus-r.svg';
import arrowLeft from '../../img/arrowLeftMenu.png';
import manteiner from '../../img/FolderNotch-d.svg';
import count from '../../img/ListNumbers-r.svg';
import arrow from '../../img/ArrowLineRight-s.svg';
import info from '../../img/Info-r.svg';
import infoWhite from '../../img/Info-rWhite.svg';
import report from '../../img/ClipboardText.svg';
import table from '../../img/Table-r.svg';
import trash from '../../img/Trash-r.svg';
import Eye from '../../img/Eye-r.svg';
import duplicate from '../../img/duplicate.png';
import arrowRight from '../../img/iconRightWhite.png';
import signoutGray from '../../img/signoutGray.png';
import userIconBlue from '../../img/UserIconBlue.svg';
import userIconWhite from '../../img/UserIconWhite.svg';
import truckIconWhite from '../../img/truckIconWhite.svg';
import menuIcon from '../../img/menuIcon.svg';
import downArrowIcon from '../../img/downArrow.svg';
import upArrowIcon from '../../img/upArrow.svg';
import adminIcon from '../../img/adminIcon.png';
import topArrowDropdownIcon from '../../img/top-arrow-dropdown.svg';
import closeXIcon from '../../img/close-x.svg';
import arrowDownIcon from '../../img/ArrowDown.svg';
import arrowRightIcon from '../../img/ArrowRight.svg';
import stockYVentasIcon from '../../img/stockYVentasIcon.svg';
import administracionUsuarios from '../../img/administracionUsuarios.svg';
import SignOutDarkBlue from '../../img/SignOutDarkBlue.svg';
import HouseIcon from '../../img/HouseIcon.svg';
import PackageIcon from '../../img/PackageIcon.svg';
import FolderNotchIcon from '../../img/FolderNotchIcon.svg';
import GearSixIcon from '../../img/GearSixIcon.svg';
import CaretRightIcon from '../../img/CaretRightIcon.svg';
import engranajeIcon from '../../img/engranaje.png';
import CalendarBlankIcon from '../../img/CalendarBlankIcon.svg';
import internationalTradeIcon from '../../img/internationalTradeIcon.svg';
import TruckIcon from '../../img/TruckIcon.svg';
import TagIcon from '../../img/TagIcon.svg';
import DiamondIcon from '../../img/DiamondIcon.svg';
import arrowOurClubBlue from '../../img/arrowOurClub-blue.svg';
import arrowOurClubYellow from '../../img/arrowOurClub-yellow.svg';
import XCircleIcon from '../../img/XCircleIcon.svg';
import BlueClockIcon from '../../img/BlueClockIcon.svg';
import MagnifyingGlass from '../../img/MagnifyingGlass.svg';
import DollarIcon from '../../img/DollarIcon.svg';
import DatafyWhiteIcon from '../../img/DatafyWhiteIcon.svg';
import YellowTagIcon from '../../img/Tag-r.svg';
import ReceiptIcon from '../../img/Receipt-r.svg';
import PlusCircleYellowIcon from '../../img/PlusCircleYellow.svg';
import PlusCircleWhiteIcon from '../../img/PlusCircleWhite.svg';

type Props = {
  onClick?: () => void;
  icon?: string;
  className?: string;
  imageStyle?: CSSProperties;
};

const GenericIcons = (props: Props) => {
  const { onClick, icon, className, imageStyle } = props;

  const iconData = [
    {
      id: 1,
      icon: 'update',
      svg: update
    },
    {
      id: 2,
      icon: 'datafy',
      svg: datafy
    },
    {
      id: 3,
      icon: 'SignOutDarkBlue',
      svg: SignOutDarkBlue
    },
    {
      id: 4,
      icon: 'mantainer',
      svg: manteiner
    },
    {
      id: 5,
      icon: 'count',
      svg: count
    },
    {
      id: 6,
      icon: 'arrow',
      svg: arrow
    },
    {
      id: 7,
      icon: 'edit',
      svg: edit
    },
    {
      id: 8,
      icon: 'filter',
      svg: filter
    },
    {
      id: 9,
      icon: 'add',
      svg: add
    },
    {
      id: 10,
      icon: 'arrowLeft',
      svg: arrowLeft
    },
    {
      id: 11,
      icon: 'info',
      svg: info
    },
    {
      id: 12,
      icon: 'infoWhite',
      svg: infoWhite
    },
    {
      id: 13,
      icon: 'report',
      svg: report
    },
    {
      id: 14,
      icon: 'table',
      svg: table
    },
    {
      id: 15,
      icon: 'trash',
      svg: trash
    },
    {
      id: 16,
      icon: 'eye',
      svg: Eye
    },
    {
      id: 17,
      icon: 'duplicate',
      svg: duplicate
    },
    {
      id: 18,
      icon: 'arrowRightWhite',
      svg: arrowRight
    },
    {
      id: 19,
      icon: 'signoutGray',
      svg: signoutGray
    },
    {
      id: 20,
      icon: 'userIconBlue',
      svg: userIconBlue
    },
    {
      id: 21,
      icon: 'userIconWhite',
      svg: userIconWhite
    },
    {
      id: 22,
      icon: 'truckIconWhite',
      svg: truckIconWhite
    },
    {
      id: 23,
      icon: 'menuIcon',
      svg: menuIcon
    },
    {
      id: 24,
      icon: 'upArrowIcon',
      svg: upArrowIcon
    },
    {
      id: 25,
      icon: 'downArrowIcon',
      svg: downArrowIcon
    },
    {
      id: 26,
      icon: 'adminIcon',
      svg: adminIcon
    },
    {
      id: 27,
      icon: 'topArrowDropdownIcon',
      svg: topArrowDropdownIcon
    },
    {
      id: 28,
      icon: 'closeXIcon',
      svg: closeXIcon
    },
    {
      id: 29,
      icon: 'arrowDownIcon',
      svg: arrowDownIcon
    },
    {
      id: 30,
      icon: 'arrowRightIcon',
      svg: arrowRightIcon
    },
    {
      id: 31,
      icon: 'stockYVentasIcon',
      svg: stockYVentasIcon
    },
    {
      id: 32,
      icon: 'administracionUsuarios',
      svg: administracionUsuarios
    },
    {
      id: 33,
      icon: 'HouseIcon',
      svg: HouseIcon
    },
    {
      id: 34,
      icon: 'PackageIcon',
      svg: PackageIcon
    },
    {
      id: 35,
      icon: 'FolderNotchIcon',
      svg: FolderNotchIcon
    },
    {
      id: 36,
      icon: 'GearSixIcon',
      svg: GearSixIcon
    },
    {
      id: 37,
      icon: 'CaretRightIcon',
      svg: CaretRightIcon
    },
    {
      id: 38,
      icon: 'engranajeIcon',
      svg: engranajeIcon
    },
    {
      id: 39,
      icon: 'CalendarBlankIcon',
      svg: CalendarBlankIcon
    },
    {
      id: 40,
      icon: 'internationalTradeIcon',
      svg: internationalTradeIcon
    },
    {
      id: 41,
      icon: 'TruckIcon',
      svg: TruckIcon
    },
    {
      id: 42,
      icon: 'TagIcon',
      svg: TagIcon
    },
    {
      id: 43,
      icon: 'DiamondIcon',
      svg: DiamondIcon
    },
    {
      id: 44,
      icon: 'arrowOurClub-blue',
      svg: arrowOurClubBlue
    },
    {
      id: 45,
      icon: 'arrowOurClub-yellow',
      svg: arrowOurClubYellow
    },
    {
      id: 46,
      icon: 'XCircleIcon',
      svg: XCircleIcon
    },
    {
      id: 47,
      icon: 'BlueClockIcon',
      svg: BlueClockIcon
    },
    {
      id: 48,
      icon: 'MagnifyingGlass',
      svg: MagnifyingGlass
    },
    {
      id: 49,
      icon: 'DollarIcon',
      svg: DollarIcon
    },
    {
      id: 50,
      icon: 'DatafyWhiteIcon',
      svg: DatafyWhiteIcon
    },
    {
      id: 51,
      icon: 'YellowTagIcon',
      svg: YellowTagIcon
    },
    {
      id: 52,
      icon: 'ReceiptIcon',
      svg: ReceiptIcon
    },
    {
      id: 53,
      icon: 'PlusCircleYellowIcon',
      svg: PlusCircleYellowIcon
    },
    {
      id: 54,
      icon: 'PlusCircleWhiteIcon',
      svg: PlusCircleWhiteIcon
    }
  ];

  const findIcon = (icon_: string) => {
    const icon = iconData.find((p) => p.icon === icon_);

    return icon ? icon?.svg : null;
  };

  const SVGIcon = icon ? findIcon(icon) : null;

  return SVGIcon ? (
    <img onClick={onClick} className={className} src={SVGIcon} alt='' style={{ ...imageStyle }} />
  ) : null;
};

export default GenericIcons;
