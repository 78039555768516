import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { AuthState } from '../reducers/types';
import GenericIcons from '../components/generics/GenericIcons';
import './css/homeControlPanel.css';
import { useHistory } from 'react-router-dom';

interface IProps {
  auth: AuthState;
}
const HomeControlPanelPage: FunctionComponent<IProps> = ({ auth }) => {
  const history = useHistory();
  return (
    <div className={'home-control-panel-container'}>
      <div
        className={'control-panel-item'}
        onClick={() => history.push('/control-panel/lot-stock-admin')}>
        <div className={'item-container'}>
          <div className={'item-title'}>
            Stock y <span>Ventas</span>
          </div>
          <div className={'item-icon'}>
            <GenericIcons icon={'stockYVentasIcon'} />
          </div>
          <div className={'item-description'}>
            Agrega y administra tu stock, además puedes revisar ventas realizadas y confirmadas
          </div>
        </div>
      </div>

      <div className={'control-panel-item'}>
        <div className={'item-container'}>
          <div className={'item-title'}>
            Administrador de <span>Usuarios</span>
          </div>
          <div className={'item-icon'}>
            <GenericIcons icon={'administracionUsuarios'} />
          </div>
          <div className={'item-description'}>
            Agrega y administra tus usuarios, internos o clientes, revisa datos de contacto
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(HomeControlPanelPage);
