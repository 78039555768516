import { PriceListBolsa } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const PriceListBolsaEntity: WebEntity<PriceListBolsa> = {
  name: 'priceListBolsa',
  endpoint: 'priceLists/bolsa',
  referenceColumn: 'item',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Corte',
      selector: 'itemName',
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      wrap: true
    },
    {
      name: 'Cantidad',
      selector: 'stockQuantity',
      wrap: true
    },
    {
      name: '$ VTA S/IVA',
      selector: 'priceWithDiscount',
      format: ({ priceWithDiscount }) => `${formatStringNumber(`${priceWithDiscount}`, false)}`,
      wrap: true
    },
    {
      name: 'Fecha Produccion',
      selector: 'elaborationDate',
      format: ({ elaborationDate }) => dateFormatFn(elaborationDate, 'DD/MM/YYYY', true),
      wrap: true
    },
    {
      name: 'Fecha Expiracion',
      selector: 'expirationDate',
      format: ({ expirationDate }) => dateFormatFn(expirationDate, 'DD/MM/YYYY', true),
      wrap: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [],
  exportColumns: [
    { name: 'Corte', selector: 'itemName', format: 'text' },
    { name: 'Marca', selector: 'brandName', format: 'text' },
    {
      name: 'Cantidad',
      selector: 'stockQuantity',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    {
      name: '$ VTA S/IVA',
      selector: 'priceWithDiscount',
      format: 'numeric',
      decimalSeparator: ',',
      thousandSeparator: '.'
    },
    { name: 'Fecha Produccion', selector: 'elaborationDate', format: 'text' },
    { name: 'Fecha Expiracion', selector: 'expirationDate', format: 'text' }
  ]
};

export default PriceListBolsaEntity;
