import React, {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEvent,
  FormEvent,
  Fragment
} from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import '../../css/signUp.css';
import { AuthState, ErrorState, REGISTER_FAIL } from '../../reducers/types';
import { Alert, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import { register } from '../../actions/authActions';
import { rutValidator } from '../../utils/utils';
import { pagesName } from '../../pages/AnonimousPage';

type UserInfoDiccType = {
  name: string;
  selector: string;
  type: InputType;
};

const userInfoDicc: UserInfoDiccType[] = [
  { name: 'Nombre', selector: 'userFullname', type: 'text' },
  { name: 'Usuario', selector: 'userUsername', type: 'text' },
  { name: 'Contraseña', selector: 'userPassword', type: 'password' },
  { name: 'Email', selector: 'userEmail', type: 'email' },
  { name: 'Rut', selector: 'nationalId', type: 'text' },
  { name: 'Nombre Empresa', selector: 'businessNickname', type: 'text' },
  { name: 'Giro', selector: 'lineOfBusiness', type: 'text' },
  { name: 'Nombre Contacto', selector: 'contactName', type: 'text' },
  { name: 'Telefono', selector: 'phoneNumber', type: 'tel' },
  { name: 'Direccion Despacho', selector: 'deliveryAddress', type: 'text' }
];

export type UserInfoType = {
  [K in UserInfoDiccType['selector']]: string;
};

const initialUserInfoState = userInfoDicc.reduce((acc: UserInfoType, item) => {
  acc[item.selector] = '';
  return acc;
}, {});

const SignUpForm: FunctionComponent<{
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  msg: string | null;
  isLoading: boolean;
  userInfo: UserInfoType;
}> = ({ onChange, onSubmit, msg, isLoading, userInfo }) => {
  return (
    <div className={'sign-up-form'}>
      <h1 className={'title'}>Bienvenido</h1>
      <p className={'text-under-title'}>¡Registrate para acceder al resto de funcionalidades!</p>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          {userInfoDicc.map((field, idx) => (
            <div className='from-group-input' key={`field_key_${idx}`}>
              <Label for={field.selector}>{field.name}</Label>
              <Input
                name={field.selector}
                type={field.type}
                placeholder={`Escribir ${field.name}`}
                onChange={onChange}
                value={
                  field.selector === 'nationalId'
                    ? rutValidator(userInfo[field.selector]).formattedValue
                    : userInfo[field.selector]
                }
              />
            </div>
          ))}

          <div className={'submit-button'}>
            {isLoading && <span className='loader'></span>}
            {msg && <Alert color='danger'>{msg}</Alert>}
            <Button disabled={isLoading}>Registrarse</Button>
          </div>
        </FormGroup>
      </Form>
    </div>
  );
};

const SignUpPage: FunctionComponent<{
  auth: AuthState;
  error: ErrorState;
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
  register: (userInfo: UserInfoType) => Promise<void>;
}> = ({ auth, error, register, setPageContentName }) => {
  const [msg, setMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfoType>(initialUserInfoState);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setPageContentName(pagesName.home);
    }
  }, [auth]);

  useEffect(() => {
    if (error) {
      if (error.id === REGISTER_FAIL) {
        setMsg(error.msg.details);
      } else {
        setMsg(null);
      }
    }
  }, [error]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = name === 'nationalId' ? rutValidator(value).formattedValue : value;
    setUserInfo({ ...userInfo, [name]: formattedValue });
  };

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    for (const f of userInfoDicc) {
      if (userInfo[f.selector].trim() === '') {
        setMsg(`El campo ${f.name} es obligatorio`);
        setIsLoading(false);
        return;
      } else if (f.selector === 'nationalId' && !rutValidator(userInfo[f.selector]).isValidRut) {
        setMsg(`El ${f.name} No es válido`);
        setIsLoading(false);
        return;
      } else {
        setMsg(null);
      }
    }
    await register(userInfo);
    setIsLoading(false);
  };

  return (
    <div className='sign-up-page-container'>
      <SignUpForm
        onSubmit={handleOnSubmit}
        onChange={handleOnChange}
        msg={msg}
        isLoading={isLoading}
        userInfo={userInfo}
      />
    </div>
  );
};

export default connect(({ auth, error }: AppState) => ({ auth, error }), { register })(SignUpPage);
