import { User } from '../types';
import { FieldTypes, WebEntity } from './types';
import { booleanFormatFn, dateFormatFn } from '../utils/utils';

const UserEntity: WebEntity<User> = {
  name: 'user',
  endpoint: 'users',
  referenceColumn: 'userUsername', //TODO: make it multicolumn, the utils should have the 'not found' text as optional param
  customButtonNames: {
    addButtonName: 'Agregar Usuario'
  },

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre Usuario',
      selector: 'userUsername',
      sortable: true
    },
    {
      name: 'Nombre',
      selector: 'userFullname',
      wrap: true,
      sortable: true
    },
    {
      name: 'Email',
      selector: 'userEmail',
      wrap: true,
      sortable: true
    },
    {
      name: 'Rut',
      selector: 'nationalId',
      sortable: true,
      wrap: true
    },
    {
      name: 'Nombre Empresa (Fsia)',
      selector: 'businessNickname',
      wrap: true,
      sortable: true
    },
    {
      name: 'Persona Contacto',
      selector: 'contactName',
      wrap: true,
      sortable: true
    },
    {
      name: 'Telefono',
      selector: 'phoneNumber',
      wrap: true,
      sortable: true
    },
    {
      name: 'Direccion Por Defecto',
      selector: 'defaultDeliveryAddress',
      wrap: true,
      sortable: true,
      format: ({ deliveryAddress }) => deliveryAddress?.toString() || ''
    },
    {
      name: 'Giro',
      selector: 'lineOfBusiness',
      sortable: true,
      wrap: true
    },
    {
      name: 'Tipo',
      selector: 'userTypeName',
      sortable: true
    },
    {
      name: '¿Activo?',
      selector: 'userIsActive',
      format: ({ userIsActive }) => booleanFormatFn(userIsActive),
      sortable: true
    },
    {
      name: 'Creado en',
      selector: 'dateCreated',
      format: ({ dateCreated }) => dateFormatFn(dateCreated),
      sortable: true,
      wrap: true
    },
    {
      name: 'Ultima actualizacion',
      selector: 'dateUpdated',
      format: ({ dateUpdated }) => dateFormatFn(dateUpdated),
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'userFullname',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'userUsername',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Email',
      selector: 'userEmail',
      type: FieldTypes.Email,
      required: true
    },
    {
      name: 'Rut',
      selector: 'nationalId',
      type: FieldTypes.Rut,
      required: true
    },
    {
      name: 'Razón Social',
      selector: 'businessNickname',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Nombre Contacto',
      selector: 'contactName',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Telefono',
      selector: 'phoneNumber',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Direccion despacho',
      selector: 'deliveryAddress',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Giro',
      selector: 'lineOfBusiness',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Clave',
      selector: 'userPassword',
      type: FieldTypes.Password,
      required: true
    },
    {
      name: 'Tipo',
      selector: 'userTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'userType',
        endpoint: 'userTypes',
        select: '_id',
        show: 'userTypeName',
        filter: {
          activo: true
        }
      }
    }
  ],

  editableFields: [
    {
      name: 'Nombre',
      selector: 'userFullname',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre de Usuario para login',
      selector: 'userUsername',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Clave',
      selector: 'userPassword',
      type: FieldTypes.Password
    },
    {
      name: 'Email',
      selector: 'userEmail',
      type: FieldTypes.Email
    },
    {
      name: 'Rut',
      selector: 'nationalId',
      type: FieldTypes.Rut,
      required: true
    },
    {
      name: 'Razón Social',
      selector: 'businessNickname',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Nombre Contacto',
      selector: 'contactName',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Telefono',
      selector: 'phoneNumber',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Direccion Por Defecto',
      selector: 'userDefaultDeliveryAddressId',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'deliveryAddress',
        endpoint: 'userDeliveryAddresses/deliveryAddress/reqQuery',
        select: 'userDeliveryAddressId',
        show: 'deliveryAddress',
        endpointQuery: (user) => ({ userId: user._id })
      }
    },
    {
      name: 'Giro',
      selector: 'lineOfBusiness',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: '¿Activo?',
      selector: 'userIsActive',
      type: FieldTypes.Boolean
    },
    {
      name: 'Tipo',
      selector: 'userTypeId',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'userType',
        endpoint: 'userTypes',
        select: '_id',
        show: 'userTypeName',
        filter: {
          activo: true
        }
      }
    }
  ]
};

export default UserEntity;
