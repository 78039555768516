import { Brand } from '../types';
import { FieldTypes, WebEntity } from './types';

const BrandEntity: WebEntity<Brand> = {
  name: 'brand',
  endpoint: 'brands',
  referenceColumn: '_id',
  customButtonNames: {
    addButtonName: 'Agregar Marca'
  },

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre',
      selector: 'brandName',
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'brandName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default BrandEntity;
