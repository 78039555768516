import React, {
  useState,
  useMemo,
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  FunctionComponent
} from 'react';
import { connect } from 'react-redux';
import { requestErrorPopAlert } from '../components/PopAlert';
import { addItemToShoppingCartByBarcode } from '../actions/middleActions';
import { generateCRUD } from '../components/CRUDGenerator';
import {
  RemoveLotDetailsFromCartButton,
  SaleCompletionButton
} from '../components/SecondaryButton';
import * as E from '../entities';
import { AppState } from '../store';
import { EntitiesState } from '../reducers/types';
import { formatStringNumber } from '../utils/utils';
import { Input, Button, Label } from 'semantic-ui-react';

export type ResumeType = {
  itemId: string;
  itemName: string;
  itemQuantityInSaleOrder: number;
  stockOriginName: string;
  saleOrderId: string;
  unitOfMeasurePrice: number;
  totalKgs: number;
  appliedDiscountUnitPrice: number;
  discountPerUnit: number;
  deliveryChargePerUnit: number;
};

export const Subtotals: FunctionComponent<{
  shoppingCartSubtotals: ResumeType[];
}> = ({ shoppingCartSubtotals }) => {
  const calculetotal = shoppingCartSubtotals.reduce(
    (acc, item) => (acc += item.appliedDiscountUnitPrice * item.totalKgs),
    0
  );
  const total = formatStringNumber(calculetotal.toString(), false);
  const iva = formatStringNumber((calculetotal * 0.19).toString(), false);
  const totalPlusIVA = formatStringNumber((calculetotal * 1.19).toString(), false);

  return (
    <div
      style={{
        backgroundColor: '#B6B6B6',
        width: '50vw',
        borderRadius: '10px',
        padding: '10px'
      }}
    >
      <div>
        <p>
          <strong>SUB TOTAL PRODUCTOS:</strong>
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {shoppingCartSubtotals.map((item, idx) => (
            <div key={`item_key_${idx}`}>
              <h5>{item.itemName}</h5>
              <div style={{ display: 'flex', textAlign: 'right' }}>
                <div style={{ display: 'inline-block' }}>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>
                      <strong>Precio (KG):</strong>{' '}
                      {`$${formatStringNumber(item.unitOfMeasurePrice.toString())}`}
                    </li>
                    <li>
                      <strong>Descuento (KG):</strong>{' '}
                      <span style={{ color: '#158120' }}>
                        -{`$${formatStringNumber(item.discountPerUnit.toString())}`}
                      </span>
                    </li>
                    <li>
                      <strong>Recargo Delivery (KG):</strong>{' '}
                      <span style={{ color: '#951400' }}>
                        +{`$${formatStringNumber(item.deliveryChargePerUnit.toString())}`}
                      </span>
                    </li>
                    <hr style={{ margin: '5px 0', border: '1px solid black' }} />
                    <li>
                      <strong>Precio Final(KG):</strong>{' '}
                      {`$${formatStringNumber(item.appliedDiscountUnitPrice.toString())}`}
                    </li>
                    <li>
                      <strong>Kilos Totales:</strong>{' '}
                      {`${formatStringNumber(item.totalKgs.toString(), true)}`}
                    </li>
                    <li>
                      <strong>Cantidad:</strong>{' '}
                      {`${formatStringNumber(item.itemQuantityInSaleOrder.toString())}`} Cajas
                    </li>
                    <li>
                      <strong>Sub total:</strong>
                      {`$${formatStringNumber(
                        (Number(item.appliedDiscountUnitPrice) * Number(item.totalKgs)).toString(),
                        false
                      )}`}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr style={{ margin: '10px 0', border: '1px solid black' }} />
      <div style={{ display: 'flex', float: 'right', fontWeight: 'bold', fontSize: '20px' }}>
        <div style={{ display: 'inline-block' }}>
          <ul style={{ listStyleType: 'none' }}>
            <li>TOTAL :</li>
            <li>IVA:</li>
            <li>TOTAL + IVA:</li>
          </ul>
        </div>
        <div style={{ display: 'inline-block' }}>
          <ul style={{ listStyleType: 'none' }}>
            <li>${total}</li>
            <li>${iva}</li>
            <li>${totalPlusIVA}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const generateShoppingCartItemsDetail = (saleOrderId: string) =>
  generateCRUD({
    webEntity: E.ShoppingCartEntity,
    title: '',
    query: {
      saleOrderId
    },
    viewInputFilter: false,
    additionalTableActions: ({ selected }) => {
      const cartDetailIds = selected.map((item) => item._id);
      return (
        <div>
          <RemoveLotDetailsFromCartButton cartDetailIds={cartDetailIds} />
        </div>
      );
    },
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false,
      select: {
        enable: true,
        rowDisabledCriteria: (item) => item.isForAuction
      }
    }
  });

const replaceColumn = {
  name: 'Unidades Pedidas',
  selector: 'packagingLotCurrentQuantity',
  format: (saleOrder: any) =>
    `${saleOrder?.packagingName}s: ${saleOrder?.itemQuantityInSaleOrder}` || '0',
  sortable: true,
  wrap: true
};

const saleOrderToCompleteEntity = {
  ...E.SaleOrderDetailEntity,
  endpoint: 'trades/saleOrderItemsToComplete',
  tableColumns: E.SaleOrderDetailEntity.tableColumns.map((tc, idx) =>
    idx === 1 ? replaceColumn : tc
  )
};

const generateSaleOrderItemsToComplete = (saleOrderId: string) =>
  generateCRUD({
    webEntity: saleOrderToCompleteEntity,
    title: 'Productos en tu carro',
    viewInputFilter: false,
    query: {
      saleOrderId
    },
    allowedActions: {
      export: false,
      add: false,
      delete: false,
      edit: false,
      select: { enable: false }
    }
  });

const SaleOrderDetailCompletion: React.FC<{
  entities: EntitiesState;
  saleOrderId: string;
  addItemToShoppingCartByBarcode: (barcode: string, saleOrderId: string) => Promise<void>;
}> = ({ entities, saleOrderId, addItemToShoppingCartByBarcode }) => {
  const [barcodeInput, setBarcodeInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [shoppingCartSubtotals, setShoppingCartSubtotals] = useState<ResumeType[]>([]);

  const handleInputChange =
    (callback: (input: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      callback(event.target.value);
    };

  const ShoppingCartItemsDetail = useMemo(
    () => generateShoppingCartItemsDetail(saleOrderId),
    [saleOrderId]
  );

  const SaleOrderItemsToComplete = useMemo(
    () => generateSaleOrderItemsToComplete(saleOrderId),
    [saleOrderId]
  );

  const handleSearch = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      await addItemToShoppingCartByBarcode(barcodeInput, saleOrderId);
      setLoading(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading === false) {
      setBarcodeInput('');
    }
  }, [loading]);

  useEffect(() => {
    const getDiscounts = async () => {
      try {
        setLoading(true);
        const resume: ResumeType[] = [];
        if (entities.shoppingCart.list.length > 0) {
          entities.shoppingCart.list.forEach((shoppingCart) => {
            const existingItemIndex = resume.findIndex(
              (resumeItem) =>
                resumeItem.itemId.toString() === shoppingCart.itemId.toString() &&
                resumeItem.stockOriginName === shoppingCart.stockOriginName
            );

            const appliedDiscountUnitPrice =
              shoppingCart.unitOfMeasurePrice -
              shoppingCart.priceDiscount +
              shoppingCart.unitOfMeasureDeliveryCharge;

            if (existingItemIndex !== -1) {
              resume[existingItemIndex] = {
                ...resume[existingItemIndex],
                itemQuantityInSaleOrder: resume[existingItemIndex].itemQuantityInSaleOrder + 1,
                totalKgs: resume[existingItemIndex].totalKgs + shoppingCart.unitOfMeasureQuantity
              };
            } else {
              resume.push({
                itemId: shoppingCart.itemId,
                itemName: shoppingCart.itemName,
                stockOriginName: shoppingCart.stockOriginName || '',
                itemQuantityInSaleOrder: 1,
                saleOrderId: shoppingCart.saleOrderId,
                unitOfMeasurePrice: shoppingCart.unitOfMeasurePrice,
                totalKgs: shoppingCart.unitOfMeasureQuantity,
                appliedDiscountUnitPrice,
                discountPerUnit: shoppingCart.priceDiscount,
                deliveryChargePerUnit: shoppingCart.unitOfMeasureDeliveryCharge
              });
            }
          });
          setShoppingCartSubtotals(resume);
        } else {
          setShoppingCartSubtotals([]);
        }
        setLoading(false);
      } catch (error) {
        requestErrorPopAlert(error);
        setLoading(false);
      }
    };

    getDiscounts();
  }, [entities.shoppingCart.list, saleOrderId]);

  return (
    <div>
      <SaleOrderItemsToComplete />

      <div style={{ marginTop: '3em', marginLeft: '3em' }}>
        <Label>Barcode:</Label>
        <form onSubmit={handleSearch}>
          <Input type='text' value={barcodeInput} onChange={handleInputChange(setBarcodeInput)} />
          <Button onClick={handleSearch} disabled={loading} loading={loading} color='teal'>
            Agregar
          </Button>
        </form>
      </div>

      <ShoppingCartItemsDetail />

      {loading ? (
        <div
          style={{
            width: '100%',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h2>Calculando...</h2>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '20px'
          }}
        >
          <Subtotals shoppingCartSubtotals={shoppingCartSubtotals} />
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <SaleCompletionButton saleOrderId={saleOrderId} />
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(({ entities }: AppState) => ({ entities }), {
  addItemToShoppingCartByBarcode
})(SaleOrderDetailCompletion);
