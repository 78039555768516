import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react';
import { AppState } from '../store';
import { AuthState, EntitiesState } from '../reducers/types';
import { SaleOrderItems } from '../components/GeneratedComponents';
import { formatStringNumber } from '../utils/utils';
import {
  User,
  UserTypeValues,
  UserDeliveryAddress,
  SaleOrderDetail,
  StockOriginValues
} from '../types';
import {
  AddBuyerToOrderButton,
  PlaceAnOrderButton,
  ConnectedNewAddressForm
} from '../components/SecondaryButton';
import { refreshSaleOrderDetail } from '../actions/middleActions';
import { getUncatchEndpointData } from '../utils/request';
import { requestErrorPopAlert } from '../components/PopAlert';

enum DeliveryTypeValues {
  Despacho = 'Despacho',
  Retiro = 'Retiro'
}

type DiscountRuleType = {
  operator: string;
  operatorValue: string;
  item: {
    itemId: string;
    itemName: string;
    stockOriginName: string;
    packageUnitQuantity: number;
    totalUnitOfMeasureQuantity: number;
    subtotal: number;
  };
  newPricePerUnit: number;
  discountPerUnit: number;
};

type ResumeType = {
  brandName: string;
  countryName: string;
  dateCreated: string;
  itemId: string;
  itemName: string;
  stockOriginName: string;
  itemQuantityInSaleOrder: number;
  packagingName: string;
  saleOrderId: string;
  totalItemQuantity: number;
  unitOfMeasureName: string;
  unitOfMeasurePrice: number;
  unitOfMeasureQuantityAvg: number;
  appliedDiscountUnitPrice: number;
  discountPerUnit: number;
  deliveryChargePerUnit: number;
};

const SaleOrderPage: FunctionComponent<{
  entities: EntitiesState;
  auth: AuthState;
  refreshSaleOrderDetail: () => Promise<void>;
}> = ({ entities, auth, refreshSaleOrderDetail }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listItems, setListItems] = useState<SaleOrderDetail[]>([]);
  const [buyerUser, setBuyerUser] = useState<User | null>(null);
  const [withdrawalChecked, setWithdrawalChecked] = useState(true);
  const [isDispatch, setIsDispatch] = useState(false);
  const [deliveryChecked, setDeliveryChecked] = useState(false);
  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState<boolean>(false);
  const [isAllowedManageDeliveryAddresses, setIsAllowedManageDeliveryAddresses] =
    useState<boolean>(false);
  const [userDeliveryAddress, setUserDeliveryAddress] = useState<UserDeliveryAddress[]>();
  const [defaultDeliveryAddress, setDefaultDeliveryAddress] = useState<string>('');
  const [defaultDeliveryAddressId, setDefaultDeliveryAddressId] = useState<string>('');
  const [dropdownDeliveryAddressOptions, setDropdownDeliveryAddressOptions] =
    useState<{ key: string; value: string; text: string }[]>();
  const [isAllowedPlaceOrder, setIsAllowedPlaceOrder] = useState<boolean>(false);
  const [isNewAddressAdded, setIsNewAddressAdded] = useState<boolean>(false);
  const [minQuantityDelivery, setMinQuantityDelivery] = useState<number>(0);
  const [total, setTotal] = useState<string>('');
  const [iva, setIva] = useState<string>('');
  const [totalPlusIVA, setTotalPlusIVA] = useState<string>('');
  const [shoppingCartSubtotals, setShoppingCartSubtotals] = useState<ResumeType[]>([]);

  // REFRESH IF THE saleOrderDetail list CHANGES
  useEffect(() => {
    setIsLoading(true);
    setListItems(entities.saleOrderDetail.list);
    const itemQuantity = entities.saleOrderDetail.list.reduce(
      (acc, item) => (acc += Number(item.itemQuantityInSaleOrder)),
      0
    );

    if (itemQuantity >= minQuantityDelivery) {
      setIsDeliveryAllowed(true);
    } else {
      setIsDeliveryAllowed(false);
      // If the minimum number of boxes for delivery is not met,
      // force check 'Withdrawal' and uncheck 'Delivery'.
      setWithdrawalChecked(true);
      setDeliveryChecked(false);
    }
    setIsLoading(false);
  }, [entities.saleOrderDetail.list, minQuantityDelivery]);

  // REFRESH IF SHOPPING AND DELIVERY ARE ALLOWED
  useEffect(() => {
    setIsAllowedPlaceOrder(
      !!shoppingCartSubtotals &&
        !isLoading &&
        !!((isDispatch && defaultDeliveryAddress) || !isDispatch) &&
        !(entities.saleOrderDetail.list.length === 0)
    );
    setIsAllowedManageDeliveryAddresses(
      !!shoppingCartSubtotals &&
        !isLoading &&
        entities.saleOrderDetail.list.length > 0 &&
        !(auth.user?.userTypeName !== UserTypeValues.Cliente && !buyerUser)
    );
  }, [
    shoppingCartSubtotals,
    isLoading,
    defaultDeliveryAddress,
    isDispatch,
    entities.saleOrderDetail.list,
    buyerUser
  ]);

  // REFRESH ON ADD NEW DELIVERY ADDRESS
  useEffect(() => {
    const update = async () => {
      const userId =
        auth.user?.userTypeName === UserTypeValues.Cliente
          ? auth.user._id
          : buyerUser
          ? buyerUser._id
          : '';

      if (userId) {
        const getDeliveryAddresses = await getUncatchEndpointData({
          endpoint: 'userDeliveryAddresses/deliveryAddress/reqQuery',
          query: { userId }
        });
        setUserDeliveryAddress(getDeliveryAddresses);
      } else {
        setUserDeliveryAddress([]);
      }
    };
    update();
  }, [auth.user, isNewAddressAdded, buyerUser]);

  // REFRESH ON DELIVERY ADDRESS SELECTION
  useEffect(() => {
    if (userDeliveryAddress) {
      const defaultAddress = userDeliveryAddress.filter((item) => item.defaultDeliveryAddress);
      if (defaultAddress.length > 0) {
        setDefaultDeliveryAddress(defaultAddress[0].deliveryAddress);
        setDefaultDeliveryAddressId(defaultAddress[0].userDeliveryAddressId);
      } else {
        setDefaultDeliveryAddress('');
        setDefaultDeliveryAddressId('');
      }
    }

    const dropdownOptions = userDeliveryAddress?.map((opt, idx) => ({
      key: `delivery_address_${idx}`,
      value: opt.deliveryAddress,
      text: opt.deliveryAddress
    }));
    setDropdownDeliveryAddressOptions(dropdownOptions);
  }, [userDeliveryAddress, buyerUser]);

  // REFRESH IF listItems OR deliveryChecked CHANGES
  useEffect(() => {
    try {
      const calculate = async () => {
        setIsLoading(true);
        const { resume, deliveryRules } = await calculateResumeBaseOnSaleOrderDetail(listItems);
        setMinQuantityDelivery(deliveryRules.minQuantityDelivery);
        // Set subtotals
        if (resume) {
          setShoppingCartSubtotals(resume);
        } else {
          setShoppingCartSubtotals([]);
          setBuyerUser(null);
        }
        setIsLoading(false);
      };
      calculate();
    } catch (error) {
      requestErrorPopAlert(error);
      setIsLoading(false);
    }
  }, [listItems, deliveryChecked]);

  // REFRESH IF shoppingCartSubtotals CHANGES
  useEffect(() => {
    if (shoppingCartSubtotals.length > 0) {
      const calculetotal = shoppingCartSubtotals.reduce(
        (acc, item) =>
          (acc +=
            Number(item.appliedDiscountUnitPrice) *
            Number(item.unitOfMeasureQuantityAvg) *
            Number(item.itemQuantityInSaleOrder)),
        0
      );
      setTotal(formatStringNumber(calculetotal.toString(), false));
      setIva(formatStringNumber((calculetotal * 0.19).toString(), false));
      setTotalPlusIVA(formatStringNumber((calculetotal * 1.19).toString(), false));
    }
  }, [shoppingCartSubtotals]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === DeliveryTypeValues.Retiro) {
      setWithdrawalChecked(checked);
      setIsDispatch(false);
      setDeliveryChecked(!checked);
    } else if (name === DeliveryTypeValues.Despacho && isDeliveryAllowed) {
      setIsDispatch(true);
      setWithdrawalChecked(!checked);
      setDeliveryChecked(checked);
    }
  };

  const onChangeDropdownDeliveryAddress = (
    _: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => setDefaultDeliveryAddress(data.value as string);

  const calculateResumeBaseOnSaleOrderDetail = async (listItems: SaleOrderDetail[]) => {
    setIsLoading(true);
    if (!listItems || listItems.length === 0) {
      return { resume: [], deliveryRules: { minQuantityDelivery: 0 } };
    }

    // rules/calcDiscount/cart/:idCart
    const {
      discountsPerItem,
      deliveryChargePerKg,
      discountForPickupPriceListItemPerKg,
      minQuantityToApplyPickupDiscount,
      minQuantityDelivery
    } = (await getUncatchEndpointData({
      endpoint: `rules/calcDiscount/saleOrder/${listItems[0].saleOrderId}`
    })) as {
      deliveryChargePerKg: number;
      discountForPickupPriceListItemPerKg: number;
      minQuantityToApplyPickupDiscount: number;
      discountsPerItem: DiscountRuleType[];
      minQuantityDelivery: number;
    };

    const resume: Array<ResumeType> = [];

    // COUNT SUBTOTALS
    for (const item of listItems) {
      const discount =
        discountsPerItem?.find(
          (d) =>
            d.item.itemId.toString() === item.itemId.toString() &&
            d.item.stockOriginName === item.stockOriginName
        ) || null;

      let deliveryChargePerUnit = 0;
      if (isDispatch && deliveryChargePerKg) {
        deliveryChargePerUnit = deliveryChargePerKg;
      } else if (
        !isDispatch &&
        discountForPickupPriceListItemPerKg &&
        item.stockOriginName === StockOriginValues.Estandar &&
        item.itemQuantityInSaleOrder >= minQuantityToApplyPickupDiscount
      ) {
        deliveryChargePerUnit = discountForPickupPriceListItemPerKg * -1;
      }

      const appliedDiscountUnitPrice =
        (discount ? discount.newPricePerUnit : Number(item.unitOfMeasurePrice)) +
        deliveryChargePerUnit;

      // Resume is grouped by Item
      const currentItem = {
        brandName: item.brandName,
        countryName: item.countryName,
        dateCreated: item.dateCreated,
        itemId: item.itemId,
        itemName: item.itemName,
        stockOriginName: item.stockOriginName || '',
        itemQuantityInSaleOrder: item.itemQuantityInSaleOrder,
        packagingName: item.packagingName,
        saleOrderId: item.saleOrderId,
        totalItemQuantity: item.totalItemQuantity,
        unitOfMeasureName: item.unitOfMeasureName,
        unitOfMeasurePrice: item.unitOfMeasurePrice,
        unitOfMeasureQuantityAvg: item.unitOfMeasureQuantityAvg,
        appliedDiscountUnitPrice,
        discountPerUnit: discount?.discountPerUnit || 0,
        deliveryChargePerUnit
      };
      resume.push(currentItem);
    }
    setIsLoading(false);
    return { resume, deliveryRules: { minQuantityDelivery } };
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        backgroundColor: '#fff'
      }}
    >
      <SaleOrderItems />
      {isLoading && <h2 style={{ textAlign: 'center' }}>Calculando...</h2>}
      {shoppingCartSubtotals.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div
            style={{
              backgroundColor: '#B6B6B6',
              width: '50vw',
              borderRadius: '10px',
              padding: '10px'
            }}
          >
            <div>
              <p>
                <strong>SUB TOTAL ESTIMADO:</strong>
              </p>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                {shoppingCartSubtotals.map((item, idx) => (
                  <div key={`item_key_${idx}`}>
                    <h5>
                      {item.itemName} ({item.brandName}) [{item.stockOriginName}]
                    </h5>
                    <div style={{ display: 'flex', textAlign: 'right' }}>
                      <div style={{ display: 'inline-block' }}>
                        <ul style={{ listStyleType: 'none' }}>
                          <li>
                            <strong>Precio (KG):</strong>{' '}
                            {`$${formatStringNumber(item.unitOfMeasurePrice.toString())}`}
                          </li>
                          <li>
                            <strong>Descuento (KG):</strong>{' '}
                            <span style={{ color: '#158120' }}>
                              {`$${formatStringNumber(item.discountPerUnit.toString())}`}
                            </span>
                          </li>
                          <li>
                            <strong>Recargo Delivery (KG):</strong>{' '}
                            <span style={{ color: '#951400' }}>
                              {`$${formatStringNumber(item.deliveryChargePerUnit.toString())}`}
                            </span>
                          </li>
                          <hr style={{ margin: '5px 0', border: '1px solid black' }} />
                          <li>
                            <strong>Precio Final(KG):</strong>{' '}
                            {`$${formatStringNumber(item.appliedDiscountUnitPrice.toString())}`}
                          </li>
                          <li>
                            <strong>Prom Caja:</strong>{' '}
                            {`${formatStringNumber(
                              item.unitOfMeasureQuantityAvg.toString(),
                              true,
                              2,
                              2
                            )}`}{' '}
                            Kgs
                          </li>
                          <li>
                            <strong>Cantidad:</strong>{' '}
                            {`${formatStringNumber(item.itemQuantityInSaleOrder.toString())}`} Cajas
                          </li>
                          <li>
                            <strong>Sub total:</strong>
                            {`$${formatStringNumber(
                              (
                                Number(item.appliedDiscountUnitPrice) *
                                Number(item.unitOfMeasureQuantityAvg) *
                                Number(item.itemQuantityInSaleOrder)
                              ).toString(),
                              false
                            )}`}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr style={{ margin: '10px 0', border: '1px solid black' }} />
            <div style={{ display: 'flex', float: 'right', fontWeight: 'bold', fontSize: '20px' }}>
              <div style={{ display: 'inline-block' }}>
                <ul style={{ listStyleType: 'none' }}>
                  <li>TOTAL :</li>
                  <li>IVA:</li>
                  <li>TOTAL + IVA:</li>
                </ul>
              </div>
              <div style={{ display: 'inline-block' }}>
                <ul style={{ listStyleType: 'none' }}>
                  <li>${total}</li>
                  <li>${iva}</li>
                  <li>${totalPlusIVA}</li>
                </ul>
              </div>
            </div>
            <div style={{ display: 'left', float: 'left', fontWeight: 'bold', fontSize: '10px' }}>
              **El precio estimado se calcula utilizando el promedio de kilos por caja. El precio
              final puede variar dependiendo del peso efectivo de cada caja comprada.
            </div>
          </div>
          <div
            style={{
              display: ' flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '35vw',
              padding: 20
            }}
          >
            {buyerUser && (
              <div style={{ display: 'inline' }}>
                <h3>Comprador: {buyerUser.userFullname}</h3>
                <Button
                  onClick={() => {
                    setBuyerUser(null);
                    setWithdrawalChecked(true);
                    setDeliveryChecked(false);
                    setIsDispatch(false);
                  }}
                  disabled={!isAllowedPlaceOrder}
                >
                  Quitar
                </Button>
              </div>
            )}

            <h3>Tipo de despacho</h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                alignItems: 'flex-start'
              }}
            >
              <label>
                <input
                  type='radio'
                  name={DeliveryTypeValues.Retiro}
                  checked={withdrawalChecked}
                  onChange={handleRadioChange}
                  disabled={!isAllowedManageDeliveryAddresses}
                />
                Retiro
              </label>
              <label>
                <input
                  type='radio'
                  name={DeliveryTypeValues.Despacho}
                  checked={deliveryChecked}
                  onChange={handleRadioChange}
                  disabled={!isDeliveryAllowed || !isAllowedManageDeliveryAddresses}
                />
                Despacho
              </label>
              {!isDeliveryAllowed && (
                <p>
                  <strong>
                    Se requieren {minQuantityDelivery} o más unidades para habilitar el Despacho
                  </strong>
                </p>
              )}
              {deliveryChecked && isDispatch && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10
                  }}
                >
                  Dirección:
                  <Dropdown
                    {...{
                      className: 'mb-3',
                      placeholder: 'Dirección',
                      fluid: true,
                      lazyLoad: true,
                      search: true,
                      selection: true,
                      value: defaultDeliveryAddress,
                      options: dropdownDeliveryAddressOptions,
                      onChange: onChangeDropdownDeliveryAddress,
                      disabled: !isAllowedManageDeliveryAddresses
                    }}
                  />
                  <ConnectedNewAddressForm
                    user={buyerUser ? buyerUser : auth.user!}
                    requestFrom={'Profile'}
                    btnName='Agregar nueva dirección'
                    callback={() => setIsNewAddressAdded(!isNewAddressAdded)}
                    forceDefaultAddress={true}
                    disabled={!isAllowedManageDeliveryAddresses}
                  />
                  <p>
                    Nota:
                    <strong>El despacho agrega un cargo adicional a cada Kg</strong>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: ' flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '15vw',
              padding: 20
            }}
          >
            {auth.user?.userTypeName === UserTypeValues.Cliente || buyerUser ? (
              <PlaceAnOrderButton
                resumeSaleOrder={{
                  isDispatch,
                  deliveryAddressId: !isDispatch ? undefined : defaultDeliveryAddressId,
                  saleOrderDate: new Date().toISOString(),
                  buyerUserId: buyerUser ? buyerUser._id : auth.user!._id
                }}
                disabled={!isAllowedPlaceOrder}
                refreshSaleOrderDetail={refreshSaleOrderDetail}
                clientSaleOrderDetailItems={entities.saleOrderDetail.list}
              />
            ) : (
              <AddBuyerToOrderButton disabled={!isAllowedPlaceOrder} setBuyerUser={setBuyerUser} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(({ auth, entities }: AppState) => ({ auth, entities }), {
  refreshSaleOrderDetail
})(SaleOrderPage);
