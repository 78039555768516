import React, { FunctionComponent, useEffect, useState } from 'react';
import { PriceListBolsaCRUD } from '../components/GeneratedComponents';
import * as E from '../entities';
import { generateCRUD } from '../components/CRUDGenerator';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { EntitiesState } from '../reducers/types';
import { ExportColumns, TableColumn, WebEntity } from '../entities/types';
import { PriceListEstandar, SimpleObject } from '../types';
import PDFpriceList from '../components/PDFpriceList';
import { formatStringNumber } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { requestErrorPopAlert } from '../components/PopAlert';

const PriceListAdminPage: FunctionComponent<{ entities: EntitiesState }> = ({ entities }) => {
  enum PriceListOptionValues {
    OnlyAvailableStockInStore = 'onlyAvailableStockInStore',
    AllAvailableStock = 'allAvailableStock',
    Everything = 'everything'
  }
  type PriceListOptionType = (typeof PriceListOptionValues)[keyof typeof PriceListOptionValues];

  const [tableColumns, setTableColumns] = useState<TableColumn<PriceListEstandar>[]>([]);
  const [exportColumns, setExportColumns] = useState<ExportColumns[]>([]);
  const [shouldRenderPriceListEstandar, setShouldRenderPriceListEstandar] =
    useState<boolean>(false);
  const [priceListEstandar, setPriceListEstandar] = useState<SimpleObject[]>([]);
  const [priceListOption, setPriceListOption] = React.useState<PriceListOptionType>(PriceListOptionValues.Everything);

  const update = async (priceListOption: PriceListOptionType) => {
    try {
      const data = await getUncatchEndpointData({ endpoint: 'priceLists/estandar', query: { priceListOption } });
      setPriceListEstandar(data);
    } catch (error) {
      requestErrorPopAlert(error);
    }
  };

  const handleChangeFormGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = (event.target as HTMLInputElement).value;
    if (selectedOption) {
      setPriceListOption(selectedOption as PriceListOptionType);
      update(priceListOption);
      console.log(priceListOption);
    }
  };

  useEffect(() => {
    update(priceListOption);
  }, []);

  useEffect(() => {
    if (priceListEstandar.length > 0) {
      const data = priceListEstandar;
      const keys: string[] = [];
      for (const row of data) {
        const rowKeys = Object.keys(row);

        for (const key of rowKeys) {
          if (!keys.includes(key)) {
            keys.push(key);
          }
        }
      }

      const updatedColumns = [...E.PriceListEstandarEntity.tableColumns];
      const updatedExportColumns = [...E.PriceListEstandarEntity.exportColumns!];

      keys
        .filter((key) => key !== 'itemName' && key !== '_id')
        .sort()
        .forEach((key) => {
          const cleanName = key.replace('_4', '');
          if (!updatedColumns.find((col) => col.name.includes(cleanName))) {
            updatedColumns.push({
              name: `${cleanName} (4+)`,
              selector: `${key}_4`,
              format: (item: SimpleObject) =>
                item[`${key}_4`] ? `${formatStringNumber(item[`${key}_4`], false)}` : '',
              wrap: true
            });
            updatedColumns.push({
              name: `${cleanName} (1-3)`,
              selector: key,
              format: (item: SimpleObject) =>
                item[key] ? `${formatStringNumber(item[key], false)}` : '',
              wrap: true
            });
            updatedExportColumns.push({
              name: `${cleanName} (4+)`,
              selector: `${key}_4`,
              format: 'numeric',
              decimalSeparator: ',',
              thousandSeparator: '.'
            });
            updatedExportColumns.push({
              name: `${cleanName} (1-3)`,
              selector: key,
              format: 'numeric',
              decimalSeparator: ',',
              thousandSeparator: '.'
            });
          }
        });

      setTableColumns(updatedColumns);
      setExportColumns(updatedExportColumns);
      setShouldRenderPriceListEstandar(true);
    }
  }, [priceListEstandar]);

  const [PriceListEstandarComponent, setPriceListEstandarComponent] =
    useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const CustomEntity = {
      ...E.PriceListEstandarEntity,
      tableColumns,
      exportColumns
    };

    if (shouldRenderPriceListEstandar) {
      setPriceListEstandarComponent(
        generateCRUD({
          webEntity: CustomEntity as WebEntity<PriceListEstandar>,
          title: 'Lista de precios Estándar',
          query: { priceListOption },
          allowedActions: {
            export: true,
            add: false,
            delete: false,
            edit: false,
            select: { enable: false }
          }
        })
      );
    }
  }, [tableColumns, exportColumns]);

  return (
    <>
      <div style={{ marginLeft: '1em', marginTop: '1em' }}>
        <PDFpriceList />
        <h3>Items a mostrar</h3>
        <RadioGroup
          row
          aria-labelledby='radio-buttons-group-label'
          name='radio-buttons-group'
          value={priceListOption}
          onChange={handleChangeFormGroup}
        >
          <FormControlLabel
            value={PriceListOptionValues.Everything}
            control={<Radio />}
            label={'Todos'}
          />
          <FormControlLabel
            value={PriceListOptionValues.OnlyAvailableStockInStore}
            control={<Radio />}
            label={'Con stock en tienda'}
          />
          <FormControlLabel
            value={PriceListOptionValues.AllAvailableStock}
            control={<Radio />}
            label={'Con stock disponible'}
          />
        </RadioGroup>
      </div>

      {PriceListEstandarComponent && <PriceListEstandarComponent />}
      <PriceListBolsaCRUD />
    </>
  );
};

export default connect(({ entities }: AppState) => ({ entities }))(PriceListAdminPage);
