import React, { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { CartSearchCRUD, CartSearchDependentCRUD } from '../components/GeneratedComponents';
import GenericInfoTag, { InfoTagSizeValues } from '../components/generics/GenericInfoTag';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import { requestErrorPopAlert } from '../components/PopAlert';
import { fetchCartSearch, fetchCartDetail } from '../actions/middleActions';
import { connect } from 'react-redux';
import { AppState } from '../store';
import * as E from '../entities';
//import { AdvancedUnlotButton } from '../components/SecondaryButton';

const ConnectedRefreshCartSearchDependent = connect(
  ({ entities: { cartSearch } }: AppState) => ({
    state: cartSearch,
    entityName: E.CartSearchDependentEntity.name
  }),
  {
    fetchFunction: fetchCartDetail
  }
)(RefresherComponent);

const LotIdSearchForm: FunctionComponent<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [lotInput, setLotInput] = useState('');
  const [search, setSearch] = useState(false);

  const RefresherComponentCartSearch: FunctionComponent<{
    lotId: string;
    fetchCartSearch: any;
  }> = ({ lotId, fetchCartSearch }) => {
    useEffect(() => {
      const update = async () => {
        if (search && lotId) {
          await fetchCartSearch(lotId, null);
        }
      };

      update();
      setSearch(false);
    }, [search]);

    return <></>;
  };

  const ConnectedRefreshCartSearchPage = connect(
    () => ({
      lotId: lotInput
    }),
    {
      fetchCartSearch
    }
  )(RefresherComponentCartSearch);

  const handleInputChange =
    (callback: (input: string) => void) => (event: ChangeEvent<HTMLInputElement>) =>
      callback(event.target.value);

  const handleSearch = async () => {
    try {
      setLoading(true);
      setSearch(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setLoading(false);
    }
  };
  return (
    <>
      <GenericInfoTag
        icon='info'
        title={
          'El desloteo avanzado elimina todo registro de los carros donde este presente el lote. Por lo que es importante que revises y Canceles/Expires los que existan.'
        }
        strongTitle='Importante!'
        typeInfo={InfoTagSizeValues.bg}
      />
      <ConnectedRefreshCartSearchPage />
      <div style={{}}>
        {loading ? (
          <b>cargando...</b>
        ) : (
          <div>
            <label>Numero de lote:</label>
            <input type='text' value={lotInput} onChange={handleInputChange(setLotInput)} />
            <button onClick={handleSearch}>Buscar</button>
          </div>
        )}
        {/*<AdvancedUnlotButton lotId={lotInput} /> */}
      </div>
    </>
  );
};

const AdvancedUnlotPage: FunctionComponent<{}> = () => {
  return (
    <div>
      <div>
        <ConnectedRefreshCartSearchDependent />
        <LotIdSearchForm />
        <CartSearchCRUD />
        <CartSearchDependentCRUD />
      </div>
    </div>
  );
};

export default AdvancedUnlotPage;
