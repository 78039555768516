import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { AppState } from '../store';
import {
  LotForAuctionCRUD,
  //LotDetailDependentCRUD,
  OutOfDateLotForAuctionCRUD
} from '../components/GeneratedComponents';
/*import { fetchLotDetail } from '../actions/middleActions';
import { RefresherComponent } from '../components/generics/GenericRefresher';
import * as E from '../entities';

const ConnectedRefreshLot = connect(
  ({ entities: { lot } }: AppState) => ({
    state: lot,
    entityName: E.LotDetailEntity.name
  }),
  {
    fetchFunction: fetchLotDetail
  }
)(RefresherComponent);*/

const LotStockAdminPage: FunctionComponent = () => {
  useEffect(() => {}, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Tab
        menu={{ color: 'red', pointing: true }}
        panes={[
          {
            menuItem: {
              key: 'auctionAdmin',
              icon: 'list alternate',
              content: 'Administración Subastas'
            },
            render: () => (
              <Tab.Pane attached={true}>
                {/*<ConnectedRefreshLot />*/}
                <LotForAuctionCRUD />
                {/*<LotDetailDependentCRUD />*/}
              </Tab.Pane>
            )
          },
          {
            menuItem: {
              key: 'outOfAuctionDateAdmin',
              icon: 'list alternate',
              content: 'Subastas fuera de fecha'
            },
            render: () => (
              <Tab.Pane attached={true}>
                {/*<ConnectedRefreshLot />*/}
                <OutOfDateLotForAuctionCRUD />
                {/*<LotDetailDependentCRUD />*/}
              </Tab.Pane>
            )
          }
        ]}
      />
    </div>
  );
};

export default connect(
  ({ auth, entities }: AppState) => ({ auth, entities }),
  null
)(LotStockAdminPage);
