import { ItemStock } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const ShoppingCartItemsEntity: WebEntity<ItemStock> = {
  name: 'shoppingCartItems',
  endpoint: 'trades/shoppingCartItems',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Unidades en carro / Cantidad disponible',
      selector: 'packagingLotCurrentQuantity',
      format: ({ totalItemQuantity, totalQuantityOnCart, packagingName }) =>
        `${packagingName}s: ${totalQuantityOnCart} de ${Number.parseInt(totalItemQuantity || '0')}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Producto',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Empaque',
      selector: 'packagingName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad de Medida',
      selector: 'unitOfMeasureName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio (Kg)',
      selector: 'lotPrice',
      format: ({ unitOfMeasurePriceOnCart }) =>
        `$${formatStringNumber((unitOfMeasurePriceOnCart || '0').toString())}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Precio Total',
      selector: 'totalQuantityOnCart',
      format: ({ totalQuantityOnCart, unitOfMeasurePriceOnCart, unitOfMeasureQuantityAvg }) =>
        `$${formatStringNumber(
          (
            Number(totalQuantityOnCart || '0') *
            Number(unitOfMeasurePriceOnCart || '0') *
            Number(unitOfMeasureQuantityAvg || '0')
          ).toString()
        )}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Tipo compra',
      selector: 'productionDate',
      //format: (lot) => (lot.isForAuction ? 'subasta' : 'normal'),
      format: (_) => 'normal',
      sortable: true,
      wrap: true
    }
    // {
    //   name: 'Fecha Elaboración',
    //   selector: 'productionDate',
    //   format: ({ productionDate }) => dateFormatFn(productionDate, 'DD/MM/YYYY', true),
    //   sortable: true,
    //   wrap: true
    // },
    // {
    //   name: 'Fecha Expiración',
    //   selector: 'expirationDate',
    //   format: ({ expirationDate }) => dateFormatFn(expirationDate, 'DD/MM/YYYY', true),
    //   sortable: true,
    //   wrap: true
    // },
  ],

  fields: [],
  editableFields: []
};

export default ShoppingCartItemsEntity;
