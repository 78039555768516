import React, { FunctionComponent, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Icon, Modal, SemanticCOLORS } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import GenericIcons from './GenericIcons';

type Props = {
  title?: string;
  buttonName?: string;
  buttonIcon?: SemanticICONS;
  openHook?: () => void;
  closeHook?: () => void;
  sizeModal?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  autoOpen?: number; // NOTE: A random number, such as Date.now()
  localIcon?: boolean;
  style?: {};
  modelstyle?: {};
  icon?: string;
  disabled?: boolean;
  btnColor?: SemanticCOLORS;
  openModal?: boolean;
};

const GenericModal: FunctionComponent<Props> = ({
  title = '',
  buttonName = '',
  buttonIcon,
  openHook = () => {},
  closeHook = () => {},
  autoOpen,
  sizeModal = 'small',
  children,
  localIcon,
  style,
  modelstyle,
  icon,
  disabled = false,
  btnColor = 'green',
  openModal
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (openModal !== undefined) {
      setOpen(openModal);
    }
  }, [openModal]);

  const triggerButton = React.useMemo(
    () => (
      <Button
        style={style}
        color={btnColor}
        onClick={() => {
          setOpen(true);
          openHook();
        }}
        disabled={disabled}>
        {localIcon ? (
          <GenericIcons icon={icon || ''} />
        ) : buttonIcon ? (
          <Icon name={buttonIcon} fitted />
        ) : null}
        {buttonName}
      </Button>
    ),
    [buttonIcon, buttonName, style, icon, openHook]
  );

  React.useEffect(() => {
    if (autoOpen) {
      setOpen(true);
    }
  }, [autoOpen]);

  const onClose = React.useMemo(
    () => () => {
      setOpen(false);
      closeHook();
    },
    [closeHook]
  );

  const Styles = {
    default: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      overflow: 'visible'
    }
  };
  return (
    <Modal
      //dimmer={'blurring'}
      closeIcon
      open={open}
      size={sizeModal}
      style={modelstyle ? modelstyle : Styles.default}
      onClose={onClose}
      trigger={autoOpen ? undefined : triggerButton}>
      {title ? <Modal.Header>{title}</Modal.Header> : null}
      <Modal.Content>
        <Modal.Description>
          {typeof children === 'function' ? children(onClose) : children}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default GenericModal;
