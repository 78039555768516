import { Element } from '../types';
import { WebEntity } from './types';

const ElementEntity: WebEntity<Element> = {
  name: 'element',
  endpoint: 'elements',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre',
      selector: 'elementName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Imagen',
      selector: 'elementImg',
      omit: true
    }
  ],

  fields: [],

  editableFields: []
};

export default ElementEntity;
