import React, { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { BarcodeSearchCRUD } from '../components/GeneratedComponents';
import { requestErrorPopAlert } from '../components/PopAlert';
import { fetchBarcodeSearch } from '../actions/middleActions';
import { connect } from 'react-redux';

const BarcodeSearchPage: FunctionComponent<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [barcodeInput, setBarcodeInput] = useState('');
  const [search, setSearch] = useState(false);

  const RefresherComponentBarcodeSearch: FunctionComponent<{
    barcode: string;
    fetchBarcodeSearch: any;
  }> = ({ barcode, fetchBarcodeSearch }) => {
    useEffect(() => {
      const update = async () => {
        if (search && barcode) {
          await fetchBarcodeSearch(barcode, null);
        }
      };

      update();
      setSearch(false);
    }, [search]);

    return <></>;
  };

  const ConnectedRefreshBarcodeSearchPage = connect(
    () => ({
      barcode: barcodeInput
    }),
    {
      fetchBarcodeSearch
    }
  )(RefresherComponentBarcodeSearch);

  const handleInputChange =
    (callback: (input: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      callback(event.target.value);
    };

  const handleSearch = async () => {
    try {
      setLoading(true);
      setSearch(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setLoading(false);
    }
  };
  return (
    <div>
      <h1>Buscador Barcode</h1>

      {loading ? (
        <b>cargando...</b>
      ) : (
        <div style={{ marginTop: '3em', marginLeft: '3em' }}>
          <label>Barcode:</label>
          <input type='text' value={barcodeInput} onChange={handleInputChange(setBarcodeInput)} />
          <button onClick={handleSearch}>Buscar</button>
        </div>
      )}
      <div>
        <ConnectedRefreshBarcodeSearchPage />
        <BarcodeSearchCRUD />
      </div>
    </div>
  );
};

export default BarcodeSearchPage;
