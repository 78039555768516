import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import '../css/navBar.css';
import GenericIcons from './generics/GenericIcons';
import { pagesName } from '../pages/AnonimousPage';
import { Cart } from '../pages/TradePage';
import { Socket } from 'socket.io-client';
import { Link } from 'react-router-dom';
import Logout from './auth/Logout';
import { protoAdminTree, protoOperadorTree } from './AppNavbar';

interface DropDownProps {
  auth: AuthState;
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
}

const UserIconComponent: FunctionComponent<DropDownProps> = ({ auth, setPageContentName }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onClickDropdownItem = (page: string) => {
    setIsOpen(false);
    setPageContentName(page);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={'nav-bar-user'}>
      {auth.isAuthenticated ? (
        <div onClick={() => setIsOpen(!isOpen)}>
          <GenericIcons icon={'userIconWhite'} />
        </div>
      ) : (
        <div className='login-sign-up-container'>
          <div className='log-in' onClick={() => setPageContentName(pagesName.login)}>
            <p>Log In</p>
          </div>
          <div className='sign-up' onClick={() => setPageContentName(pagesName.signUp)}>
            <p>Sign Up</p>
          </div>
        </div>
      )}

      {auth.isAuthenticated && isOpen ? (
        <>
          <div className={'dropdown-top-arrow'}>
            <GenericIcons icon={'topArrowDropdownIcon'} />
          </div>

          <div className={'dropdown-content'}>
            <a onClick={() => onClickDropdownItem(pagesName.profile)}>Mi cuenta</a>
            <Logout setPageContentName={setPageContentName} />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

interface IProps {
  auth: AuthState;
  pageContentName: string;
  setPageContentName: React.Dispatch<React.SetStateAction<string>>;
  refreshShoppingCart: () => Promise<void>;
  socket: Socket | null;
}

const NavBar: FunctionComponent<IProps> = ({
  auth,
  pageContentName,
  setPageContentName,
  refreshShoppingCart,
  socket
}) => {
  return (
    <div className='nav-bar'>
      <div className='nav-bar-left'>
        <div className='title' onClick={() => setPageContentName(pagesName.home)}>
          <h1>
            The<span>Trader</span>
          </h1>
        </div>

        <div className='nav-bar-items'>
          {auth.isAuthenticated ? (
            <>
              <div className='item' onClick={() => setPageContentName(pagesName.home)}>
                <p className={`${pageContentName === pagesName.home ? 'item-active' : ''}`}>Home</p>
              </div>
              {/* <div className='item' onClick={() => setPageContentName(pagesName.auction)}>
                <p className={`${pageContentName === pagesName.auction ? 'item-active' : ''}`}>
                  Subasta
                </p>
              </div> */}
              <div className='item' onClick={() => setPageContentName(pagesName.trade)}>
                <p className={`${pageContentName === pagesName.trade ? 'item-active' : ''}`}>
                  Comprar
                </p>
              </div>
            </>
          ) : (
            <>
              <div className='item' onClick={() => setPageContentName(pagesName.home)}>
                <p className={`${pageContentName === pagesName.home ? 'item-active' : ''}`}>Home</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='nav-bar-right'>
        {auth.isAuthenticated && (
          <Cart
            refreshShoppingCart={refreshShoppingCart}
            socket={socket}
            onClick={() => setPageContentName(pagesName.shoppingCart)}
          />
        )}

        {['ADMIN', 'OPERADOR'].includes(auth.user?.userTypeName!) && (
          <div>
            {auth.user?.userTypeName === 'ADMIN' ? (
              <Link to={protoAdminTree[0].href || '/control-panel/home'}>
                <GenericIcons icon={'engranajeIcon'} imageStyle={{ width: 26, height: 26 }} />
              </Link>
            ) : auth.user?.userTypeName === 'OPERADOR' ? (
              <Link to={protoOperadorTree[0].href || '/control-panel/price-list'}>
                <GenericIcons icon={'engranajeIcon'} imageStyle={{ width: 26, height: 26 }} />
              </Link>
            ) : (
              ''
            )}
          </div>
        )}

        <UserIconComponent auth={auth} setPageContentName={setPageContentName} />
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(NavBar);
