import React, { FunctionComponent } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { formatStringNumber, dateFormatFn } from '../utils/utils';

interface Item {
  barcode: string;
  stockOriginName: string;
  itemName: string;
  brandName: string;
  expirationDate: string;
  itemTotalKgs: number;
  itemUnitOfMeasurePrice: number;
  itemSubtotal: number;
}

interface Purchase {
  cartOrderDate: string;
  buyerFullname: string;
  requesterFullname: string;
  deliveryAddress: string;
  terminosDePago: string;
  giro: string;
  cartId: string | number;
  isDispatch: boolean;
  cartTotalPrice: string;
  taxPrice: string;
  totalNetPrice: string;
}

export interface BillProps {
  purchaseData: Purchase;
  items: Item[];
  buyerUserId?: string;
}

export const generateBill = async ({ purchaseData, items }: BillProps) => {
  const pdfDoc = await PDFDocument.create();
  const HelveticaObliqueFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
  const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const quantityItemsFirstPage = 30;
  const itemsPerPageQuantity = 40;
  //NOTE: (itemsPerPageQuantity - quantityItemsFirstPage) is to include the difference
  //between the first page and the rest when calculating the total number of pages.
  const pagesQuantity =
    items.length > quantityItemsFirstPage
      ? Math.ceil(
          (items.length + (itemsPerPageQuantity - quantityItemsFirstPage)) / itemsPerPageQuantity
        )
      : 1;

  let currentInitialItem = 0;
  let currentIFinalItem = quantityItemsFirstPage;

  for (let pageNum = 0; pageNum < pagesQuantity; pageNum++) {
    const isFirstPage = pageNum === 0;
    const page = pdfDoc.addPage();
    let firstItemPosY = 760;
    const itemsForThisPage = items.slice(currentInitialItem, currentIFinalItem);
    currentInitialItem = currentIFinalItem;
    currentIFinalItem = currentIFinalItem + itemsPerPageQuantity;
    if (isFirstPage) {
      firstItemPosY = 600;
      // DATOS EMPRESA Izquierda
      page.drawText('The Trader', {
        x: 30,
        y: 790,
        size: 10,
        color: rgb(0, 0, 0),
        font: HelveticaBold
      });
      page.drawText('Reserva - Cotización', {
        x: 30,
        y: 775,
        size: 10,
        color: rgb(0, 0, 0),
        font: HelveticaObliqueFont
      });
      page.drawText('Direccion', {
        x: 30,
        y: 760,
        size: 10,
        color: rgb(0, 0, 0)
      });

      // DATOS EMPRESA Derecha
      page.drawText('RUT: ', {
        x: 420,
        y: 800,
        size: 10,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText('ORDEN DE VENTA', {
        x: 420,
        y: 780,
        size: 10,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`Nº: ${purchaseData.cartId}`, {
        x: 420,
        y: 760,
        size: 10,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText('  ', {
        x: 420,
        y: 740,
        size: 10,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawRectangle({
        x: 380,
        y: 750,
        width: 190,
        height: 70,
        borderColor: rgb(0, 0, 0),
        borderWidth: 0.5
      });

      // Datos Comprador Izquirda
      page.drawText('Fecha:', {
        x: 30,
        y: 725,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`${dateFormatFn(purchaseData.cartOrderDate, 'DD/MM/YYYY HH:mm:ss')}`, {
        x: 70,
        y: 725,
        size: 12,
        color: rgb(0, 0, 0)
      });
      page.drawText('Vendedor:', {
        x: 30,
        y: 710,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`${purchaseData.requesterFullname}`, {
        x: 90,
        y: 710,
        size: 12,
        color: rgb(0, 0, 0)
      });
      page.drawText('Cliente:', {
        x: 30,
        y: 695,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`${purchaseData.buyerFullname}`, {
        x: 75,
        y: 695,
        size: 12,
        color: rgb(0, 0, 0)
      });
      page.drawText('Dirección:', {
        x: 30,
        y: 680,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`${purchaseData.deliveryAddress}`, {
        x: 85,
        y: 680,
        size: 12,
        color: rgb(0, 0, 0)
      });
      page.drawText('Terminos de pago:', {
        x: 30,
        y: 665,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`${purchaseData.terminosDePago}`, {
        x: 130,
        y: 665,
        size: 12,
        color: rgb(0, 0, 0)
      });

      page.drawText('Giro:', {
        x: 30,
        y: 650,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });

      if (purchaseData.giro && purchaseData.giro.length > 70) {
        page.drawText(`${purchaseData.giro.slice(0, 70)}`, {
          x: 60,
          y: 650,
          size: 12,
          color: rgb(0, 0, 0)
        });
        page.drawText(`${purchaseData.giro.slice(70, purchaseData.giro.length)}`, {
          x: 60,
          y: 635,
          size: 12,
          color: rgb(0, 0, 0)
        });
      } else {
        page.drawText(`${purchaseData.giro ? purchaseData.giro : ''}`, {
          x: 60,
          y: 650,
          size: 12,
          color: rgb(0, 0, 0)
        });
      }
    }
    // Items Titulos
    page.drawText('Barcode', {
      x: 30,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });
    page.drawText('Bodega', {
      x: 115,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });
    page.drawText('Item', {
      x: 180,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });
    page.drawText('Marca', {
      x: 250,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });
    page.drawText('Kgs', {
      x: 325,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });
    page.drawText('F. Venc', {
      x: 373,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });
    page.drawText('P. Unit', {
      x: 430,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });
    page.drawText('Subtotal', {
      x: 495,
      y: firstItemPosY,
      size: 12,
      color: rgb(175 / 255, 151 / 255, 107 / 255)
    });

    // Items Titulos
    for (let i = 0; i < itemsForThisPage.length; i++) {
      page.drawText(itemsForThisPage[i].barcode, {
        x: 30,
        y: firstItemPosY - 15 - 15 * i,
        size: 11,
        color: rgb(0, 0, 0)
      });
      page.drawText(itemsForThisPage[i].stockOriginName, {
        x: 115,
        y: firstItemPosY - 15 - 15 * i,
        size: 11,
        color: rgb(0, 0, 0)
      });
      page.drawText(itemsForThisPage[i].itemName, {
        x: 180,
        y: firstItemPosY - 15 - 15 * i,
        size: 11,
        color: rgb(0, 0, 0)
      });
      page.drawText(itemsForThisPage[i].brandName, {
        x: 250,
        y: firstItemPosY - 15 - 15 * i,
        size: 11,
        color: rgb(0, 0, 0)
      });
      page.drawText(formatStringNumber(`${itemsForThisPage[i].itemTotalKgs}`, true, 0, 2), {
        x: 325,
        y: firstItemPosY - 15 - 15 * i,
        size: 11,
        color: rgb(0, 0, 0)
      });
      page.drawText(`${dateFormatFn(itemsForThisPage[i].expirationDate, 'DD/MM/YYYY', true)}`, {
        x: 373,
        y: firstItemPosY - 15 - 15 * i,
        size: 11,
        color: rgb(0, 0, 0)
      });
      page.drawText(
        `$${formatStringNumber(itemsForThisPage[i].itemUnitOfMeasurePrice.toString())}`,
        {
          x: 430,
          y: firstItemPosY - 15 - 15 * i,
          size: 11,
          color: rgb(0, 0, 0)
        }
      );
      page.drawText(`$${formatStringNumber(itemsForThisPage[i].itemSubtotal.toString())}`, {
        x: 495,
        y: firstItemPosY - 15 - 15 * i,
        size: 11,
        color: rgb(0, 0, 0)
      });
    }
    if (pageNum === pagesQuantity - 1) {
      // TOTAL
      page.drawLine({
        start: { x: 300, y: firstItemPosY - 15 - 15 * itemsForThisPage.length },
        end: { x: 560, y: firstItemPosY - 15 - 15 * itemsForThisPage.length },
        thickness: 1,
        color: rgb(0, 0, 0)
      });
      page.drawText('Base imponible', {
        x: 300,
        y: firstItemPosY - 30 - 15 * itemsForThisPage.length,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`$ ${formatStringNumber(purchaseData.cartTotalPrice.toString())}`, {
        x: 500,
        y: firstItemPosY - 30 - 15 * itemsForThisPage.length,
        size: 12,
        color: rgb(0, 0, 0)
      });
      page.drawText('IVA 19%', {
        x: 300,
        y: firstItemPosY - 50 - 15 * itemsForThisPage.length,
        size: 12,
        color: rgb(0, 0, 0)
      });
      page.drawText(`$ ${formatStringNumber(purchaseData.taxPrice.toString())}`, {
        x: 500,
        y: firstItemPosY - 50 - 15 * itemsForThisPage.length,
        size: 12,
        color: rgb(0, 0, 0)
      });

      page.drawLine({
        start: {
          x: 300,
          y: firstItemPosY - 70 - 15 * itemsForThisPage.length
        },
        end: {
          x: 560,
          y: firstItemPosY - 70 - 15 * itemsForThisPage.length
        },
        thickness: 1,
        color: rgb(0, 0, 0)
      });
      page.drawText('TOTAL', {
        x: 300,
        y: firstItemPosY - 90 - 15 * itemsForThisPage.length,
        size: 12,
        color: rgb(175 / 255, 151 / 255, 107 / 255)
      });
      page.drawText(`$ ${formatStringNumber(purchaseData.totalNetPrice.toString())}`, {
        x: 500,
        y: firstItemPosY - 90 - 15 * itemsForThisPage.length,
        size: 12,
        color: rgb(0, 0, 0)
      });
    }
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = 'reserva.pdf';
  a.click();
  URL.revokeObjectURL(url);
};

const GeneratePDF: FunctionComponent<BillProps> = ({ purchaseData, items }) => {
  return (
    <button onClick={() => generateBill({ purchaseData, items })}>Generar y Descargar PDF</button>
  );
};

export default GeneratePDF;
