import { ShoppingCart } from '../types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

/*
 lotDetailId
 lotId
 unitOfMeasureId
 lotDetailStateId
 packagingId
 lotDetailUnitOfMeasurePrice
 lotDetailUnitOfMeasureQuantity
 lotDetailPackagingQuantity
 userBuyerId
 dateCreated
 dateUpdated
 */

const ShoppingCartDetailEntity: WebEntity<ShoppingCart> = {
  name: 'shoppingCart',
  endpoint: 'trades/shoppingCart',
  referenceColumn: '_id',
  customButtonNames: {
    deleteButtonName: 'Quitar del carro'
  },

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Barcode',
      selector: 'barcode',
      sortable: true,
      wrap: true
    },
    {
      name: 'Producto',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Bodega Origen',
      selector: 'stockOriginName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Cantidad',
      selector: 'packagingName',
      format: ({ packagingName }) => `1 ${packagingName}(s)`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Kg',
      selector: 'unitOfMeasureQuantity',
      format: ({ unitOfMeasureQuantity }) => `${unitOfMeasureQuantity}Kg`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Valor Kg',
      selector: 'unitOfMeasurePrice',
      format: ({ unitOfMeasurePrice }) =>
        `$${formatStringNumber(unitOfMeasurePrice.toString(), true, 2, 2)}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Subtotal',
      selector: 'unitOfMeasurePrice',
      format: ({ unitOfMeasurePrice, unitOfMeasureQuantity }) =>
        `$${formatStringNumber(
          (Number(unitOfMeasurePrice) * Number(unitOfMeasureQuantity)).toString(),
          true,
          2,
          2
        )}`,
      sortable: true,
      wrap: true
    },
    {
      name: 'Tipo compra',
      selector: 'productionDate',
      format: (lotDetail) => (lotDetail.isForAuction ? 'subasta' : 'normal'),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Elaboración',
      selector: 'elaborationDate',
      format: ({ elaborationDate }) => dateFormatFn(elaborationDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Expiración',
      selector: 'expirationDate',
      format: ({ expirationDate }) => dateFormatFn(expirationDate, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Precio',
      selector: 'precio',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default ShoppingCartDetailEntity;
