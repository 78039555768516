import { StockMovementAudit } from '../types';
import { FieldTypes, WebEntity } from './types';

const StockMovementAuditEntity: WebEntity<StockMovementAudit> = {
  name: 'stockMovementAudit',
  endpoint: 'stockMovementAudits',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      omit: true
    },
    {
      name: 'Nombre usuario editor',
      selector: 'userUsername',
      sortable: true,
      wrap: true
    },
    {
      name: 'Barcode',
      selector: 'barcode',
      sortable: true,
      wrap: true
    },
    {
      name: 'Item',
      selector: 'itemName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Marca',
      selector: 'brandName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Pais Origen',
      selector: 'countryName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Campo modificado',
      selector: 'fieldName',
      sortable: true,
      wrap: true
    },
    {
      name: 'Valor Previo',
      selector: 'previousFieldValue',
      sortable: true,
      wrap: true
    },
    {
      name: 'Nuevo Valor',
      selector: 'newFieldValue',
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'brandName',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default StockMovementAuditEntity;
