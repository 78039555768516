import React, { FunctionComponent } from 'react';
import '../css/footer.css';
import GenericIcons from './generics/GenericIcons';

const Footer: FunctionComponent = ({}) => {
  return (
    <div className={'footer'}>
      <p>© 2024</p>
      <a href='https://datafy.cl/'>
        <GenericIcons icon='DatafyWhiteIcon' />
      </a>
    </div>
  );
};

export default Footer;
