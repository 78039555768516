import { SimpleObject } from '../types';
export const USER_TYPES = 'USER_TYPES';
export const TOKEN = 'TOKEN';
export const CURRENT_PAGE_KEY = 'CURRENT_PAGE_KEY';

export const addToStorage = (itemName: string, itemValue: string) => {
  localStorage.setItem(itemName, itemValue);
};

export const removeFromStorage = (itemName: string) => {
  localStorage.removeItem(itemName);
};

export const getFromStorage = (itemName: string) => {
  return localStorage.getItem(itemName);
};

export const addObjectToStorage = (itemName: string, simpleObject: SimpleObject) => {
  const jsonObject = JSON.stringify(simpleObject);
  addToStorage(itemName, jsonObject);
};

export const getObjectFromStorage = (itemName: string) => {
  const jsonObject = getFromStorage(itemName);
  return jsonObject ? JSON.parse(jsonObject) : null;
};
